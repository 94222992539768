import React, { type ReactElement } from 'react'
import { Center, Heading, Text, useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import CheckInfoCard from './CheckInfoCard'
import { ModalComponent } from '@/library/modal/ModalComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import { BorderRadius, Color } from '@/theme/theme'
import {
  type TransferFragment as Transfer
} from '@/graphql/__generated__/TransferFragment'
import { CANCEL_TRANSFER } from '@/graphql/mutations/checks/CancelTransfer'
import { type CancelTransfer, type CancelTransferVariables } from '@/graphql/__generated__/CancelTransfer'
import { getSuccessToast, ToastDuration } from '@/utils/toastUtils'
import ErrorInline from '@/library/errors/ErrorInline'

interface StopPaymentRequestModalProps {
  isOpen: boolean
  onClose: () => void
  check: Transfer
}

const REQUEST_STOP_PAYMENT_SUBTITLE = `
    If an hour has passed since the transfer, you can request a Stop Payment. 
`

const STOP_PAYMENT_REQUEST_FAQ = `
    A Stop Payment Request does not guarantee the check's cancellation. It's best to request a Stop Payment as soon as possible. We will update you with the result of the request.
`

export default function StopPaymentRequestModal ({
  isOpen,
  onClose,
  check
}: StopPaymentRequestModalProps): ReactElement {
  const toast = useToast()
  const [requestStopPayment, { loading, error }] = useMutation<CancelTransfer, CancelTransferVariables>(
    CANCEL_TRANSFER,
    {
      variables: {
        id: check.id,
        requestedTransferStatus: check.eligibleCancellationType
      },
      onCompleted: () => {
        toast({
          ...getSuccessToast(
            `
              Your Stop Payment Request has been submitted.
              It may take a few minutes before this is reflected in your account.
              We will update you with the result of the request.
            `
          ),
          isClosable: true,
          duration: ToastDuration.LONG
        })
        onClose()
      }
    }
  )

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <Center w='100%' flexDir='column' gap={8}>
        <Center w='100%' flexDir='column' gap={1} textAlign='center'>
          <Heading>Request Stop Payment</Heading>
          <Text>{REQUEST_STOP_PAYMENT_SUBTITLE}</Text>
        </Center>
        <Center
          w='100%'
          flexDir='column'
          gap={1}
          textAlign='center'
          bg={Color.WHITE}
          borderRadius={BorderRadius.CARD}
          p={6}
        >
          <Heading size='sm'>What is a Stop Payment Request?</Heading>
          <Text>{STOP_PAYMENT_REQUEST_FAQ}</Text>
        </Center>
        <CheckInfoCard check={check}/>
        <Center w='100%' flexDir='column' gap={4}>
          <Button
            text='Request Stop Payment'
            onClick={async () => await requestStopPayment()}
            variant={ButtonVariant.DESTRUCTIVE}
          />
          <Button
            text='Cancel'
            onClick={onClose}
            variant={ButtonVariant.WHITE}
            isLoading={loading}
          />
          <ErrorInline error={error}/>
        </Center>
      </Center>
    </ModalComponent>
  )
}
