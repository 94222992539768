import React, { useState, type ReactElement } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Center } from '@chakra-ui/react'
import AccountUsageFormComponent from './AccountUsageFormComponent'
import { type AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import { UPDATE_AMPLIFY_ACCOUNT_EXPECTED_USAGE } from '@/graphql/mutations/AmplifyApplicationMutations'
import {
  type AmplifyAccountIntendedFunction
} from '@/graphql/__generated__/globalTypes'
import {
  type UpdateAmplifyAccountExpectedUsageVariables,
  type UpdateAmplifyAccountExpectedUsage
} from '@/graphql/__generated__/UpdateAmplifyAccountExpectedUsage'
import AmplifyApplicationHeader
  from '@/components/pages/v2/sign_up/routes/amplify/routes/components/AmplifyApplicationHeader'
import { GET_ONBOARDING_BUSINESS_BY_ID } from '@/graphql/queries/onboarding/GetOnboardingBusinessById'
import {
  type GetOnboardingBusinessById,
  type GetOnboardingBusinessByIdVariables
} from '@/graphql/__generated__/GetOnboardingBusinessById'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { AmplifyApplicationStep, getAmplifyApplicationRouteForContext } from '@/utils/amplifyApplicationUtils'
import { routeWithParams } from '@/utils/routerUtils'
import { RouteParam } from '@/api/routes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface AccountUsageComponentProps {
  franchiseGroupId: number
  applicationId: string
  backLink: string
  context: AmplifyApplicationContext
}

export default function AccountUsageComponent (
  {
    franchiseGroupId,
    applicationId,
    backLink,
    context
  }: AccountUsageComponentProps
): ReactElement {
  const navigate = useAltirNavigate()
  const [submissionError, setSubmissionError] = useState<Error | undefined>()

  const {
    data,
    loading,
    error
  } = useQuery<GetOnboardingBusinessById, GetOnboardingBusinessByIdVariables>(
    GET_ONBOARDING_BUSINESS_BY_ID,
    { variables: { franchiseGroupId } }
  )

  const [
    updateApplication,
    { loading: updateApplicationLoading }
  ] = useMutation<UpdateAmplifyAccountExpectedUsage, UpdateAmplifyAccountExpectedUsageVariables>(
    UPDATE_AMPLIFY_ACCOUNT_EXPECTED_USAGE,
    {
      onCompleted: () => {
        // Navigate to disclosures page
        const baseRoute = getAmplifyApplicationRouteForContext(AmplifyApplicationStep.DISCLOSURES, context)
        navigate(
          routeWithParams(
            baseRoute,
            [
              { param: RouteParam.BUSINESS_ID, value: String(franchiseGroupId) },
              { param: RouteParam.AMPLIFY_APPLICATION_ID, value: String(applicationId) }
            ]
          ),
          null
        )
      },
      onError: (error) => { setSubmissionError(error) }
    }
  )

  async function handleSubmission (
    sourcesOfFunds: string[],
    expectedContinuousSourcesOfFunds: string[],
    expectedTransferVolume: string,
    expectedAccountFunction: AmplifyAccountIntendedFunction,
    doesExpectToTransactInternationally: boolean
  ): Promise<void> {
    await updateApplication({
      variables: {
        applicationId,
        sourcesOfFunds,
        expectedTransferVolume,
        doesExpectToTransactInternationally,
        expectedContinuousSourcesOfFunds,
        intendedFunction: expectedAccountFunction
      }
    })
  }

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={8} w='100%'>
        <AmplifyApplicationHeader
          backLink={backLink}
          businessName={data?.franchiseGroup?.name ?? null}
          header='How will this Amplify Account be used?'
        />
        <AccountUsageFormComponent
          isLoading={updateApplicationLoading}
          error={submissionError}
          onSubmit={handleSubmission}
        />
      </Center>
    </AltirSkeleton>
  )
}
