import React, { type ReactElement } from 'react'
import { Center, Text } from '@chakra-ui/react'
import CheckIssuanceAccessRequestMenuItem from './CheckIssuanceAccessRequestMenuItem'
import { getAvailableTransferTypes, getFormattedDropdownForType } from './utils'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import { BorderRadius } from '@/theme/theme'
import {
  CheckEnablementStatus,
  type TransferType
} from '@/graphql/__generated__/globalTypes'
import PopoverMenuSingleOptionSelect from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import { isAmplifyAccount } from '@/utils/transferUtils'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

interface TransferMethodSelectorProps {
  selectedSourceAccount: FinancialAccountExtendedFragment | null
  selectedDestinationAccount: FinancialAccountExtendedFragment | null
  counterparties: FinancialAccountExtendedFragment[]
  selectedTransferType: TransferType
  setSelectedTransferType: (type: TransferType) => void
  isCheckIssuanceEnabled: boolean
  organizationCheckEnablementStatus?: CheckEnablementStatus
}

export default function TransferMethodSelector ({
  selectedSourceAccount,
  selectedDestinationAccount,
  counterparties,
  selectedTransferType,
  setSelectedTransferType,
  isCheckIssuanceEnabled,
  organizationCheckEnablementStatus
}: TransferMethodSelectorProps): ReactElement {
  const hasAmplifyCounterparty = counterparties.some(cp => isAmplifyAccount(cp))
  const selectableTransferTypes = getAvailableTransferTypes(
    selectedSourceAccount,
    selectedDestinationAccount,
    isCheckIssuanceEnabled,
    organizationCheckEnablementStatus,
    { enableBookTransfers: true, hasAmplifyCounterparty }
  )

  const showCheckIssuanceAccessCTA = shouldShowCheckIssuanceAccessCTA(
    selectedSourceAccount,
    isCheckIssuanceEnabled,
    organizationCheckEnablementStatus
  )

  return (
    <Center flexDir='column' alignItems='start' gap={2} w='100%'>
      <Text size='sm'> Method </Text>
      <PopoverMenu
        popoverMenuTitle={getFormattedDropdownForType(selectedTransferType).title}
        onSubmit={() => {}}
        shouldMatchWidth={true}
        hasSelection={true}
        paddingY={7}
        paddingX={2}
        borderRadius={BorderRadius.CARD}
        border='0px'
      >
        <PopoverMenuSingleOptionSelect
          options={selectableTransferTypes}
          selectedOption={selectedTransferType}
          formattingFunction={type => { return getFormattedDropdownForType(type) }}
          setSelectedOption={setSelectedTransferType}
          dividerFrequency={0}
          maxSubtitleWidth={undefined}
          callToActionElement={
            showCheckIssuanceAccessCTA
              ? <CheckIssuanceAccessRequestMenuItem checkEnablementStatus={organizationCheckEnablementStatus}/>
              : undefined
          }
        />
      </PopoverMenu>
    </Center>
  )
}

function shouldShowCheckIssuanceAccessCTA (
  selectedSourceAccount: FinancialAccountExtendedFragment | null,
  isCheckIssuanceEnabled: boolean,
  checkEnablementStatus?: CheckEnablementStatus
): boolean {
  if (!isCheckIssuanceEnabled) return false
  if (!isAmplifyAccount(selectedSourceAccount ?? undefined)) return false

  return checkEnablementStatus === CheckEnablementStatus.DISABLED ||
    checkEnablementStatus === CheckEnablementStatus.REQUESTED
}
