import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import StackedInfoAttribute from '../../../../library/utility/StackedInfoAttribute'
import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { getFormattedBalanceRule } from '@/utils/transferRuleUtils'

export interface BalanceRuleDropdownComponentProps {
  transferRule: TransferRule
}

export default function BalanceRuleDropdownComponent (
  { transferRule }: BalanceRuleDropdownComponentProps
): ReactElement {
  const formattedRule = getFormattedBalanceRule(transferRule)
  return (
    <Flex direction='column' gap={4}>
      <StackedInfoAttribute header='Description' subtext={formattedRule.sentenceDescription}/>
    </Flex>
  )
}
