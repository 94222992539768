import React, { type ReactElement } from 'react'
import { Menu, MenuList, MenuButton, MenuItem, Text, Box } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { type BusinessSelection, filterBusinesses } from '../state/utils'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { Color } from '@/theme/theme'
import {
  type GetBusinessOptionsForOrganization,
  type GetBusinessOptionsForOrganizationVariables
} from '@/graphql/__generated__/GetBusinessOptionsForOrganization'
import { GET_BUSINESS_OPTIONS_FOR_ORGANIZATION } from '@/graphql/queries/GetBusinessOptionsForOrganization'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface QuickActionsUrlState {
  isTeamSettingsSelected?: boolean
  isLinkAccountModalOpen?: boolean
  shouldPreFilterOnSelectedBusiness?: boolean
}

interface BusinessSelectorPopoverProps {
  quickActionRoute: string
  appendBusinessIdToRoute?: boolean
  isAmplifyRequired: boolean
  urlState?: QuickActionsUrlState
  children?: React.ReactNode
}

export default function BusinessSelectorPopover ({
  quickActionRoute,
  isAmplifyRequired,
  appendBusinessIdToRoute = false,
  urlState = {},
  children
}: BusinessSelectorPopoverProps): ReactElement {
  const navigate = useAltirNavigate()
  const {
    selectedOrganizationId,
    setSelectedFranchiseGroupId
  } = useAltirStore(state => {
    return {
      selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId,
      setSelectedFranchiseGroupId: state.setSelectedFranchiseGroupId
    }
  })

  const {
    data: orgBusinessData
  } = useQuery<GetBusinessOptionsForOrganization, GetBusinessOptionsForOrganizationVariables>(
    GET_BUSINESS_OPTIONS_FOR_ORGANIZATION,
    {
      fetchPolicy: 'cache-and-network',
      variables: { organizationId: selectedOrganizationId }
    }
  )

  function handleSelect (business: BusinessSelection): void {
    if (business.id != null) {
      setSelectedFranchiseGroupId(Number(business.id))
      navigate(`${quickActionRoute}${appendBusinessIdToRoute ? `/${business.id}` : ''}`, null, {
        state: {
          businessId: business.id,
          ...urlState
        }
      })
    }
  }

  // If the popover would only have one selection option, just select it directly
  // instead of opening the menu
  function handleParentClick (allBusinesses: BusinessSelection[]): void {
    if (allBusinesses.length === 1) {
      const singleBusiness = allBusinesses[0]
      if (singleBusiness != null) {
        handleSelect(singleBusiness)
      }
    }
  }

  const businesses = filterBusinesses(
    orgBusinessData?.currentUser?.selectedOrganization?.franchiseGroups ?? [],
    { hasAmplify: isAmplifyRequired }
  )

  return (
    <Box
      minW='100px'
      onClick={() => { handleParentClick(businesses) }}
    >
      <Menu placement='bottom-end'>
        <MenuButton borderRadius='100%' w='100%'>
          {children}
        </MenuButton>
        {businesses.length > 0 &&
        <MenuList p={4}>
          <Text pb={1}>Continue with</Text>
          {
            businesses.map(business => {
              return (
                <MenuItem onClick={() => { handleSelect(business) }} key={business.id}>
                  <Text color={Color.DARK_BLUE}>{business.name}</Text>
                </MenuItem>
              )
            })
        }
        </MenuList>
      }
      </Menu>
    </Box>
  )
}
