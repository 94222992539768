import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import BalanceRuleDropdownComponent from './BalanceRuleDropdownComponent'
import TimeRuleDropdownComponent from './TimeRuleDropdownComponent'
import TransferRuleDropdownErrorMessage from './TransferRuleDropdownErrorMessage'
import TransferRuleRecentTransferComponent from './TransferRuleRecentTransferComponent'
import RuleManagementModal from '../management/RuleManagementModal'
import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { isBalanceRule } from '@/utils/transferRuleUtils'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { IconSize } from '@/theme/theme'
import { getAutomatedTransferRuleDetailPageRoute } from '@/utils/routerUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface TransferRuleDropdownComponentProps {
  transferRule: TransferRule
  onUpdate: () => void
  canEditTransferRules: boolean
}

export default function TransferRuleDropdownComponent (
  {
    transferRule,
    onUpdate,
    canEditTransferRules
  }: TransferRuleDropdownComponentProps
): ReactElement {
  const navigate = useAltirNavigate()

  const createdAtDate = getFormattedDateString(transferRule.createdAt, DateTimeTemplate.MM_DD_YY_SLASH)
  return (
    <Flex w='100%'>
      <Flex w='100%' pb={4} flexDir='column' gap={4}>
        <StackedInfoAttribute
          header='Created'
          subtext={createdAtDate ?? ''}
        />
        {
          isBalanceRule(transferRule.transferRuleType)
            ? <BalanceRuleDropdownComponent transferRule={transferRule}/>
            : <TimeRuleDropdownComponent transferRule={transferRule}/>
        }
        <TransferRuleDropdownErrorMessage transferRule={transferRule}/>
        <TransferRuleRecentTransferComponent rule={transferRule}/>
        {
          !transferRule.isActive &&
            <StackedInfoAttribute
              header='Deactivated On'
              subtext={`${getFormattedDateString(transferRule.updatedAt, DateTimeTemplate.MM_DD_YY_SLASH) ?? 'N/A'}`}
            />
        }
        <Flex gap={4}>
          <Button
            text='View Overview'
            afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
            onClick={(e) => { navigate(getAutomatedTransferRuleDetailPageRoute(transferRule.id), e) }}
            variant={ButtonVariant.GREY}
            width='auto'
            size={ButtonSize.SMALL}
          />
          <RuleManagementModal
            rule={transferRule}
            onUpdate={onUpdate}
            canEditTransferRules={canEditTransferRules}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
