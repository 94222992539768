import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import CheckEnablementComponent from './CheckEnablementComponent'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { GET_CHECK_ENABLEMENT_STATUS } from '@/graphql/queries/amplify_account/GetCheckEnablementStatus'
import {
  type GetCheckEnablementStatus,
  type GetCheckEnablementStatusVariables
} from '@/graphql/__generated__/GetCheckEnablementStatus'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { CheckEnablementStatus } from '@/graphql/__generated__/globalTypes'
import Button, { ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { routeWithParams } from '@/utils/routerUtils'
import { RouteParam, ROUTES } from '@/api/routes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface CheckCTAComponentProps {
  accountId: number
}

export default function CheckCTAComponent ({ accountId }: CheckCTAComponentProps): ReactElement {
  const navigate = useAltirNavigate()
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const { data, loading, error, refetch } = useQuery<GetCheckEnablementStatus, GetCheckEnablementStatusVariables>(
    GET_CHECK_ENABLEMENT_STATUS,
    {
      variables: {
        organizationId: selectedOrganizationId
      },
      fetchPolicy: 'network-only'
    }
  )

  function onManageChecksClick (e: React.MouseEvent | null): void {
    navigate(
      routeWithParams(
        ROUTES.CHECKS,
        [{ param: RouteParam.ACCOUNT_ID, value: String(accountId) }]
      ),
      e
    )
  }

  if (!loading && data?.currentUser?.rolloutConfiguration?.enableCheckIssuance !== true) {
    return <></>
  }

  const checkEnablementStatus = data?.currentUser?.selectedOrganization?.checkEnablementStatus

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      {
        checkEnablementStatus === CheckEnablementStatus.ENABLED
          ? <Button
              text='Manage Checks'
              variant={ButtonVariant.WHITE_OUTLINE}
              afterIcon={<RightArrowIcon/>}
              onClick={(e) => { onManageChecksClick(e) }}
            />
          : <CheckEnablementComponent
              isEnablementRequested={checkEnablementStatus === CheckEnablementStatus.REQUESTED}
              onSuccessfulRequest={refetch}
            />
      }
    </AltirSkeleton>
  )
}
