import React, { type ReactElement } from 'react'
import EmptyStateCard from '@/library/card/EmptyStateCard'
import Table, { type TableData, type TableHeader } from '@/library/table/Table'
import { getFormattedTransferType } from '@/utils/transferUtils'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import TransferStatusBadge from '@/library/badge/TransferStatusBadge'
import { getBankAccountShortName } from '@/utils/financialAccountUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { Color } from '@/theme/theme'
import { type TransferFragment } from '@/graphql/__generated__/TransferFragment'

interface RecipientTransaction extends TableData {
  amount: { value: string }
  date: { value: string }
  transferStatus: { value: ReactElement | undefined }
  amplifyAccountName: { value: string | undefined }
  method: { value: string | undefined }
}

interface RecipientPaymentHistoryProps {
  transfers: TransferFragment[]
}

export default function RecipientPaymentHistory ({
  transfers
}: RecipientPaymentHistoryProps): ReactElement {
  const tableMetadata: Array<TableHeader<RecipientTransaction>> = [
    { header: 'Date', key: 'date' },
    { header: 'Method', key: 'method' },
    { header: 'From', key: 'amplifyAccountName' },
    { header: 'Status', key: 'transferStatus', isReactElement: true },
    { header: 'Amount', key: 'amount', alignment: 'end' }
  ]

  const formattedTransfers: RecipientTransaction[] = transfers.map(transfer => {
    const amplifyAccount = transfer.amplifyAccount
    return {
      amount: { value: getCurrencyFormatted(transfer.amount.amount) },
      date: { value: getFormattedDateString(transfer.createdAt, DateTimeTemplate.MM_DD_YYYY) ?? '' },
      transferStatus: {
        value: (
          <TransferStatusBadge
            status={transfer.status ?? undefined}
            expectedSettlementDate={transfer.estimatedSettlementDate}
          />
        )
      },
      amplifyAccountName: { value: getBankAccountShortName(amplifyAccount?.lastFour ?? null, 'Altir') },
      method: { value: transfer.type != null ? getFormattedTransferType(transfer.type) : undefined }
    }
  })

  if (transfers.length < 1) {
    return (
      // TODO (PJ): Align with Allison on more robust empty state
      <EmptyStateCard
        title='No Transfers Found'
        subTitle='Any payments you make to this recipient will appear here.'
      />
    )
  }

  return (
    <Table metadata={tableMetadata} data={formattedTransfers} backgroundColor={Color.LIGHT_GREY}/>
  )
}
