import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Center } from '@chakra-ui/react'
import AutomatedTransferRuleTransactionsComponent from './components/detail/AutomatedTransferRuleTransactionsComponent'
import AutomatedTransferRuleMetadataComponent from './components/detail/AutomatedTransferRuleMetadataComponent'
import AutomatedTransferRuleErrorBanner from './components/detail/AutomatedTransferRuleErrorBanner'
import { type GetTransferRule, type GetTransferRuleVariables } from '@/graphql/__generated__/GetTransferRule'
import { GET_TRANSFER_RULE } from '@/graphql/queries/transfer_rules/GetTransferRule'
import Page from '@/library/page/Page'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { RouteParam } from '@/api/routes'

export default function AutomatedTransferRuleDetailPage (): ReactElement {
  const { [RouteParam.AUTOMATED_TRANSFER_RULE_ID]: ruleId } = useParams()
  const { data, loading, error, refetch } = useQuery<GetTransferRule, GetTransferRuleVariables>(
    GET_TRANSFER_RULE,
    { variables: { id: String(ruleId) } }
  )
  const ruleData = data?.transferRule
  return (
    <Page>
      <AltirSkeleton isLoading={loading} error={error}>
        <Center w='100%' gap={6} alignItems='start'>
          <Center flex={1} flexDir='column' alignItems='start' justifyContent='start'>
            {ruleData != null && <AutomatedTransferRuleMetadataComponent rule={ruleData} refetch={refetch}/>}
          </Center>
          <Center flex={2} w='100%' flexDir='column' gap={6}>
            {ruleData != null && <AutomatedTransferRuleErrorBanner rule={ruleData} refetch={refetch}/>}
            <AutomatedTransferRuleTransactionsComponent ruleId={String(ruleId)}/>
          </Center>
        </Center>
      </AltirSkeleton>
    </Page>
  )
}
