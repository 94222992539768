import React, { type ReactElement } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import {
  type GetCounterpartiesPendingVerificationVariables,
  type GetCounterpartiesPendingVerification
} from '@/graphql/__generated__/GetCounterpartiesPendingVerification'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import DefaultCard from '@/library/card/DefaultCard'
import { Color, IconSize } from '@/theme/theme'
import MoreInfoTooltip from '@/library/utility/MoreInfoTooltip'
import ClockIcon from '@/library/icons/ClockIcon'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { CounterpartyType } from '@/graphql/__generated__/globalTypes'
import {
  GET_COUNTERPARTIES_PENDING_VERIFICATION
} from '@/graphql/queries/recipients/GetCounterpartiesPendingVerification'

const MORE_INFO_TEXT = `
    These accounts are being verified. Any transfers you initiate may be delayed until the verification process is complete.
`

export default function CounterpartiesPendingVerificationCard (): ReactElement {
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const {
    data,
    loading,
    error
  } = useQuery<GetCounterpartiesPendingVerification, GetCounterpartiesPendingVerificationVariables>(
    GET_COUNTERPARTIES_PENDING_VERIFICATION,
    {
      variables: {
        franchiseGroupId,
        isOwnershipVerified: false,
        counterpartyTypes: [CounterpartyType.EXTERNAL_PERSONAL, CounterpartyType.PLAID]
      },
      fetchPolicy: 'network-only'
    }
  )
  const unverifiedCounterparties = data?.currentUser?.franchiseGroup?.counterparties ?? []
  const hasCounterpartiesPendingVerification = unverifiedCounterparties.length > 0
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      {
        hasCounterpartiesPendingVerification &&
        <DefaultCard>
          <Flex flexDir='column' w='100%' gap={6}>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text color={Color.DARK_BLUE}>Accounts Pending Verification</Text>
              <MoreInfoTooltip tooltipText={MORE_INFO_TEXT}/>
            </Flex>
            <Flex flexDir='column' gap={1}>
              {unverifiedCounterparties.map((counterparty, i) => {
                return (
                  <Flex key={counterparty.treasuryPrimeId} flexDir='column' gap={1}>
                    {i !== 0 && <Divider/>}
                    <Flex justifyContent='space-between'>
                      <Text color={Color.DARK_GREY}>
                        {counterparty.name}
                      </Text>
                      <Flex gap={1} alignItems='center'>
                        <ClockIcon color={Color.DARK_GREY} size={IconSize.SMALL}/><Text>Verifying</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                )
              })}
            </Flex>
          </Flex>
        </DefaultCard>
    }
    </AltirSkeleton>
  )
}
