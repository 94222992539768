import React, { type ReactElement } from 'react'
import { useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import {
  type RefreshTransactions,
  type RefreshTransactionsVariables
} from '@/graphql/__generated__/RefreshTransactions'
import { REFRESH_TRANSACTIONS } from '@/graphql/mutations/financial_accounts/RefreshTransactions'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import RefreshIcon from '@/library/icons/RefreshIcon'
import { IconSize } from '@/theme/theme'
import TrackedJobLoader from '@/library/loading/TrackedJobLoader'
import { TrackedJobType } from '@/graphql/__generated__/globalTypes'

interface AccountRefreshButtonProps {
  plaidItemId: string
  hasPendingRefreshJob: boolean
  buttonSize?: ButtonSize
  hideText?: boolean
}

export default function AccountRefreshButton ({
  plaidItemId,
  hasPendingRefreshJob,
  buttonSize = ButtonSize.SMALL,
  hideText = false
}: AccountRefreshButtonProps): ReactElement {
  const toast = useToast()
  const [
    refreshTransactions,
    { loading }
  ] = useMutation<RefreshTransactions, RefreshTransactionsVariables>(
    REFRESH_TRANSACTIONS, {
      variables: { plaidItemId },
      onCompleted: (data) => {
        const account = data.refreshTransactions[0]
        const institutionName = account?.institution?.name ?? EMPTY_BALANCE_VALUE
        const jobId = account?.trackedJob?.id
        if (jobId != null) {
          toast(getSuccessToast(`Successfully initiated refresh for ${institutionName}`))
        } else {
          toast(getErrorToast('Unable to initiate refresh'))
        }
      },
      onError: () => {
        toast(getErrorToast('Unable to initiate refresh'))
      }
    }
  )

  if (hasPendingRefreshJob) {
    return <TrackedJobLoader type={TrackedJobType.TRANSACTION_REFRESH}/>
  }

  return (
    <Button
      text={hideText ? null : 'Refresh Data'}
      beforeIcon={<RefreshIcon size={IconSize.SCHMEDIUM}/>}
      variant={ButtonVariant.LINK_TRANSPARENT}
      size={buttonSize}
      onClick={() => { void refreshTransactions() }}
      width='auto'
      isLoading={loading}
    />
  )
}
