import React, { type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import {
  type GetTransferRuleTransactions,
  type GetTransferRuleTransactionsVariables
} from '@/graphql/__generated__/GetTransferRuleTransactions'
import { GET_TRANSFER_RULE_TRANSACTIONS } from '@/graphql/queries/transfer_rules/GetTransferRuleTransaction'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import TransactionsWithOriginatingTransferTable from '@/library/transactions/TransactionsWithOriginatingTransferTable'

interface AutomatedTransferRuleTransactionsProps {
  ruleId: string
}

export default function AutomatedTransferRuleTransactionsComponent ({
  ruleId
}: AutomatedTransferRuleTransactionsProps): ReactElement {
  const { data, loading, error } = useQuery<GetTransferRuleTransactions, GetTransferRuleTransactionsVariables>(
    GET_TRANSFER_RULE_TRANSACTIONS,
    {
      variables: { id: ruleId }
    }
  )
  return (
    <Center flexDir='column' w='100%' gap={3}>
      <Flex w='100%'>
        <Text>Transaction History</Text>
      </Flex>
      <AltirSkeleton isLoading={loading} error={error}>
        <TransactionsWithOriginatingTransferTable
          transactions={data?.transferRule.transactions ?? []}
          emptyStateSubHeader='There have been no transfers created by this rule.'
        />
      </AltirSkeleton>
    </Center>
  )
}
