import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import AmplifyAccountInfoModal from './AmplifyAccountInfoModal'
import {
  type GetAmplifyAccountInfoVariables,
  type GetAmplifyAccountInfo
} from '@/graphql/__generated__/GetAmplifyAccountInfo'
import { GET_AMPLIFY_ACCOUNT_INFO } from '@/graphql/queries/GetTransferPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import MockDropDownComponent from '@/library/layout/drop_down/MockDropDownComponent'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import { Color, IconSize } from '@/theme/theme'

interface AmplifyInfoComponentProps {
  id?: string
}

export default function AmplifyInfoComponent ({ id }: AmplifyInfoComponentProps): ReactElement {
  const franchiseGroupId = useAltirStore((state) => state.selectedFranchiseGroupId)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data, loading, error } = useQuery<GetAmplifyAccountInfo, GetAmplifyAccountInfoVariables>(
    GET_AMPLIFY_ACCOUNT_INFO, {
      variables: {
        franchiseGroupId
      }
    })

  const amplifyAccounts = data?.currentUser?.franchiseGroup?.amplifyAccounts ?? []
  const amplifyAccount = amplifyAccounts.length > 0 ? amplifyAccounts[0] : undefined

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex>
        <AmplifyAccountInfoModal
          isOpen={isModalOpen}
          onClose={() => { setIsModalOpen(false) }}
          amplifyAccount={amplifyAccount}
        />
        <MockDropDownComponent
          title='Amplify Account Information'
          icon={<TreasuryIcon color={Color.DARK_BLUE} size={IconSize.SCHMEDIUM}/>}
          onClick={() => { setIsModalOpen(true) }}
        />
      </Flex>
    </AltirSkeleton>
  )
}
