import React, { useState, type ReactElement } from 'react'
import { Center, Heading } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import Loader from '@/library/loading/Loader'
import Button from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import {
  type UpdateUserOnboardingState,
  type UpdateUserOnboardingStateVariables
} from '@/graphql/__generated__/UpdateUserOnboardingState'
import { UPDATE_USER_ONBOARDING_STATE } from '@/graphql/mutations/onboarding/UpdateUserOnboardingState'
import ErrorInline from '@/library/errors/ErrorInline'
import { type ErrorWithContent } from '@/types/types'
import { ErrorCopy } from '@/utils/errorUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function EmailVerificationVerifyLoadingContent (): ReactElement {
  const navigate = useAltirNavigate()
  const [error, setError] = useState<ErrorWithContent | undefined>()
  const [
    skipEmailVerification,
    { loading: isSkipEmailVerificationLoading }
  ] = useMutation<UpdateUserOnboardingState, UpdateUserOnboardingStateVariables>(
    UPDATE_USER_ONBOARDING_STATE,
    {
      variables: { isEmailVerificationSkipped: true },
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP, null) },
      onError: (error) => {
        setError({
          customContent: {
            title: ErrorCopy.SOMETHING_WENT_WRONG,
            subtitle: ErrorCopy.TRY_AGAIN_LATER
          },
          error
        })
      }
    }
  )
  return (
    <Center flexDir='column' gap={8}>
      <Heading>Attempting to Verify Your Email</Heading>
      <Loader size='50px'/>
      <Button
        text='Skip this for now'
        onClick={async () => await skipEmailVerification()}
        isLoading={isSkipEmailVerificationLoading}
      />
      <ErrorInline error={error}/>
    </Center>
  )
}
