import React, { type ReactElement } from 'react'
import { Center, Divider, Flex, Text } from '@chakra-ui/react'
import {
  type GetRecentRecipients_currentUser_franchiseGroup_counterparties as Recipient
} from '@/graphql/__generated__/GetRecentRecipients'
import { Color, IconSize } from '@/theme/theme'
import ClockIcon from '@/library/icons/ClockIcon'
import PlusIcon from '@/library/icons/PlusIcon'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface RecipientsCardContentProps {
  recipients: Recipient[]
  onAddNewRecipientClick: (e: React.MouseEvent | null) => void
}

export default function RecipientsCardContent ({
  recipients,
  onAddNewRecipientClick
}: RecipientsCardContentProps): ReactElement {
  const navigate = useAltirNavigate()
  return (
    <Flex flexDir='column' gap={6} w='100%'>
      <Flex flexDir='column' gap={1}>
        {recipients.map((recipient, i) => {
          const isOwnershipVerified: boolean = recipient.isOwnershipVerified
          return (
            <Flex key={i} flexDir='column' gap={1}>
              {i !== 0 && <Divider/>}
              <Flex justifyContent='space-between'>
                <Text
                  color={isOwnershipVerified ? Color.DARK_BLUE : Color.DARK_GREY}
                  noOfLines={1}
                >
                  {recipient.name}
                </Text>
                {
                    isOwnershipVerified
                      ? null
                      : <Flex gap={1} alignItems='center'>
                        <ClockIcon color={Color.DARK_GREY} size={IconSize.SMALL}/><Text>Verifying</Text>
                      </Flex>
                  }
              </Flex>
            </Flex>
          )
        })}
      </Flex>
      <Center w='100%' flexDir='column' gap={3}>
        <Button
          text='Add a New Recipient'
          beforeIcon={<PlusIcon size={IconSize.SMALL}/>}
          size={ButtonSize.SMALL}
          onClick={onAddNewRecipientClick}
        />
        <Button
          text='View All'
          size={ButtonSize.SMALL}
          variant={ButtonVariant.WHITE_OUTLINE}
          onClick={(e) => { navigate(ROUTES.RECIPIENTS, e) }}
        />
      </Center>
    </Flex>
  )
}
