import React, { type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import BusinessOverviewComponent from './BusinessOverviewComponent'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import { Color, IconSize } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import { RouteParam, ROUTES } from '@/api/routes'
import {
  type GetOnboardingBusinessData_currentUser_selectedOrganization_franchiseGroups as Business
} from '@/graphql/__generated__/GetOnboardingBusinessData'
import { routeWithParams } from '@/utils/routerUtils'
import
StoreLocationSettingsComponent from
  '@/components/pages/settings/components/store_locations/StoreLocationSettingsComponent'
import
AccountsSettingsComponent from
  '@/components/pages/settings/components/business_settings/accounts/AccountsSettingsComponent'
import InstitutionConnectionComponent from '@/components/clients/financial_institution/InstitutionConnectionComponent'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface OnboardingBusinessDropDownComponentProps {
  refetchBusinessData: () => void
  business: Business
}

export default function OnboardingBusinessDropDownComponent (
  {
    business,
    refetchBusinessData
  }: OnboardingBusinessDropDownComponentProps
): ReactElement {
  const navigate = useAltirNavigate()

  function onStoreLocationClick (): void {
    navigate(
      routeWithParams(
        ROUTES.V2_SIGN_UP_BUSINESS_CREATE_STORE_LOCATION,
        [{ param: RouteParam.BUSINESS_ID, value: business.id }]
      ),
      null
    )
  }

  const financialAccounts = business.financialAccounts ?? []
  const storeLocations = business.storeLocations

  const header = (
    <Flex w='full' alignItems='center' justifyContent='space-between' gap={4}>
      <Text color={Color.DARK_BLUE}>{business.name}</Text>
      <Flex gap={4} alignItems='center'>
        {financialAccounts.length < 1
          ? <InstitutionConnectionComponent
              button={
                <Button text='Link Bank Accounts' width='auto' beforeIcon={<TreasuryIcon size={IconSize.SMALL}/>}/>
              }
              franchiseGroupId={Number(business.id)}
              handleBankAccountLink={refetchBusinessData}
            />
          : <Text>{financialAccounts.length} Account{financialAccounts.length > 1 ? 's' : ''}</Text>
        }
        {
          storeLocations.length < 1
            ? <Button
                text='Add Store Locations'
                variant={financialAccounts.length > 0 ? ButtonVariant.PRIMARY : ButtonVariant.GREY}
                width='auto'
                onClick={onStoreLocationClick}
              />
            : <Text>{storeLocations.length} Store Location{storeLocations.length > 1 ? 's' : ''}</Text>
        }
      </Flex>
    </Flex>
  )

  const dropdownContent = (
    <Center flexDir='column' w='100%' gap={4}>
      <Flex flexDir='column' alignItems='start' gap={2} w='100%'>
        <Text>Business Overview</Text>
        <BusinessOverviewComponent {...business} />
      </Flex>
      <Flex flexDir='column' alignItems='start' gap={2} w='100%'>
        <Text>Store Locations</Text>
        <StoreLocationSettingsComponent
          storeLocations={storeLocations}
          canManageStoreLocationSettings={true}
          refetchSettings={refetchBusinessData}
          franchiseGroupId={Number(business.id)}
          onAddStoreLocationClick={onStoreLocationClick}
        />
      </Flex>
      <AccountsSettingsComponent
        franchiseGroupId={Number(business.id)}
        accounts={financialAccounts}
        refetchSettings={refetchBusinessData}
      />
    </Center>
  )

  return (
    <DropDownComponent
      customHeader={header}
      dropDownContent={dropdownContent}
    />
  )
}
