import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { GET_BUSINESS_NAME } from '@/graphql/queries/business/GetBusinessName'
import { type GetBusinessName, type GetBusinessNameVariables } from '@/graphql/__generated__/GetBusinessName'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import Button, { ButtonVariant } from '@/library/button/Button'
import LeftArrowIcon from '@/library/icons/LeftArrowIcon'
import { IconSize } from '@/theme/theme'
import { ROUTES } from '@/api/routes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function AmplifyApplicationPageHeaderContent (): ReactElement {
  const navigate = useAltirNavigate()
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const {
    data,
    loading,
    error
  } = useQuery<GetBusinessName, GetBusinessNameVariables>(
    GET_BUSINESS_NAME,
    {
      variables: { franchiseGroupId },
      fetchPolicy: 'network-only'
    }
  )

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={8}>
        <Button
          text='See All Businesses'
          beforeIcon={<LeftArrowIcon size={IconSize.SMALL}/>}
          onClick={() => { navigate(ROUTES.AMPLIFY_APP_OVERVIEW, null) }}
          variant={ButtonVariant.GREY}
          width='auto'
        />
        <Center flexDir='column' textAlign='center' gap={1}>
          <Heading size='sm'>
            {data?.franchiseGroup?.name}
          </Heading>
          <Heading>
            Amplify Account Application
          </Heading>
          <Text>
            Your information will be securely encrypted and sent to our banking partner.
            We will save your progress if you skip this step.
          </Text>
        </Center>
      </Center>
    </AltirSkeleton>
  )
}
