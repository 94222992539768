import React, { type ReactElement } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import AccountRowIcon from './AccountRowIcon'
import { getMask, isLinkedBankAccountOrAmplifyAccount } from '@/utils/financialAccountUtils'
import { Color } from '@/theme/theme'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { AccountLinkStatus } from '@/graphql/__generated__/globalTypes'
import { ROUTES } from '@/api/routes'
import { DateTimeTemplate, getFormattedDateString, getFormattedTimeString } from '@/utils/dateUtils'
import { isAltirConnectAccount } from '@/utils/transferUtils'
import CloseButton from '@/library/button/CloseButton'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface AccountRowContentProps {
  account: FinancialAccountExtendedFragment
  isDisabled: boolean
  hideDisabledState?: boolean
  onClearButtonHover: (isHovered: boolean) => void
  onClearSelection?: () => void
}

export default function AccountRowContent ({
  account,
  isDisabled,
  hideDisabledState = false,
  onClearSelection,
  onClearButtonHover
}: AccountRowContentProps): ReactElement {
  const navigate = useAltirNavigate()

  const isAccountLoginRequired = account.status === AccountLinkStatus.LOGIN_REQUIRED

  const shouldShowBalanceUpdatedAt = isAltirConnectAccount(account) && account.liveBalance?.availableBalance != null
  const primaryTextColor = !isDisabled ? Color.DARK_BLUE : Color.DARK_GREY
  return (
    <Flex flexDirection='row' alignItems='center' justifyContent='start' gap={3} width='100%'>
      <Box minW='30px'>
        <AccountRowIcon account={account}/>
      </Box>
      {
        // Display different content for
        // 1. Bank Accounts (plaid/ amplify)
        // 2. External counterparties (vendors)
        isLinkedBankAccountOrAmplifyAccount(account)
          ? <Flex alignItems='center' w='100%' gap={3}>
            <Heading size='sm' color={primaryTextColor}>
              {getMask(account.lastFour)}
            </Heading>
            <Heading size='sm' color={primaryTextColor}>{account?.institution?.name}</Heading>
            <Heading size='sm' color={primaryTextColor}>{account.name}</Heading>
          </Flex>
          : <Flex alignItems='center' w='100%' gap={3}>
            <Heading size='sm' color={primaryTextColor}>{account.counterparty?.nickname}</Heading>
            <Heading size='sm' color={Color.DARK_GREY}>|</Heading>
            <Heading size='sm' color={Color.DARK_GREY}>{account.counterparty?.nameOnAccount}</Heading>
            <Heading size='sm' color={Color.DARK_GREY}>
              {getMask(account.counterparty?.achAccountNumber ?? null)}
            </Heading>
          </Flex>
      }
      <Flex
        flexDirection='row'
        ml='auto'
        alignItems='center'
        justifyContent='center'
        gap={3}
        whiteSpace='nowrap'
      >
        {
          isAccountLoginRequired &&
            <Button
              variant={ButtonVariant.DESTRUCTIVE}
              onClick={(e) => { navigate(ROUTES.SETTINGS, e, { state: { isLinkedAccountsSelected: true } }) }}
              text='Reconnect'
              size={ButtonSize.SMALL}
              onClickEventType={Event.PLAID_RELINK_OPEN_CLICK}
            />
          }
        <Flex flexDir='column' justifyContent='center' textAlign='end'>
          <Heading size='sm' color={primaryTextColor}>
            {isLinkedBankAccountOrAmplifyAccount(account)
              ? getCurrencyFormatted(account.liveBalance?.availableBalance?.amount)
              : ''}
          </Heading>
          {shouldShowBalanceUpdatedAt && <Text fontSize='xs'>
            {`Updated at ${getFormattedTimeString(account.liveBalance?.updatedAt) ?? ''} ${getFormattedDateString(account.liveBalance?.updatedAt, DateTimeTemplate.MONTH_YEAR_SHORT) ?? ''}`}
            </Text>}
        </Flex>
      </Flex>
      {
      onClearSelection != null &&
        <Flex
          onMouseEnter={() => { onClearButtonHover(true) }}
          onMouseLeave={() => { onClearButtonHover(false) }}
        >
          <CloseButton onClick={onClearSelection}/>
        </Flex>
      }
    </Flex>
  )
}
