import { Flex, Heading, Link } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import TransferTableComponent from './table/TransferTableComponent'
import { ROUTES } from '@/api/routes'
import Button, { ButtonVariant } from '@/library/button/Button'
import { Color } from '@/theme/theme'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface RecentTransfersComponentProps {
  numberOfTransfers?: number
}

export default function RecentTransfersComponent ({
  numberOfTransfers = 10
}: RecentTransfersComponentProps): ReactElement {
  const navigate = useAltirNavigate()

  return (
    <Flex flexDir='column' gap={4} w='100%'>
      <Flex flexDir='column' gap={6} w='100%'>
        <Flex justifyContent='space-between'>
          <Heading color={Color.DARK_BLUE} size='md'>Activity</Heading>
        </Flex>
        <TransferTableComponent limit={numberOfTransfers}/>
      </Flex>
      <Flex w='100%'>
        <Link
          color={Color.GREY_BLUE}
          onClick={(e) => { navigate(ROUTES.TRANSFER_HISTORY, e) }}
          w='100%'
        >
          <Button
            text='View All'
            variant={ButtonVariant.WHITE_OUTLINE}
            afterIcon={<RightArrowIcon/>}
            onClickEventType={Event.VIEW_ALL_TRANSFERS_CLICK}
          />
        </Link>
      </Flex>
    </Flex>
  )
}
