import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import OnboardingPage from '@/library/page/OnboardingPage'
import { RouteParam, ROUTES } from '@/api/routes'
import {
  type GetOrganizationBusinessesWithAmplifyApplications,
  type GetOrganizationBusinessesWithAmplifyApplicationsVariables
} from '@/graphql/__generated__/GetOrganizationBusinessesWithAmplifyApplications'
import {
  GET_ORGANIZATION_BUSINESSES_WITH_AMPLIFY_APPLICATIONS
} from '@/graphql/queries/amplify_application/GetOrganizationBusinessesWithAmplifyApplications'
import AmplifyBusinessDropDownComponent
  from '@/components/pages/v2/sign_up/routes/amplify/routes/overview/components/AmplifyBusinessDropDownComponent'
import { routeWithParams } from '@/utils/routerUtils'
import { useAmplifyAppSuccessToast } from '@/hooks/onboarding/useAmplifyAppSuccessToast'
import { ContainerWidth } from '@/theme/theme'
import Button from '@/library/button/Button'
import { areAllAmplifyApplicationsSubmitted } from '@/utils/amplifyApplicationUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function AmplifyApplicationOverviewPage (): ReactElement {
  const navigate = useAltirNavigate()
  useAmplifyAppSuccessToast()
  const {
    organizationId,
    setSelectedFranchiseGroupId
  } = useAltirStore(state => {
    return {
      organizationId: state.selectedOrganizationState.selectedOrganizationId,
      setSelectedFranchiseGroupId: state.setSelectedFranchiseGroupId
    }
  })

  const {
    data,
    loading,
    error
  } = useQuery<
  GetOrganizationBusinessesWithAmplifyApplications,
  GetOrganizationBusinessesWithAmplifyApplicationsVariables
  >(
    GET_ORGANIZATION_BUSINESSES_WITH_AMPLIFY_APPLICATIONS,
    {
      variables: { organizationId },
      fetchPolicy: 'network-only'
    }
  )

  const skipCallToAction = {
    callToActionText: 'Finish this later',
    callToActionOnClick: () => { navigate(ROUTES.HOME, null) },
    isLoading: false
  }
  const businesses = data?.currentUser?.selectedOrganization?.franchiseGroups ?? []

  return (
    <OnboardingPage
      callToAction={skipCallToAction}
      maxWidth={ContainerWidth.LARGE}
      shouldHideProgressBar={true}
      isLoading={loading}
      error={error}
    >
      <Center flexDir='column' gap={1} textAlign='center' w='100%'>
        <Heading>Amplify Account Applications</Heading>
        <Text>
          Apply to open your Amplify Account(s).
          Your information will be securely encrypted and sent to our banking partner.
          We will save your progress if you choose to finish this later.
        </Text>
      </Center>
      <Center flexDir='column' gap={2} w='100%'>
        {
          businesses.map((business, index) => {
            const callToActionClick = (): void => {
              setSelectedFranchiseGroupId(Number(business.id))
              navigate(
                routeWithParams(
                  ROUTES.AMPLIFY_APP_APPLY,
                  [{ param: RouteParam.BUSINESS_ID, value: business.id }]
                ),
                null
              )
            }
            return (
              <AmplifyBusinessDropDownComponent
                key={index}
                business={business}
                callToActionClick={callToActionClick}
              />
            )
          })
        }
      </Center>
      <Button
        text='Continue'
        onClick={(e) => { navigate(ROUTES.HOME, e) }}
        isDisabled={!areAllAmplifyApplicationsSubmitted(businesses)}
      />
    </OnboardingPage>
  )
}
