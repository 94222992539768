import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import TransactionRowDropdownContent, { type TransactionRowDropdownContentProps } from './TransactionRowDropdownContent'

type TransactionRowDropInfoDropdownProps = TransactionRowDropdownContentProps & { isSelected: boolean }

const MotionFlex = motion(Flex)

export default function TransactionRowDropInfoDropdown ({
  isSelected,
  categoryLabelFormatted,
  longDescription,
  fromAccount,
  toAccount,
  dateFormatted,
  timeFormatted,
  originatingTransfer,
  expectedSettlementDate,
  isCheckManagementEnabled
}: TransactionRowDropInfoDropdownProps
): ReactElement {
  return (
    <MotionFlex
      w='100%'
      overflow='hidden'
      initial='closed'
      animate={isSelected
        ? 'open'
        : 'closed'}
      transition={{ duration: 0.2 }}
      variants={{
        open: {
          opacity: 1,
          height: 'auto',
          display: 'block'
        },
        closed: {
          opacity: 0.8,
          height: 0,
          transitionEnd: {
            display: 'none'
          }
        }
      }}
    >
      <TransactionRowDropdownContent
        categoryLabelFormatted={categoryLabelFormatted}
        longDescription={longDescription}
        fromAccount={fromAccount}
        toAccount={toAccount}
        dateFormatted={dateFormatted}
        timeFormatted={timeFormatted}
        originatingTransfer={originatingTransfer}
        expectedSettlementDate={expectedSettlementDate}
        isCheckManagementEnabled={isCheckManagementEnabled}
      />

    </MotionFlex>
  )
}
