import { gql } from '@apollo/client'
import { TRANSFER_RULE_FRAGMENT } from '@/graphql/fragments/TransferRuleFragment'
import {
  FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT
} from '@/graphql/fragments/financial_accounts/FinancialAccountExtendedFragment'
export const GET_TRANSFER_RULE = gql`
    ${FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT}
    ${TRANSFER_RULE_FRAGMENT}
    query GetTransferRule($id: String!) {
        transferRule(id: $id) {
            ...TransferRuleFragment
            fromAccount {
                ...FinancialAccountExtendedFragment
            }
            toAccount {
                ...FinancialAccountExtendedFragment
            }
        }
    }
`
