import { gql } from '@apollo/client'
import { TRANSACTION_FRAGMENT } from '@/graphql/fragments/TransactionFragment'
export const GET_TRANSFER_RULE_TRANSACTIONS = gql`
    ${TRANSACTION_FRAGMENT}
    query GetTransferRuleTransactions($id: String!) {
        transferRule(id: $id) {
            id
            transactions {
                ...TransactionFragment
            }
        }
    }
`
