import React, { useState, type ReactElement } from 'react'
import { Center, Flex, Heading, Text } from '@chakra-ui/react'
import {
  filterCounterparties,
  type AccountWithDisabledState,
  getAccountRowTooltipText,
  sortCounterparties,
  areAccountsEqual
} from './utils'
import { SelectableAccountRow } from './account_row/SelectableAccountRow'
import { AccountSelectorAccountContext, AccountSelectorContext } from './AccountSelectorComponent'
import EmptyAccountsSectionComponent from '../../../treasury/components/treasury/EmptyAccountsSectionComponent'
import { IconSize } from '@/theme/theme'
import SearchBar from '@/library/form/text/SearchBar'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import Button, { ButtonVariant } from '@/library/button/Button'
import CirclePlusOutlineIcon from '@/library/icons/CirclePlusOutlineIcon'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { ROUTES } from '@/api/routes'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import useAltirNavigate from '@/hooks/useAltirNavigate'
import { TransferType } from '@/graphql/__generated__/globalTypes'

export enum CounterpartyTypeSearchCriteria {
  ALL = 'All',
  VENDOR = 'Vendor',
  ACCOUNT = 'Bank Account'
}

export interface AccountSelectorModalProps {
  isOpen: boolean
  onClose: () => void
  onSelectCounterParty: (counterparty: FinancialAccountExtendedFragment) => void
  altirAccounts?: AccountWithDisabledState[]
  otherAccounts?: AccountWithDisabledState[]
  selectedAccount?: FinancialAccountExtendedFragment
  selectedOppositeSideOfTransferAccount: FinancialAccountExtendedFragment | null
  refetch: () => void
  context: AccountSelectorContext
  accountContext: AccountSelectorAccountContext
  selectedTransferType: TransferType | null
  businessName: string | null
}

export function AccountSelectorModal (
  {
    isOpen,
    onClose,
    otherAccounts = [],
    altirAccounts = [],
    onSelectCounterParty,
    refetch,
    context,
    accountContext,
    selectedAccount,
    selectedTransferType,
    businessName
  }: AccountSelectorModalProps
): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const navigate = useAltirNavigate()
  const [searchQuery, setSearchQuery] = useState('')

  function handleAccountSelection (account: FinancialAccountExtendedFragment): void {
    setSearchQuery('')
    onSelectCounterParty(account)
  }

  const transferDirectionHeaderText = accountContext === AccountSelectorAccountContext.TO ? 'To' : 'From'
  const transferDirectionBodyText = transferDirectionHeaderText.toLowerCase()

  // Allow users to add new recipients if
  //   1. They surface this modal on the transfer page
  //   2. They are initiating a transfer OUT OF the Amplify Account
  const canAddNewExternalRecipient = context === AccountSelectorContext.TRANSFER &&
    accountContext === AccountSelectorAccountContext.TO

  const filteredOtherAccounts = sortCounterparties(filterCounterparties(
    otherAccounts,
    searchQuery,
    selectedTransferType
  ))

  const counterpartyListHeader = selectedTransferType === TransferType.BOOK
    ? "Other Business's Altir Accounts"
    : 'Other Accounts'

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={() => { setSearchQuery(''); onClose() }}
      size='2xl'
      trapFocus={false}
    >
      <Center flexDirection='column' gap={8}>
        <Center flexDir='column' w='100%' gap={2}>
          <Heading size='lg'>
            Transfer {transferDirectionHeaderText}
          </Heading>
          <Text>
            Select which account to transfer {transferDirectionBodyText}
          </Text>
        </Center>
        {
          canAddNewExternalRecipient &&
            <Button
              text='Add a New Recipient'
              beforeIcon={<CirclePlusOutlineIcon size={IconSize.SCHMEDIUM}/>}
              variant={ButtonVariant.WHITE_OUTLINE}
              width='auto'
              onClick={(e) => { navigate(ROUTES.RECIPIENTS_CREATE, e, { state: { isOriginTransferPage: true } }) }}
            />
        }
        <Center alignItems='start' flexDir='column' w='100%' gap={4}>
          <Text>{getAltirAccountsHeader(businessName)}</Text>
          {
            altirAccounts.map((account, index) =>
              <SelectableAccountRow
                key={index}
                onClick={() => { handleAccountSelection(account) }}
                account={account}
                isSelected={areAccountsEqual(account, selectedAccount)}
                isDisabled={account.enabledState.isDisabled}
                tooltipLabel={getAccountRowTooltipText(account)}
              />
            )
          }
        </Center>
        <Center alignItems='start' flexDir='column' w='100%' gap={4}>
          <Text>{counterpartyListHeader}</Text>
          <SearchBar queryValue={searchQuery} onChange={setSearchQuery}/>
          {
            filteredOtherAccounts.length < 1
              ? <EmptyAccountsSectionComponent
                  franchiseGroupId={selectedFranchiseGroupId}
                  ctaText='Connect your accounts in order to make a transfer.'
                  refetch={refetch}
                />
              : <Flex flexDirection='column' width='100%' gap={2}>
                {
                  filteredOtherAccounts?.map((account, index) =>
                    <SelectableAccountRow
                      key={index}
                      onClick={() => { handleAccountSelection(account) }}
                      account={account}
                      isSelected={areAccountsEqual(account, selectedAccount)}
                      isDisabled={account.enabledState.isDisabled}
                      tooltipLabel={getAccountRowTooltipText(account)}
                    />
                  )
                }
              </Flex>
          }
        </Center>
      </Center>
    </ModalComponent>
  )
}

function getAltirAccountsHeader (businessName: string | null): string {
  if (businessName == null) return 'Altir Accounts'

  return `${businessName}'s Altir Accounts`
}
