import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import useTrackJobStatus from './useTrackJobStatus'
import {
  type GetFinancialAccount,
  type GetFinancialAccountVariables
} from '@/graphql/__generated__/GetFinancialAccount'
import { GET_FINANCIAL_ACCOUNT } from '@/graphql/queries/accounts/GetFinancialAccount'

interface UseTrackFinancialAccountJobHookStates {
  isTracking: boolean
}

export function useTrackFinancialAccountJob (
  { accountId, trackedJobId }:
  { accountId: number, trackedJobId: string | undefined }
): UseTrackFinancialAccountJobHookStates {
  const [
    fetchFinancialAccountToUpdateCache
  ] = useLazyQuery<GetFinancialAccount, GetFinancialAccountVariables>(
    GET_FINANCIAL_ACCOUNT
  )

  const { trackJobStatus, isTracking } = useTrackJobStatus({
    onSuccess: () => {
      void fetchFinancialAccountToUpdateCache({ variables: { accountId } })
    },
    onError: () => {} // TODO (PJ): align on how to display error state
  })

  useEffect(() => {
    if (!isTracking && trackedJobId != null) {
      trackJobStatus(trackedJobId)
    }
  }, [trackJobStatus, isTracking, trackedJobId])

  return { isTracking }
}
