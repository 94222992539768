import { gql } from '@apollo/client'
import { FINANCIAL_ACCOUNT_FRAGMENT } from '../fragments/financial_accounts/FinancialAccountFragment'

export const BUSINESS_FRAGMENT = gql`
    ${FINANCIAL_ACCOUNT_FRAGMENT}
    fragment BusinessFragment on FranchiseGroup {
        id
        name
        amplifyAccounts {
            ...FinancialAccountFragment
        }
    }
`
