import React, { type ReactElement, type ReactNode } from 'react'
import TransferRuleAccountCell from './cell/TransferRuleAccountCell'
import TransferRuleRecentTransferCell from './cell/TransferRuleRecentTransferCell'
import TransferRuleEditMenu from '../TransferRuleEditMenu'
import TransferRuleDropdownContent from '../dropdown/TransferRuleDropdownContent'
import {
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
}
  from '@/graphql/__generated__/GetTransferRules'
import {
  getFormattedBalanceRule,
  getFormattedTimeRule,
  isRuleInUnaddressedErrorState
} from '@/utils/transferRuleUtils'
import Badge, { BadgeVariant } from '@/library/badge/Badge'
import {
  type TableHeader,
  TABLE_DROPDOWN_CONTENT,
  TableColumnType,
  type TableData
} from '@/library/table/Table'

export interface TransferRuleBalanceRow extends TableData {
  fromAccount: { value: ReactElement, sortValue: string }
  toAccount: { value: ReactElement, sortValue: string }
  threshold: { value: string }
  lastTransfer: { value: ReactElement, sortValue: string | null }
  status: { value: ReactElement | null, sortValue?: string }
  editButton: { value: ReactNode }
  [TABLE_DROPDOWN_CONTENT]: { value: ReactElement }
}

export interface TransferRuleTimeRow extends TableData {
  fromAccount: { value: ReactElement, sortValue: string }
  toAccount: { value: ReactElement, sortValue: string }
  amount: { value: string }
  frequency: { value: string }
  nextTransfer: { value: string }
  status: { value: ReactElement | null, sortValue?: string }
  editButton: { value: ReactNode }
  [TABLE_DROPDOWN_CONTENT]: { value: ReactElement }
}

type BalanceBasedRuleMetadata = Array<TableHeader<TransferRuleBalanceRow>>
type TimeBasedRuleMetadata = Array<TableHeader<TransferRuleTimeRow>>

export const BALANCE_RULE_METADATA: BalanceBasedRuleMetadata = [
  { header: 'From', key: 'fromAccount', isReactElement: true },
  { header: 'To', key: 'toAccount', isReactElement: true },
  { header: 'Threshold', key: 'threshold', alignment: 'center' },
  {
    header: 'Last Transfer',
    key: 'lastTransfer',
    alignment: 'center',
    isReactElement: true,
    type: TableColumnType.DATE
  },
  { header: 'Status', key: 'status', isReactElement: true, alignment: 'center' },
  { header: 'dropdownContent', key: TABLE_DROPDOWN_CONTENT, isReactElement: true, alignment: 'end' }
]

export const TIME_RULE_METADATA: TimeBasedRuleMetadata = [
  { header: 'From', key: 'fromAccount', isReactElement: true },
  { header: 'To', key: 'toAccount', isReactElement: true },
  { header: 'Amount', key: 'amount', alignment: 'center' },
  { header: 'Frequency', key: 'frequency', alignment: 'center' },
  { header: 'Next Transfer', key: 'nextTransfer', alignment: 'center', type: TableColumnType.DATE },
  { header: 'Status', key: 'status', isReactElement: true, alignment: 'end' },
  { header: 'dropdownContent', key: TABLE_DROPDOWN_CONTENT, isReactElement: true, alignment: 'end' }
]

export function balanceRuleTableRow (
  rule: TransferRule,
  onUpdate: () => void,
  canEditTransferRules: boolean
): TransferRuleBalanceRow {
  const isInErrorState = isRuleInUnaddressedErrorState(rule)
  const { threshold, from, to, mostRecentTransferDate } = getFormattedBalanceRule(rule)
  return {
    fromAccount: { value: <TransferRuleAccountCell rule={rule} context='from'/>, sortValue: from },
    toAccount: { value: <TransferRuleAccountCell rule={rule} context='to'/>, sortValue: to },
    threshold: { value: threshold },
    lastTransfer: { sortValue: mostRecentTransferDate, value: <TransferRuleRecentTransferCell rule={rule}/> },
    status: isInErrorState
      ? { value: <Badge title='Error' badgeVariant={BadgeVariant.RED}/>, sortValue: 'error' }
      : { value: null, sortValue: 'none' },
    editButton: { value: <TransferRuleEditMenu transferRule={rule} canEditTransferRules={true} onUpdate={() => {}}/> },
    [TABLE_DROPDOWN_CONTENT]: {
      value: (
        <TransferRuleDropdownContent
          transferRule={rule}
          onUpdate={onUpdate}
          canEditTransferRules={canEditTransferRules}
        />)
    }
  }
}

export function timeRuleTableRow (
  rule: TransferRule,
  onUpdate: () => void,
  canEditTransferRules: boolean
): TransferRuleTimeRow {
  const isInErrorState = isRuleInUnaddressedErrorState(rule)
  const { amount, from, to, nextTransfer, frequency } = getFormattedTimeRule(rule)
  return {
    fromAccount: { value: <TransferRuleAccountCell rule={rule} context='from'/>, sortValue: from },
    toAccount: { value: <TransferRuleAccountCell rule={rule} context='to'/>, sortValue: to },
    amount: { value: amount },
    frequency: { value: frequency },
    nextTransfer: { value: nextTransfer },
    status: isInErrorState
      ? { value: <Badge title='Error' badgeVariant={BadgeVariant.RED}/>, sortValue: 'error' }
      : { value: null, sortValue: 'none' },
    editButton: { value: <TransferRuleEditMenu transferRule={rule} canEditTransferRules={true} onUpdate={() => {}}/> },
    [TABLE_DROPDOWN_CONTENT]: {
      value: (
        <TransferRuleDropdownContent
          transferRule={rule}
          onUpdate={onUpdate}
          canEditTransferRules={canEditTransferRules}
        />)
    }
  }
}
