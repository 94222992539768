import { Box, Tooltip } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { getBackgroundColor, getBackgroundHoverColor, getHeaderTextColor } from './utils'
import { type BaseDropDownProps, DropDownVariant } from './types'
import DropDownHeader from './components/header/DropDownHeader'
import {
  BorderRadius
} from '../../theme/theme'

type DropDownHeaderComponentProps = BaseDropDownProps & { onClick?: (e: React.MouseEvent) => void }

/**
 * Emulates the style of the DropDownComponent without exposing DropDownContent
 */
export default function MockDropDownComponent (
  {
    title,
    subtitle,
    icon,
    variant = DropDownVariant.DEFAULT,
    rightDescription,
    rightCallToActionComponent,
    isComplete = false,
    hasNotification = false,
    onClick = () => {},
    isDisabled = false,
    disabledTooltipText,
    customHeader,
    borderStyle,
    padding = 6
  }: DropDownHeaderComponentProps
): ReactElement {
  const backgroundColor = getBackgroundColor(variant, isDisabled)
  const backgroundHoverColor = getBackgroundHoverColor(variant, isDisabled)
  const headerColor = getHeaderTextColor(variant, isDisabled)

  function handleClick (e: React.MouseEvent): void {
    if (isDisabled) return
    onClick(e)
  }

  return (
    <Tooltip label={disabledTooltipText}>
      <Box
        bg={backgroundColor}
        borderRadius={BorderRadius.CARD}
        p={padding}
        width='100%'
        onClick={handleClick}
        border={borderStyle}
        _hover={rightCallToActionComponent == null
          ? {
              cursor: !isDisabled ? 'pointer' : 'auto',
              backgroundColor: backgroundHoverColor
            }
          : {}}
      >
        <DropDownHeader
          title={title}
          subtitle={subtitle}
          icon={icon}
          rightDescription={rightDescription}
          rightCallToActionComponent={rightCallToActionComponent}
          customHeader={customHeader}
          isSelected={false}
          isDisabled={isDisabled}
          isComplete={isComplete}
          hasNotification={hasNotification}
          handleDropDownToggle={() => {}}
          headerColor={headerColor}
        />
      </Box>
    </Tooltip>
  )
}
