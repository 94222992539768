import React, { useState, type ReactElement } from 'react'
import { Heading } from '@chakra-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import OnboardingPage from '@/library/page/OnboardingPage'
import Button, { ButtonVariant } from '@/library/button/Button'
import LeftArrowIcon from '@/library/icons/LeftArrowIcon'
import { ContainerWidth, IconSize } from '@/theme/theme'
import { ROUTES } from '@/api/routes'
import
BusinessCreationForm,
{
  type NonNullBusinessCreationFormData
} from '@/components/pages/franchise_sign_up/BusinessCreationForm'
import { type GetOnboardingOrganization } from '@/graphql/__generated__/GetOnboardingOrganization'
import { GET_ONBOARDING_ORGANIZATION } from '@/graphql/queries/onboarding/GetOnboardingOrganization'
import {
  type CreateFranchiseGroup,
  type CreateFranchiseGroupVariables
} from '@/graphql/__generated__/CreateFranchiseGroup'
import { CREATE_FRANCHISE_GROUP } from '@/graphql/mutations/CreateFranchiseGroup'
import ErrorInline from '@/library/errors/ErrorInline'
import { nonNull } from '@/utils/arrayUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function OnboardingBusinessCreatePage (): ReactElement {
  const navigate = useAltirNavigate()
  const [formError, setFormError] = useState<Error | undefined>()
  const { data, loading, error } = useQuery<GetOnboardingOrganization>(GET_ONBOARDING_ORGANIZATION)
  const [
    createBusiness,
    { loading: createBusinessLoading, error: createBusinessError }
  ] = useMutation<CreateFranchiseGroup, CreateFranchiseGroupVariables>(
    CREATE_FRANCHISE_GROUP,
    {
      onCompleted: () => {
        navigate(ROUTES.V2_SIGN_UP_BUSINESS, null)
      },
      onError: () => {}
    }
  )

  function onSubmit (formData: NonNullBusinessCreationFormData): void {
    const organizationId = data?.currentUser?.selectedOrganization?.id
    const userId = data?.currentUser?.id != null ? Number(data.currentUser.id) : null
    if (organizationId == null) {
      setFormError(Error('Organization id is null.'))
      return
    }

    void createBusiness({
      variables: {
        organizationId,
        franchiseBrand: formData.franchiseBrand,
        franchiseGroupName: formData.franchiseGroupName,
        numberOfLocations: Number(formData.franchiseGroupNumberOfLocations),
        memberIds: nonNull([userId])
      }
    })
  }

  return (
    <OnboardingPage maxWidth={ContainerWidth.SMALL} isLoading={loading} error={error}>
      <Button
        text='Back'
        beforeIcon={<LeftArrowIcon size={IconSize.SMALL}/>}
        variant={ButtonVariant.GREY}
        onClick={() => { navigate(ROUTES.V2_SIGN_UP_BUSINESS, null) }}
        width='auto'
      />
      <Heading>Add a Business</Heading>
      <BusinessCreationForm
        organizationId={null}
        onSubmit={onSubmit}
        isSubmissionLoading={createBusinessLoading}
        shouldAssignUserPermissions={false}
      />
      <ErrorInline
        error={createBusinessError ?? formError}
      />
    </OnboardingPage>
  )
}
