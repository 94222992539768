import { DateTime } from 'luxon'
import {
  DayOfWeek,
  dateIsBeforeTime,
  getCurrentDateTimeInUserTimeZone,
  getFormattedDateString,
  getFormattedTimeString,
  isFutureDate,
  parseDate,
  DateTimeTemplate
} from './dateUtils'
import {
  InstitutionConnectionProvider, type TransferRecipientTypesInput, TransferStatus, TransferType
} from '@/graphql/__generated__/globalTypes'
import { ACH_AUTHORIZATION_URL, WIRE_AUTHORIZATION_URL } from '@/theme/urls'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import { type FinancialAccountShortFragment } from '@/graphql/__generated__/FinancialAccountShortFragment'
import { type FinancialAccountFragment } from '@/graphql/__generated__/FinancialAccountFragment'
import { type TransactionFragment } from '@/graphql/__generated__/TransactionFragment'

export const ALTIR_ACH_PROCESSING_FEE = 0
export const ALTIR_BOOK_PROCESSING_FEE = 0
export const ALTIR_WIRE_PROCESSING_FEE = 25
export const ALTIR_CHECK_PROCESSING_FEE = 1.50
// Indicates the time that Grasshopper Bank initiates all transfers for a given day
export const TRANSFER_CUTOFFS: Map<TransferType, { cutoffHr: number, cutoffMin: number, daysToSettle: number }> =
 new Map<TransferType, { cutoffHr: number, cutoffMin: number, daysToSettle: number }>([
   // cutoff = 2:30pm ET with 1 day settlement
   [TransferType.SAMEDAY_ACH, { cutoffHr: 14, cutoffMin: 30, daysToSettle: 1 }],
   // cutoff = 4:30pm ET with 0 day settlement
   [TransferType.WIRE, { cutoffHr: 16, cutoffMin: 30, daysToSettle: 0 }]
 ])

export const ACH_TOOLTIP_MESSAGE = `
ACH transfers initiated before 2:30pm EST will be accessible the following business day.
ACH transfers initiated after 2:30pm EST will be accessible in two business days.
`
export const WIRE_TOOLTIP_MESSAGE = `
Wire transfers initiated before 4:30pm EST will be accessible the same business day.
Wire transfers initiated after 4:30pm EST will be accessible the next business day.
`

export interface TransferComponentContent {
  transferTotal: number
  transferFee: number
  agreementUrl: string | null
  transferTypeFormatted: string
}

export function calculateSettleETA (transferType: TransferType): DateTime {
  const estTime = DateTime.now().setZone('America/New_York')
  const userDateTime = getCurrentDateTimeInUserTimeZone()
  const cutoffs = TRANSFER_CUTOFFS.get(transferType)
  if (cutoffs == null) {
    // Instead of throwing an error on unsupported transfer type, estimate as 1 day out
    return estTime.plus({ days: 1 })
  }

  let daysForTransferToSettle

  // If the transfer date is a Saturday or Sunday, return days til the next Monday
  // TODO handle holidays (ie generalize to non-business-day and daysTilNextBusinessDay)
  const dayOfWeek = userDateTime.weekday
  if ([DayOfWeek.SAT, DayOfWeek.SUN].includes(dayOfWeek)) {
    const daysTilNextWeekday = DayOfWeek.MON + 7 - dayOfWeek
    daysForTransferToSettle = daysTilNextWeekday + cutoffs.daysToSettle
  } else {
    const daysTilNextWeekday = dayOfWeek !== DayOfWeek.FRI ? 1 : 3
    daysForTransferToSettle = dateIsBeforeTime(
      estTime, { hour: cutoffs.cutoffHr, minute: cutoffs.cutoffMin })
      ? cutoffs.daysToSettle
      : cutoffs.daysToSettle + daysTilNextWeekday
  }

  return userDateTime.plus({
    day: daysForTransferToSettle
  })
}

export function getTransferTooltipMessage (transferType: TransferType): string {
  return transferType === TransferType.WIRE
    ? WIRE_TOOLTIP_MESSAGE
    : ACH_TOOLTIP_MESSAGE
}

export function getTransferComponentContent (amount: number, transferType: TransferType): TransferComponentContent {
  if (transferType === TransferType.WIRE) {
    return {
      transferTotal: amount + ALTIR_WIRE_PROCESSING_FEE,
      transferFee: ALTIR_WIRE_PROCESSING_FEE,
      agreementUrl: WIRE_AUTHORIZATION_URL,
      transferTypeFormatted: 'Wire'
    }
  } else if (transferType === TransferType.CHECK) {
    return {
      transferTotal: amount + ALTIR_CHECK_PROCESSING_FEE,
      transferFee: ALTIR_CHECK_PROCESSING_FEE,
      agreementUrl: WIRE_AUTHORIZATION_URL, // TODO (PJ): Check agreement?
      transferTypeFormatted: 'Check'
    }
  } else if (transferType === TransferType.BOOK) {
    return {
      transferTotal: amount + ALTIR_BOOK_PROCESSING_FEE,
      transferFee: ALTIR_BOOK_PROCESSING_FEE,
      agreementUrl: null,
      transferTypeFormatted: 'Book'
    }
  }
  return {
    transferTotal: amount + ALTIR_ACH_PROCESSING_FEE,
    transferFee: ALTIR_ACH_PROCESSING_FEE,
    agreementUrl: ACH_AUTHORIZATION_URL,
    transferTypeFormatted: 'ACH'
  }
}

export function isPendingTransfer (status: TransferStatus | null): boolean {
  if (status == null) return false
  return [
    TransferStatus.PENDING,
    TransferStatus.CREATED,
    TransferStatus.PROCESSING,
    TransferStatus.SENT
  ].includes(status)
}

export function isPendingExternalTransfer (status: TransferStatus | null): boolean {
  if (status == null) return false
  return [
    TransferStatus.PENDING,
    TransferStatus.CREATED,
    TransferStatus.PROCESSING
  ].includes(status)
}

export function isAltirConnectAccount (account: FinancialAccountExtendedFragment): boolean {
  return account.connectionProvider === InstitutionConnectionProvider.ALTIR
}

export function needsWireVerification (
  transferType: TransferType,
  selectedCounterparty: FinancialAccountExtendedFragment | null): boolean {
  return (
    transferType === TransferType.WIRE &&
      selectedCounterparty?.wireAccountNumber == null && selectedCounterparty?.wireRoutingNumber == null
  )
}

export function getFormattedTransferType (transferType: TransferType): string {
  switch (transferType) {
    case TransferType.BOOK:
      return 'Internal Transfer'
    case TransferType.CHECK:
      return 'Check'
    case TransferType.SAMEDAY_ACH:
    case TransferType.STANDARD_ACH:
      return 'ACH'
    case TransferType.WIRE:
      return 'Wire'
  }
}

export function isAmplifyAccount (
  account?: FinancialAccountExtendedFragment |
  FinancialAccountShortFragment |
  FinancialAccountFragment
): boolean {
  return account?.amplifyAccount?.id != null
}

// TODO (PJ): Audit this when we introduce Treasure account
export function isCounterparty (
  account?: FinancialAccountExtendedFragment
): boolean {
  return account?.counterparty?.treasuryPrimeId != null && !isAmplifyAccount(account)
}

export function getExpectedSettlementDateTime (
  transaction: TransactionFragment,
  dateTemplate: DateTimeTemplate = DateTimeTemplate.FULL
): string | null {
  const originatingTransfer = transaction.originatingTransfer
  if (originatingTransfer == null) return null
  const settlementDate = originatingTransfer.estimatedSettlementDate
  if (settlementDate == null || !isFutureDate(parseDate(settlementDate))) return null

  return `
    ${getFormattedDateString(transaction.originatingTransfer?.estimatedSettlementDate, dateTemplate) ?? ''} @ ${getFormattedTimeString(transaction.originatingTransfer?.estimatedSettlementDate) ?? ''} 
  `
}

export function getTransferRecipientInput (account: FinancialAccountExtendedFragment): TransferRecipientTypesInput {
  const isAccountAmplify = isAmplifyAccount(account)

  return {
    amplifyAccountId: isAccountAmplify ? account?.amplifyAccount?.id : undefined,
    counterpartyId: !isAccountAmplify ? account.counterparty?.treasuryPrimeId : undefined
  }
}
