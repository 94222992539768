import React, { type ReactElement } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { BorderRadius, Color } from '@/theme/theme'
import SensitiveTextComponent from '@/library/text/SensitiveTextComponent'
import MetricCardRow from '@/components/pages/treasury/components/treasury/MetricCardRow'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import ClockIcon from '@/library/icons/ClockIcon'
import { GET_RECIPIENT_METADATA } from '@/graphql/queries/recipients/GetRecipientMetadata'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import {
  type GetRecipientMetadata,
  type GetRecipientMetadataVariables
} from '@/graphql/__generated__/GetRecipientMetadata'

interface RecipientDetailCardProps {
  counterpartyId: string
}

export default function RecipientDetailCard ({ counterpartyId }: RecipientDetailCardProps): ReactElement {
  const { data, loading, error } = useQuery<GetRecipientMetadata, GetRecipientMetadataVariables>(
    GET_RECIPIENT_METADATA,
    { variables: { counterpartyId } }
  )
  const counterparty = data?.counterparty

  const accountNumberComponent = (
    <SensitiveTextComponent
      description='Account Number'
      text={counterparty?.achAccountNumber ?? ''}
    />
  )
  const routingNumberComponent = (
    <SensitiveTextComponent
      description='Routing Number'
      text={counterparty?.achRoutingNumber ?? ''}
    />
  )
  const wireAccountNumberComponent = (
    <SensitiveTextComponent
      description='Wire Account Number'
      text={counterparty?.wireAccountNumber ?? ''}
    />
  )
  const wireRoutingNumberComponent = (
    <SensitiveTextComponent
      description='Wire Routing Number'
      text={counterparty?.wireRoutingNumber ?? ''}
    />
  )
  console.log(counterparty)
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex
        w='100%'
        flexDir='column'
        bg={Color.WHITE}
        p={6}
        borderRadius={BorderRadius.CARD}
        gap={24}
      >
        <Flex w='100%' justifyContent='space-between'>
          <Text>
            {counterparty?.nickname}
          </Text>
          {
            counterparty?.isOwnershipVerified === true
              ? <CircleCheckIcon color={Color.DARK_BLUE}/>
              : <ClockIcon color={Color.DARK_GREY}/>
        }
        </Flex>
        <Flex w='100%' flexDir='column' gap={2}>
          {
            counterparty?.nameOnAccount != null &&
            <Flex flexDir='column' gap={2}>
              <MetricCardRow label='Name On Account' value={counterparty.nameOnAccount}/>
            </Flex>
        }
          {
            counterparty?.achAccountNumber != null &&
            <Flex flexDir='column' gap={2}>
              <Divider/>
              <MetricCardRow label='Account Number' valueElement={accountNumberComponent}/>
            </Flex>
        }
          {
            counterparty?.achRoutingNumber != null &&
            <Flex flexDir='column' gap={2}>
              <Divider/>
              <MetricCardRow label='Routing Number' valueElement={routingNumberComponent}/>
            </Flex>
        }
          {
            counterparty?.wireAccountNumber != null &&
            counterparty.wireAccountNumber !== counterparty.achAccountNumber &&
            <Flex flexDir='column' gap={2}>
              <Divider/>
              <MetricCardRow label='Wire Account Number' valueElement={wireAccountNumberComponent}/>
            </Flex>
        }
          {
            counterparty?.wireRoutingNumber != null &&
            counterparty.wireRoutingNumber !== counterparty.achRoutingNumber &&
            <Flex flexDir='column' gap={2}>
              <Divider/>
              <MetricCardRow label='Wire Routing Number' valueElement={wireRoutingNumberComponent}/>
            </Flex>
        }
        </Flex>
      </Flex>
    </AltirSkeleton>
  )
}
