import { gql } from '@apollo/client'

export const GET_BUSINESS_OPTIONS_FOR_ORGANIZATION = gql`
query GetBusinessOptionsForOrganization($organizationId: String) {
    currentUser {
      selectedOrganization(organizationId: $organizationId) {
        name
        franchiseGroups {
          id
          name
          amplifyAccounts {
            accountId
          }
        }
      }
    }
  }
`
