import React, { type ReactElement } from 'react'
import { Flex, Heading, Link, Text } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import { useQuery } from '@apollo/client'
import RecipientDropDown from './components/RecipientDropDown'
import Page from '@/library/page/Page'
import Button from '@/library/button/Button'
import { Color, ContainerWidth, FontWeight, Height, IconSize } from '@/theme/theme'
import PlusIcon from '@/library/icons/PlusIcon'
import { ROUTES } from '@/api/routes'
import { GET_RECENT_RECIPIENTS_PAGE }
  from '@/graphql/queries/recipients/GetRecipientsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import {
  type GetRecentRecipientsPageVariables,
  type GetRecentRecipientsPage
} from '@/graphql/__generated__/GetRecentRecipientsPage'
import { CounterpartyType } from '@/graphql/__generated__/globalTypes'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import useAltirNavigate from '@/hooks/useAltirNavigate'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'

export enum RecipientTransferStatus {
  PENDING,
  ERROR,
  SENT
}

export default function RecipientsPage (): ReactElement {
  const franchiseGroupId = useAltirStore((state) => state.selectedFranchiseGroupId)
  const navigate = useAltirNavigate()

  const {
    data,
    loading,
    error
  } = useQuery<GetRecentRecipientsPage, GetRecentRecipientsPageVariables>(
    GET_RECENT_RECIPIENTS_PAGE,
    {
      variables: {
        franchiseGroupId,
        transferLimit: 3,
        counterpartyTypes: [CounterpartyType.EXTERNAL_VENDOR, CounterpartyType.EXTERNAL_PERSONAL]
      },
      fetchPolicy: 'network-only'
    }
  )

  const recipients = data?.currentUser?.franchiseGroup?.counterparties.map(
    recipient => {
      return {
        treasuryPrimeId: recipient.treasuryPrimeId,
        name: recipient.name,
        isVerified: recipient.isOwnershipVerified,
        transfers: recipient.transfers ?? []
      }
    }
  ) ?? []

  return (
    <Page maxWidth={ContainerWidth.FULL_PAGE}>
      <AltirSkeleton
        isLoading={loading}
        error={error}
      >
        <Flex flexDir='column' gap={6}>
          <Flex justifyContent='space-between' alignItems='center'>
            <Heading color={Color.DARK_BLUE} size='md'>My Recipients</Heading>
            <Flex gap={2}>
              <Button
                text='Add a New Recipient'
                beforeIcon={<PlusIcon color={Color.WHITE} size={IconSize.SMALL}/>}
                width='auto'
                onClick={(e) => { navigate(ROUTES.RECIPIENTS_CREATE, e) }}
              />
              <BusinessSelectorComponent
                height={Height.BUTTON}
                businessFilterParams={{ hasAmplify: true }}
                requireMultipleBusinesses={true}
              />
            </Flex>
          </Flex>
          <Flex flexDir='column' gap={2}>
            {recipients.map(recipient => {
              return <RecipientDropDown key={nanoid()} recipient={recipient}/>
            })}
          </Flex>
          <Flex justifyContent='center' alignItems='center' w='100%'>
            <Text textAlign='center' maxW='400px'>
              You can view your own accounts that you have linked through this process on the{' '}
              <Link
                as='span'
                onClick={(e) => { navigate(ROUTES.TREASURY, e) }}
                color={Color.BRIGHT_BLUE_HOVER}
                fontWeight={FontWeight.NORMAL}
              >
                Treasury Page
              </Link>
            </Text>
          </Flex>
        </Flex>
      </AltirSkeleton>
    </Page>
  )
}
