import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import { getAmplifyApplicationCTA } from '@/utils/amplifyApplicationUtils'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface AmplifyAccountApplicationCTAProps {
  status?: AmplifyAccountApplicationStatus | null

}

export default function AmplifyAccountApplicationCTA ({ status }: AmplifyAccountApplicationCTAProps): ReactElement {
  const navigate = useAltirNavigate()
  const ctaText = getAmplifyApplicationCTA(status ?? null)
  const buttonVariant = getButtonVariantForStatus(status ?? null)
  return (
    <Flex>
      <Button
        text={ctaText}
        variant={buttonVariant}
        size={ButtonSize.SMALL}
        onClick={(e) => { navigate(ROUTES.AMPLIFY_APP_APPLY, e) }}
        width='auto'
      />
    </Flex>
  )
}

export function getButtonVariantForStatus (status: AmplifyAccountApplicationStatus | null): ButtonVariant {
  if (status == null) return ButtonVariant.LINK_TRANSPARENT
  switch (status) {
    case AmplifyAccountApplicationStatus.DOES_NOT_EXIST:
    case AmplifyAccountApplicationStatus.CREATED:
      return ButtonVariant.PRIMARY
    case AmplifyAccountApplicationStatus.SUBMITTED:
    case AmplifyAccountApplicationStatus.MANUAL_REVIEW:
    case AmplifyAccountApplicationStatus.PROCESSING:
    case AmplifyAccountApplicationStatus.ERROR:
    case AmplifyAccountApplicationStatus.DELETED:
    case AmplifyAccountApplicationStatus.REJECTED:
    case AmplifyAccountApplicationStatus.APPROVED:
      return ButtonVariant.LINK_TRANSPARENT
  }
}
