import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { nonNull } from '@/utils/arrayUtils'
import { BorderRadius, Color } from '@/theme/theme'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import { RouteParam, ROUTES } from '@/api/routes'
import EmptyStateCard from '@/library/card/EmptyStateCard'
import Button, { ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { getMask } from '@/utils/financialAccountUtils'
import { routeWithParams } from '@/utils/routerUtils'
import {
  type GetOrganizationAmplifyAccounts,
  type GetOrganizationAmplifyAccountsVariables
} from '@/graphql/__generated__/GetOrganizationAmplifyAccounts'
import { GET_ORGANIZATION_AMPLIFY_ACCOUNTS } from '@/graphql/queries/amplify_account/GetOrganizationAmplifyAccounts'
import { LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function AmplifyAccountSection (): ReactElement {
  const navigate = useAltirNavigate()
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const {
    data,
    loading,
    error
  } = useQuery<GetOrganizationAmplifyAccounts, GetOrganizationAmplifyAccountsVariables>(
    GET_ORGANIZATION_AMPLIFY_ACCOUNTS,
    {
      variables: {
        organizationId: selectedOrganizationId,
        financialAccountFilter: { accountType: LinkedAccountType.BANK }
      }
    }
  )

  // TODO (PJ): Fetch amplify accounts for org directly here once endpoint is ready
  const amplifyAccounts = nonNull(data
    ?.currentUser
    ?.selectedOrganization
    ?.franchiseGroups
    ?.map(franchiseGroup => franchiseGroup.financialAccounts)
    ?.flat()
    .filter(account => account?.amplifyAccount != null) ?? [])

  const hasAmplifyAccount = amplifyAccounts.length > 0
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex flexDir='column' gap={3}>
        <Text>My Amplify Accounts</Text>
        {hasAmplifyAccount
          ? <Flex flexDir='column' gap={2}>
            {
            amplifyAccounts.map(account => {
              const balance = account.liveBalance?.availableBalance?.amount
              const earnings = account.amplifyAccount?.earningsToDate?.amount
              return (
                <Flex
                  key={account.accountId}
                  onClick={(e) => {
                    navigate(
                      routeWithParams(
                        ROUTES.ACCOUNT_DETAIL, [{ param: RouteParam.ACCOUNT_ID, value: String(account.accountId) }]
                      ),
                      e
                    )
                  }}
                  bg={Color.WHITE}
                  borderRadius={BorderRadius.CARD}
                  px={6}
                  py={4}
                  _hover={{ cursor: 'pointer', bg: Color.GREY }}
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Flex flexDir='column'>
                    <Text color={Color.DARK_BLUE}>
                      {account.name}
                    </Text>
                    <Text color={Color.DARK_BLUE}>
                      {getMask(account.lastFour)}
                    </Text>
                    <Text fontSize='sm'>{account.business?.name}</Text>
                  </Flex>
                  <Flex
                    flexDir='column'
                    justifyContent='start'
                    alignItems='end'
                    textAlign='end'
                    h='100%'
                  >
                    <Text color={Color.DARK_BLUE}>
                      {balance != null
                        ? getCurrencyFormatted(balance, { minimumFractionDigits: 2 })
                        : EMPTY_BALANCE_VALUE}
                    </Text>
                    <Text fontSize='sm' color={Color.BRIGHT_BLUE}>
                      {earnings != null ? `+${getCurrencyFormatted(earnings, { minimumFractionDigits: 2 })}` : EMPTY_BALANCE_VALUE}
                    </Text>
                  </Flex>
                </Flex>
              )
            })
        }
          </Flex>
          : <EmptyStateCard
              title='No Amplify Accounts yet.'
              subTitle='Apply for an Amplify Account to get started.'
            >
            <Button
              text='Apply Now'
              onClick={(e) => { navigate(ROUTES.AMPLIFY_APP_APPLY, e) }}
              variant={ButtonVariant.WHITE_GREY_BACKGROUND}
              afterIcon={<RightArrowIcon color={Color.DARK_BLUE}/>}
            />
          </EmptyStateCard>}
      </Flex>
    </AltirSkeleton>
  )
}
