import { gql } from '@apollo/client'

export const GET_RECIPIENT_TRANSFER_RULES = gql`
    query GetRecipientTransferRules($counterpartyId: String!) {
        counterparty(id: $counterpartyId) {
            id
            transferRules {
                id
            }
        }
    }
`
