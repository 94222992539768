import React, { type ReactElement } from 'react'
import {
  balanceRuleTableRow,
  BALANCE_RULE_METADATA,
  timeRuleTableRow,
  TIME_RULE_METADATA
} from './TransferRuleTableRow'
import TransferRuleEmptyStateComponent from '../TransferRuleEmptySectionComponent'
import {
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
}
  from '@/graphql/__generated__/GetTransferRules'
import { GenericTransferRuleType } from '@/utils/transferRuleUtils'
import Table from '@/library/table/Table'
import { Color } from '@/theme/theme'

interface TransferRuleTableProps {
  transferRules: TransferRule[]
  ruleType: GenericTransferRuleType
  onUpdate: () => void
  canEditTransferRules: boolean
  isDeactivated?: boolean
}

const MIN_RULES_FOR_SEARCH_BAR = 3

export default function TransferRuleTable ({
  transferRules,
  ruleType,
  onUpdate,
  canEditTransferRules,
  isDeactivated = false
}: TransferRuleTableProps): ReactElement {
  if (transferRules.length < 1) {
    return (
      // TODO (PJ): Align with Allison on more robust empty state
      <TransferRuleEmptyStateComponent ruleType={ruleType}/>
    )
  }

  const textColor = isDeactivated ? Color.DARK_GREY : Color.DARK_BLUE
  const isSearchEnabled = transferRules.length >= MIN_RULES_FOR_SEARCH_BAR

  switch (ruleType) {
    case GenericTransferRuleType.BALANCE: {
      const data = transferRules.map(rule => balanceRuleTableRow(rule, onUpdate, canEditTransferRules))
      return (
        <Table
          metadata={BALANCE_RULE_METADATA}
          data={data}
          textColor={textColor}
          templateColumns='2fr 2fr 1fr 1fr 1fr'
          isSearchEnabled={isSearchEnabled}
        />
      )
    }
    case GenericTransferRuleType.TIME: {
      const data = transferRules.map(rule => timeRuleTableRow(rule, onUpdate, canEditTransferRules))
      return (
        <Table
          metadata={TIME_RULE_METADATA}
          data={data}
          textColor={textColor}
          templateColumns='2fr 2fr 1fr 1fr 1fr 1fr'
          isSearchEnabled={isSearchEnabled}
        />
      )
    }
  }
}
