import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import InterestCalculatorSlider from '../../../../../library/slider/InterestCalculatorSlider'
import MetricCard from '@/library/card/MetricCard'
import { getPercentageFormatted, getCurrencyFormatted } from '@/utils/stringUtils'
import { type GetInterestRate, type GetInterestRateVariables } from '@/graphql/__generated__/GetInterestRate'
import { GET_INTEREST_RATE } from '@/graphql/queries/amplify_account/GetInterestRate'
import { calculateProjectedInterest } from '@/utils/mathUtils'

interface InterestCalculatorCardProps {
  accountId: number
  currentAccountBalance?: number
}

// eslint-disable-next-line max-len
const CALCULATOR_EXPLANATION_TEXT = 'We calculate your projected interest using the Amplify Account’s current Annual Percentage Yield (APY) and your selected balance and timespan.'

export default function InterestCalculatorCard ({
  accountId,
  currentAccountBalance
}: InterestCalculatorCardProps): ReactElement {
  const { data, loading, error } = useQuery<GetInterestRate, GetInterestRateVariables>(
    GET_INTEREST_RATE, { variables: { accountId } }
  )

  // Slider should range from current balance -> Max(10x current balance, 1m)
  const minDepositValue = Math.floor(currentAccountBalance ?? 0)
  const maxDepositValue = Math.max((currentAccountBalance ?? 0) * 10, 1000000)
  const [depositValue, setDepositValue] = useState(minDepositValue)

  const [depositTermYears, setDepositTermYears] = useState(1)

  // Calculate accrued interest
  const amplifyInterestRate = data?.financialAccount.amplifyAccount?.interestRate ?? 0
  const altirProInterestRate = data?.amplifyAccountPromotionalOffer?.subscribedAnnualPercentageYield ?? 0

  // Note: if the Amplify Account in question has an interest rate that is higher than the default
  //  AltirPro interest rate, we use this for the projections.
  //  However, if the Amplify Account is unsubscribed -- and has a 0% interest rate -- we use the default
  //  AltirPro rate.
  const advertisedInterestRate = Math.max(amplifyInterestRate, altirProInterestRate)

  const projectedInterest = calculateProjectedInterest(depositValue, advertisedInterestRate, depositTermYears)

  return (
    <MetricCard
      isLoading={loading}
      error={error}
      label='Projected Interest Calculator'
      metric={`+${getCurrencyFormatted(projectedInterest, { minimumFractionDigits: 2 })}`}
      tooltipInfo={CALCULATOR_EXPLANATION_TEXT}
    >
      <Flex py={4} flexDir='column' gap={4} alignItems='start'>
        <InterestCalculatorSlider
          depositValue={depositValue}
          depositTermYears={depositTermYears}
          minDepositValue={minDepositValue}
          maxDepositValue={maxDepositValue}
          setDepositValue={(value) => { setDepositValue(value) }}
          setDepositTermYears={(value) => { setDepositTermYears(value) }}
        />
        {amplifyInterestRate < advertisedInterestRate
          ? <Text fontSize='xs' fontStyle='italic'>{`Based on a ${getPercentageFormatted(advertisedInterestRate)} (APY) interest rate for Altir Pro subscribers. Rates are subject to change.`}</Text>
          : <Text fontSize='xs' fontStyle='italic'>{`Based on your ${getPercentageFormatted(amplifyInterestRate)} (APY) interest rate. Rates are subject to change.`}</Text>
      }
      </Flex>
    </MetricCard>
  )
}
