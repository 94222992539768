import React, { type ReactElement } from 'react'
import { type GetTransferRule_transferRule as TransferRule } from '@/graphql/__generated__/GetTransferRule'
import { getOperativeConnectedAccount } from '@/utils/transferRuleUtils'
import {
  isAccountBroken,
  isLinkedBankAccountOrAmplifyAccount
} from '@/utils/financialAccountUtils'
import { ReLinkErrorBanner } from '@/library/errors/ReLinkErrorBanner'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import Button, { ButtonVariant } from '@/library/button/Button'
import LinkIcon from '@/library/icons/LinkIcon'
import { Color, IconSize } from '@/theme/theme'

interface AutomatedTransferRuleErrorBannerProps {
  rule: TransferRule
  refetch: () => void
}

export default function AutomatedTransferRuleErrorBanner ({
  rule,
  refetch
}: AutomatedTransferRuleErrorBannerProps): ReactElement {
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const operativeAccount = getOperativeConnectedAccount(rule)

  if (!isLinkedBankAccountOrAmplifyAccount(operativeAccount)) {
    return <></>
  }

  if (!isAccountBroken(operativeAccount)) {
    return <></>
  }

  return (
    <ReLinkErrorBanner
      plaidAccessToken={operativeAccount.plaidAccessToken ?? undefined}
      plaidItemId={operativeAccount.plaidItemId ?? undefined}
      connectionProvider={operativeAccount.connectionProvider ?? undefined}
      franchiseGroupId={franchiseGroupId}
      refetchData={refetch}
      institutionName={operativeAccount.institution?.name}
      customSubTitle='Please reconnect the account to enable this rule to continue executing.'
      customButton={
        <Button
          text='Reconnect'
          beforeIcon={<LinkIcon size={IconSize.SMALL} color={Color.WHITE}/>}
          variant={ButtonVariant.DESTRUCTIVE}
        />
        }
    />
  )
}
