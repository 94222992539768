import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Center, Flex, Text } from '@chakra-ui/react'
import RecipientMetadataComponent from './components/RecipientMetadataComponent'
import RecipientTransactionsComponent from './components/RecipientTransactionsComponent'
import Page from '@/library/page/Page'

export default function RecipientDetailPage (): ReactElement {
  const { COUNTERPARTY_ID: counterpartyId } = useParams()
  return (
    <Page>
      <Center w='100%' gap={6} alignItems='start'>
        <Flex flex={1} gap={3} flexDir='column'>
          <Text>Recipient Overview</Text>
          <RecipientMetadataComponent counterpartyId={String(counterpartyId)}/>
        </Flex>
        <Flex flex={2} gap={3} flexDir='column'>
          <Text>Transaction History</Text>
          {counterpartyId != null && <RecipientTransactionsComponent counterpartyId={counterpartyId}/>}
        </Flex>
      </Center>
    </Page>
  )
}
