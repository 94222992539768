import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import CounterpartyGraphicComponent from './CounterpartyGraphicComponent'
import CounterpartyDescription from './CounterpartyDescription'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import VerticalDivider, { VerticalDividerHeight } from '@/library/divider/VerticalDivider'

interface TransferCounterpartyComponentProps {
  fromAccount?: FinancialAccountExtendedFragment
  toAccount?: FinancialAccountExtendedFragment
}

export default function TransferCounterpartyComponent ({
  fromAccount,
  toAccount
}: TransferCounterpartyComponentProps): ReactElement {
  return (
    <Flex gap={4} w='100%'>
      <Flex direction='column' alignItems='center' gap={3}>
        <CounterpartyGraphicComponent logoAssetUrl={fromAccount?.institution?.logoAssetUrl ?? undefined}/>
        <VerticalDivider height={VerticalDividerHeight.FULL}/>
        <CounterpartyGraphicComponent logoAssetUrl={toAccount?.institution?.logoAssetUrl ?? undefined}/>
      </Flex>
      <Flex flexDirection='column' alignItems='start' gap={6}>
        <CounterpartyDescription account={fromAccount} role='FROM'/>
        <CounterpartyDescription account={toAccount} role='TO'/>
      </Flex>
    </Flex>
  )
}
