import React, { type RefObject, type ReactElement, type LegacyRef } from 'react'
import {
  Flex,
  Popover as ChakraPopover,
  PopoverArrow as ChakraPopoverArrow,
  PopoverBody as ChakraPopoverBody,
  PopoverCloseButton,
  PopoverContent as ChakraPopoverContent,
  PopoverTrigger as ChakraPopoverTrigger
} from '@chakra-ui/react'
import { BorderRadius, BoxShadow, Color } from '@/theme/theme'
import { type Child, type ComponentChild } from '@/types/react/types'

interface PopoverProps {
  children: [ReactElement, ReactElement]
  isOpen?: boolean // Used to override chakra trigger for hover popovers
  size?: string
}

export default function Popover ({ children, isOpen, size = 'md' }: PopoverProps): ReactElement {
  const { trigger, content } = getComponents(children)
  const initialFocusRef = React.useRef()
  return (
    <ChakraPopover
      initialFocusRef={initialFocusRef as unknown as RefObject<{ focus: any }>}
      isOpen={isOpen}
      size={size}
    >
      <ChakraPopoverTrigger>
        <Flex _hover={{ cursor: 'pointer' }}>
          {trigger}
        </Flex>
      </ChakraPopoverTrigger>
      <ChakraPopoverContent
        bg={Color.WHITE}
        borderColor={Color.WHITE}
        borderRadius={BorderRadius.CARD}
        boxShadow={BoxShadow.LIGHT}
        p={0}
        border='none'
      >
        <ChakraPopoverArrow />
        <ChakraPopoverBody
          p={6}
        >
          <Flex ref={initialFocusRef as unknown as LegacyRef<HTMLDivElement>}>
            {isOpen == null && <PopoverCloseButton mr={2} mt={2} color={Color.DARK_GREY} size='md'/>}
            {content}
          </Flex>
        </ChakraPopoverBody>
      </ChakraPopoverContent>
    </ChakraPopover>
  )
}

function getComponents (children: [ReactElement, ReactElement]): { trigger: Child, content: Child } {
  const childArray = React.Children.toArray(children)
  const trigger = childArray.find(
    (child) => React.isValidElement(child) && child.type === PopoverTrigger
  )

  const content = childArray.find(
    (child) => React.isValidElement(child) && child.type === PopoverContent
  )
  return { trigger, content }
}

export const PopoverTrigger: React.FC<ComponentChild> = ({ children }: ComponentChild) => {
  return (<>{children}</>)
}

export const PopoverContent: React.FC<ComponentChild> = ({ children }: ComponentChild) => {
  return (<>{children}</>)
}
