import React, { useEffect, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { App } from '@capitalos/react'
import { useParams } from 'react-router-dom'
import Page from '@/library/page/Page'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import Loader from '@/library/loading/Loader'
import { GET_CAPITAL_OS_RENDER_TOKEN } from '@/graphql/queries/capital_os/GetCapitalOSRenderToken'
import {
  type GetCapitalOSDRenderToken,
  type GetCapitalOSDRenderTokenVariables
} from '@/graphql/__generated__/GetCapitalOSDRenderToken'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { ROUTES } from '@/api/routes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function CapitalOSPage (): ReactElement {
  const { id } = useParams()
  const navigate = useAltirNavigate()
  const { selectedFranchiseGroupId, setSelectedFranchiseGroupId } = useAltirStore(state => {
    return {
      selectedFranchiseGroupId: state.selectedFranchiseGroupId,
      setSelectedFranchiseGroupId: state.setSelectedFranchiseGroupId
    }
  })
  const {
    data,
    loading,
    error
  } = useQuery<GetCapitalOSDRenderToken, GetCapitalOSDRenderTokenVariables>(
    GET_CAPITAL_OS_RENDER_TOKEN,
    { variables: { franchiseGroupId: Number(id) } }
  )

  useEffect(() => {
    if (id == null) {
      navigate(ROUTES.CARDS, null)
      return
    }
    if (Number(id) !== selectedFranchiseGroupId) {
      setSelectedFranchiseGroupId(Number(id))
    }
  }, [id, selectedFranchiseGroupId, setSelectedFranchiseGroupId, navigate])

  const token = data?.currentUser?.capitalOsConfiguration?.token
  return (
    <Page>
      <AltirSkeleton isLoading={loading} error={error}>
        {token != null && <App token={token} loadingComponent={<Loader size='100px'/>}/>}
      </AltirSkeleton>
    </Page>
  )
}
