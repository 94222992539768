import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import {
  getCounterpartyLongName,
  getStatementPageUrl,
  isAccountBroken,
  isLinkedBankAccountOrAmplifyAccount
} from '@/utils/financialAccountUtils'
import { Color } from '@/theme/theme'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface CounterpartyDescriptionProps {
  account?: FinancialAccountExtendedFragment
  role: 'TO' | 'FROM'
}

export default function CounterpartyDescription ({ account, role }: CounterpartyDescriptionProps): ReactElement {
  const navigate = useAltirNavigate()
  if (account == null) return (<></>)

  const statementPageUrl = getStatementPageUrl(account)
  const isLinkedBankAccount = isLinkedBankAccountOrAmplifyAccount(account)
  const isAccountUnhealthy = isAccountBroken(account)
  const balance = getCurrencyFormatted(account?.liveBalance?.availableBalance?.amount)
  return (
    <Flex flexDir='column'>
      <Text fontSize='sm' color={Color.DARK_BLUE}>{role}</Text>
      <Text
        color={isAccountUnhealthy ? Color.ERROR_RED : Color.DARK_BLUE}
        _hover={{ cursor: 'pointer', color: Color.BRIGHT_BLUE_HOVER }}
        onClick={(e) => { statementPageUrl != null && navigate(statementPageUrl, e) }}
      >
        {getCounterpartyLongName(account)} ***{account?.lastFour}
      </Text>
      {isLinkedBankAccount && <Text>Balance: {balance}</Text>}
    </Flex>
  )
}
