import { nonNull } from './arrayUtils'
import { parseDate, getTimeSinceDateFormatted, isBeginningOfTime } from './dateUtils'
import { isAmplifyAccount } from './transferUtils'
import { routeWithParams } from './routerUtils'
import {
  type GetSettingsPageData_currentUser_selectedOrganization_franchiseGroups_financialAccounts
  as FinancialAccount
}
  from '../graphql/__generated__/GetSettingsPageData'

import { type FinancialDataPoint, type GroupedFinancialAccounts } from '../types/types'
import {
  AccountLinkStatus,
  CounterpartyType,
  InstitutionConnectionProvider,
  TransferType
} from '@/graphql/__generated__/globalTypes'
import {
  type GetAggregateEODBalancesForOrganization_currentUser_selectedOrganization_aggregateEODBalances as EODAccountBalance
} from '@/graphql/__generated__/GetAggregateEODBalancesForOrganization'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import { type FinancialAccountFragment } from '@/graphql/__generated__/FinancialAccountFragment'
import { RouteName, RouteParam, ROUTES } from '@/api/routes'
import { type FinancialAccountShortFragment } from '@/graphql/__generated__/FinancialAccountShortFragment'

export interface CounterpartyShort {
  name: string
  nameLine1: string
  nameLine2: string | null
}

export function groupFinancialAccountsByInstitution (accounts: FinancialAccount[]): GroupedFinancialAccounts[] {
  // Create plaidAccessToken -> account[] map
  const map = new Map<string, FinancialAccount[]>()
  accounts.forEach(account => {
    const plaidAccessToken = account?.plaidAccessToken
    if (plaidAccessToken != null) {
      if (map.has(plaidAccessToken)) {
        map.get(plaidAccessToken)?.push(account)
      } else {
        map.set(plaidAccessToken, [account])
      }
    }
  })

  // Transform map into list of institutions and their respective accounts
  const result: GroupedFinancialAccounts[] = []
  map.forEach((groupedAccounts, plaidAccessToken) => {
    const firstAccount = groupedAccounts[0]
    if (
      firstAccount?.institution != null &&
      firstAccount?.status != null &&
      firstAccount?.plaidItemId != null &&
      firstAccount?.accountType != null &&
      firstAccount?.connectionProvider != null
    ) {
      result.push({
        accounts: groupedAccounts,
        institution: firstAccount.institution,
        plaidAccessToken,
        plaidItemId: firstAccount.plaidItemId,
        status: firstAccount.status,
        accountType: firstAccount.accountType,
        connectionProvider: firstAccount.connectionProvider
      })
    }
  })

  return result
}

export function EODAccountBalancesToFinancialDataPoint (
  EODAccountBalances: EODAccountBalance[]
): FinancialDataPoint[] {
  return nonNull(EODAccountBalances.map(b =>
    (b.balance?.amount != null && b.date != null)
      ? { amount: b.balance.amount, date: b.date }
      : undefined)
  )
}

export function getAccountString (account: FinancialAccount): string {
  // Cast to string for linter
  return `${String(getMask(account.lastFour))} | ${String(account.accountSubTypeFormatted ?? '')} | ${String(account.name ?? '')}`
}

/**
 * We frequently need to determine whether a given account is a legitimate bank account,
 * or an externally linked counterparty
 */
export function isLinkedBankAccountOrAmplifyAccount (
  account: FinancialAccountFragment | FinancialAccountShortFragment
): boolean {
  return isCounterpartyTypeLinkedBankAccount(account.counterparty?.counterpartyType) ||
    account.amplifyAccount?.id != null
}

/**
 * External accounts: Counterparty Nickname
 * Bank accounts: Account name
 */
export function getCounterpartyLongName (account?: FinancialAccountExtendedFragment): string | undefined {
  if (account == null) return undefined
  const isExternalAccount = !isLinkedBankAccountOrAmplifyAccount(account)
  return isExternalAccount
    ? getCounterpartyNickname(account)
    : account.name ?? undefined
}

export function getCounterpartyShort (account?: FinancialAccountFragment): CounterpartyShort | undefined {
  if (account == null) return undefined
  const shortName = getCounterpartyShortName(account)
  if (shortName == null) return undefined

  return {
    name: shortName,
    nameLine1: (isLinkedBankAccountOrAmplifyAccount(account) ? account.name : shortName) ?? shortName,
    nameLine2: getMask(account.lastFour) ?? null
  }
}

/**
 * External accounts: Counterparty Nickname
 * Bank accounts: ***8765
 */
export function getCounterpartyShortName (account?: FinancialAccountFragment): string | undefined {
  if (account == null) return undefined
  const isExternalAccount = !isLinkedBankAccountOrAmplifyAccount(account)
  return isExternalAccount
    ? getCounterpartyNickname(account)
    : getBankAccountShortName(account.lastFour, account.institution?.name ?? null)
}

function getCounterpartyNickname (account: FinancialAccountFragment): string | undefined {
  return account.counterparty?.nickname ?? account.name ?? undefined
}

/**
 * Bank Account -> ***8765
 */
export function getBankAccountShortName (
  lastFour: string | null,
  institutionName: string | null
): string | undefined {
  return lastFour != null ? `${institutionName ?? ''} ${String(getMask(lastFour))}` : undefined
}

/**
 * 8765 -> ***8765
 */
export function getMask (lastFour: string | null): string | undefined {
  return lastFour != null ? lastFour.slice(-4).padStart(7, '*') : undefined
}

export function getConnectionProviderDescription (provider: InstitutionConnectionProvider): string {
  switch (provider) {
    case InstitutionConnectionProvider.PLAID:
      return 'Plaid'
    case InstitutionConnectionProvider.TELLER:
      return 'Teller'
    case InstitutionConnectionProvider.ALTIR:
      return 'Altir Connect'
    case InstitutionConnectionProvider.TREASURY_PRIME:
      return 'Grasshopper'
  }
}

// TODO (PJ): In the long term we should more carefully discuss this logic.
// For now, it's no worse than it was before
export function isAccountValidForDebit (
  account: FinancialAccountExtendedFragment,
  transferType: TransferType | null
): boolean {
  if (account.plaidAccessToken != null) return true
  if (isAmplifyAccount(account) && transferType === TransferType.BOOK) return true

  const counterpartyType = account.counterparty?.counterpartyType
  if (counterpartyType == null) return false

  return [CounterpartyType.PLAID, CounterpartyType.ALTIR_CONNECT].includes(counterpartyType)
}

// We exclude EXTERNAL_PERSONAL from this list because although they are bank accounts,
// their data is not "linked" to our platform
export function isCounterpartyTypeLinkedBankAccount (counterpartyType?: CounterpartyType): boolean {
  if (counterpartyType == null) return false

  return [
    CounterpartyType.PLAID,
    CounterpartyType.ALTIR_CONNECT
  ].includes(counterpartyType)
}

export function isCounterpartyTypeOwnedBankAccount (counterpartyType?: CounterpartyType): boolean {
  if (counterpartyType == null) return false

  return [
    CounterpartyType.PLAID,
    CounterpartyType.ALTIR_CONNECT,
    CounterpartyType.EXTERNAL_PERSONAL
  ].includes(counterpartyType)
}

/**
 * For Altir Connect accounts, only the balance is updated.
 * For Plaid accounts, we can rely on the latest transaction update.
 */
export function getTimeSinceLastUpdate (account: FinancialAccountExtendedFragment): string {
  const updatedDateString = account.connectionProvider === InstitutionConnectionProvider.ALTIR
    ? account.liveBalance?.updatedAt
    : account.transactionsUpdatedAt

  const date = updatedDateString != null ? parseDate(updatedDateString) : null

  if (date == null || isBeginningOfTime(date)) return 'Never'

  return getTimeSinceDateFormatted(date)
}

// 1. For Plaid accounts, send to statement
// 2. For Amplify account, send to account detail
// 3. For counterparties, send to recipients page
export function getStatementPageUrl (
  financialAccount: FinancialAccountFragment | FinancialAccountShortFragment
): string | null {
  if (!isLinkedBankAccountOrAmplifyAccount(financialAccount)) {
    const counterpartyId = financialAccount?.counterparty?.treasuryPrimeId
    if (counterpartyId != null) {
      return routeWithParams(
        ROUTES.RECIPIENT_DETAIL,
        [{ param: RouteParam.COUNTERPARTY_ID, value: counterpartyId }]
      )
    }
    return `/${RouteName.RECIPIENTS}`
  }

  const accountId = financialAccount.accountId
  if (accountId == null) return null

  if (isAmplifyAccount(financialAccount)) {
    return `/${RouteName.ACCOUNTS}/${accountId}`
  }

  return `/${RouteName.TREASURY}/${accountId}`
}

export function isAccountBroken (
  financialAccount: FinancialAccountFragment | FinancialAccountShortFragment
): boolean {
  return financialAccount.status === AccountLinkStatus.LOGIN_REQUIRED ||
    financialAccount.status === AccountLinkStatus.UPDATE_REQUIRED ||
    financialAccount.status === AccountLinkStatus.ERROR
}
