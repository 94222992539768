import React, { type ReactElement } from 'react'
import { GridItem, Text, Flex } from '@chakra-ui/react'
import { type TableData, TABLE_DROPDOWN_CONTENT, type TableHeader } from '../../Table'
import { type Color } from '@/theme/theme'
import DropDownIsSelectedIcon from '@/library/layout/drop_down/components/DropDownIsSelectedIcon'

interface TableCellProps<T> {
  columnMetadata: TableHeader<T>
  columnIndex: number
  row: T
  isDropdownOpen: boolean
  onDropdownToggle: () => void

  // Styles
  textColor: Color
}

export default function TableCell<T extends TableData> ({
  row,
  columnIndex,
  columnMetadata: {
    key,
    alignment = 'start',
    isReactElement = false
  },
  isDropdownOpen,
  onDropdownToggle,
  textColor
}: TableCellProps<T>): ReactElement {
  return (
    <GridItem
      key={columnIndex}
      colStart={columnIndex + 1}
      justifySelf='start'
      width='100%'
      py={2}
      textAlign={alignment}
    >
      {
        key === TABLE_DROPDOWN_CONTENT
          ? <Flex justifyContent='end'>
            <DropDownIsSelectedIcon
              isSelected={isDropdownOpen}
              isDisabled={false}
              onClick={onDropdownToggle}
            />
          </Flex>
          : isReactElement
            ? <Flex justifyContent={alignment}>{row[key]?.value as unknown as ReactElement}</Flex>
            : <Text noOfLines={1} maxWidth='95%' color={textColor}>{String(row[key]?.value)}</Text>
        }
    </GridItem>
  )
}
