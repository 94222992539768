import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { type CounterpartyShort } from '@/utils/financialAccountUtils'
import { Color } from '@/theme/theme'

interface AccountColumnProps {
  account: CounterpartyShort
}

export default function AccountColumn ({ account }: AccountColumnProps): ReactElement {
  return (
    <Flex flexDir='column' px={1}>
      <Text noOfLines={1} color={Color.DARK_BLUE}>
        {account.nameLine1}
      </Text>
      <Text noOfLines={1} color={Color.DARK_BLUE}>
        {account.nameLine2}
      </Text>
    </Flex>
  )
}
