import { gql } from '@apollo/client'
import { COUNTERPARTY_FRAGMENT } from '@/graphql/fragments/financial_accounts/CounterpartyFragment'

export const GET_RECIPIENT_METADATA = gql`
    ${COUNTERPARTY_FRAGMENT}
    query GetRecipientMetadata($counterpartyId: String!) {
        counterparty(id: $counterpartyId) {
            ...CounterpartyFragment
        }
    }
`
