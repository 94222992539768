import React, { useState, type ReactElement } from 'react'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import NoAutomatedRuleTreasuryDropdownCard from './NoAutomatedRuleTreasuryDropdownCard'
import AutomatedRuleTreasuryDropdownCard from './AutomatedRuleTreasuryDropdownCard'
import AccountRefreshButton from '../account_refresh/AccountRefreshButton'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'
import Button, { ButtonVariant } from '@/library/button/Button'
import { BorderRadius, Color } from '@/theme/theme'
import { ROUTES } from '@/api/routes'
import {
  type FinancialAccountExtendedFragment as FinancialAccount
} from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import { getConnectionProviderDescription, getTimeSinceLastUpdate } from '@/utils/financialAccountUtils'
import { joinStrings } from '@/utils/stringUtils'
import DefaultStoreLocationSelectorModal from '@/library/modal/DefaultStoreLocationModal'
import ReLinkAccountButton from '@/components/clients/plaid/ReLinkAccountButton'
import { AccountLinkStatus } from '@/graphql/__generated__/globalTypes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface TreasuryAccountDropdownContentProps {
  account: FinancialAccount
  isTransactionRefreshEnabled: boolean
  hasPendingRefreshJob: boolean
  refetch: () => void
}
const defaultStoreLocationExplanation = `
  Setting a default store location will cause each transaction from this account to have an associated location, unless explicitly matched by Check ID or POS ID.
`

export default function TreasuryAccountDropdownContent (
  {
    account,
    isTransactionRefreshEnabled,
    hasPendingRefreshJob,
    refetch
  }: TreasuryAccountDropdownContentProps
): ReactElement {
  const navigate = useAltirNavigate()

  // For now assume 1 transfer rule
  const transferRule = account.transferRules[0]

  // Store location
  const [showDefaultStoreLocationModal, setShowDefaultStoreLocationModal] = useState(false)
  const storeLocationString = joinStrings(
    [account.defaultStoreLocation?.storeId, account.defaultStoreLocation?.name],
    ' - ')

  const timeSinceUpdated = getTimeSinceLastUpdate(account)
  const isBrokenPlaidAccount = account.status === AccountLinkStatus.LOGIN_REQUIRED

  return (
    <Flex flexDirection='column' gap={25}>
      <DefaultStoreLocationSelectorModal
        isOpen={showDefaultStoreLocationModal}
        onClose={() => { setShowDefaultStoreLocationModal(false) }}
        financialAccount={account}
        refetch={refetch}
      />
      <Grid
        templateColumns={{
          base: 'repeat(3, minmax(0,1fr))',
          xl: 'repeat(4, minmax(0,1fr))',
          '2xl': 'repeat(6, minmax(0,1fr))'
        }}
        gap={4}
      >
        <GridItem>
          <StackedInfoAttribute header='Institution' subtext={account.institution?.name ?? 'N/A'}/>
        </GridItem>
        <GridItem>
          <StackedInfoAttribute header='Name' subtext={account.name ?? 'N/A'}/>
        </GridItem>
        <GridItem>
          <StackedInfoAttribute header='Account Number' sensitiveSubtext={account.achAccountNumber ?? ''}/>
        </GridItem>
        <GridItem>
          <StackedInfoAttribute header='Routing Number' sensitiveSubtext={account.achRoutingNumber ?? ''}/>
        </GridItem>
        <GridItem>
          <StackedInfoAttribute
            header='Connection Method'
            subtext={
              account.connectionProvider != null ? getConnectionProviderDescription(account.connectionProvider) : 'N/A'
            }
          />
        </GridItem>
        <GridItem>
          <StackedInfoAttribute header='Last Updated'
            subtext={timeSinceUpdated}
          />
        </GridItem>
        {
          isTransactionRefreshEnabled && account.plaidItemId != null && !isBrokenPlaidAccount &&
            <GridItem>
              <AccountRefreshButton
                hasPendingRefreshJob={hasPendingRefreshJob}
                plaidItemId={account.plaidItemId}
              />
            </GridItem>
        }
        <GridItem>
          <StackedInfoAttribute header='Business' subtext={account.business?.name ?? undefined}/>
        </GridItem>
        <GridItem>
          {account.defaultStoreLocation != null
            ? <StackedInfoAttribute header='Default Location'
                infoTooltip={defaultStoreLocationExplanation}
                editOptions={{
                  editText: storeLocationString,
                  onEdit: () => { setShowDefaultStoreLocationModal(true) }
                }}
              />
            : <StackedInfoAttribute
                infoTooltip={defaultStoreLocationExplanation}
                header='Default Location'
                linkOptions={{
                  linkText: 'Add Store Location',
                  onLinkClick: () => { setShowDefaultStoreLocationModal(true) }
                }}
              />
          }
        </GridItem>
      </Grid>
      {isBrokenPlaidAccount &&
          account.plaidAccessToken != null &&
           account.plaidItemId != null &&
            account.connectionProvider != null &&
            <ReLinkAccountButton
              franchiseGroupId={Number(account.franchiseGroupId)}
              connectionProvider={account.connectionProvider}
              handleBankAccountLinkUpdate={refetch}
              plaidAccessToken={account.plaidAccessToken}
              plaidItemId={account.plaidItemId}
            />
        }
      <Flex
        borderRadius={BorderRadius.CARD}
        backgroundColor={Color.LIGHT_GREY}
        flexDir='column'
        justifyItems='center'
        alignContent='center'
        justifyContent='space-between'
        gap={3}
        px={5}
        py={5}
      >
        {transferRule != null
          ? <AutomatedRuleTreasuryDropdownCard transferRuleId={transferRule.id} onDelete={refetch}/>
          : <NoAutomatedRuleTreasuryDropdownCard counterpartyId={account.counterparty?.treasuryPrimeId ?? undefined}/>
        }
      </Flex>
      <Button
        text='View Account Details'
        variant={ButtonVariant.WHITE_OUTLINE}
        onClick={(e) => { navigate(`${ROUTES.TREASURY}/${String(account.accountId)}`, e) }}
      />
    </Flex>
  )
}
