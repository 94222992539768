import React, { type ReactElement } from 'react'
import { Center, Heading, Text, useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import CheckInfoCard from './CheckInfoCard'
import { ModalComponent } from '@/library/modal/ModalComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import {
  type TransferFragment as Transfer
} from '@/graphql/__generated__/TransferFragment'
import { CANCEL_TRANSFER } from '@/graphql/mutations/checks/CancelTransfer'
import { type CancelTransfer, type CancelTransferVariables } from '@/graphql/__generated__/CancelTransfer'
import { getSuccessToast, ToastDuration } from '@/utils/toastUtils'
import ErrorInline from '@/library/errors/ErrorInline'
interface CancelCheckModalProps {
  isOpen: boolean
  onClose: () => void
  check: Transfer
}

const CANCELLATION_SUBTITLE = `
    Within an hour of initiating a Check Send, you can cancel the check. Cancelling prevents the check from being sent. After an hour, you are able to request a Stop Payment, which does not guarantee its cancellation.
`

export default function CancelCheckModal ({
  isOpen,
  onClose,
  check
}: CancelCheckModalProps): ReactElement {
  const toast = useToast()
  const [cancelCheck, { loading, error }] = useMutation<CancelTransfer, CancelTransferVariables>(
    CANCEL_TRANSFER,
    {
      variables: {
        id: check.id,
        requestedTransferStatus: check.eligibleCancellationType
      },
      onCompleted: () => {
        toast({
          ...getSuccessToast(
            'Your check has been canceled. It may take a few minutes before this is reflected in your account.'
          ),
          isClosable: true,
          duration: ToastDuration.LONG
        })
        onClose()
      }
    }
  )

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <Center w='100%' flexDir='column' gap={8}>
        <Center w='100%' flexDir='column' gap={1} textAlign='center'>
          <Heading>Cancel Check</Heading>
          <Text>{CANCELLATION_SUBTITLE}</Text>
        </Center>
        <CheckInfoCard check={check}/>
        <Center w='100%' flexDir='column' gap={4}>
          <Button
            text='Cancel Check'
            onClick={async () => await cancelCheck()}
            variant={ButtonVariant.DESTRUCTIVE}
            isLoading={loading}
          />
          <Button
            text='Cancel'
            onClick={onClose}
            variant={ButtonVariant.WHITE}
          />
          <ErrorInline error={error}/>
        </Center>
      </Center>
    </ModalComponent>
  )
}
