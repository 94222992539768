import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import OnboardingPage from '@/library/page/OnboardingPage'
import UserFormComponent from '@/components/pages/v2/sign_up/routes/user/components/UserFormComponent'
import { ROUTES } from '@/api/routes'
import { ContainerWidth } from '@/theme/theme'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function OnboardingUserPage (): ReactElement {
  const navigate = useAltirNavigate()
  function onSuccess (): void {
    navigate(ROUTES.V2_SIGN_UP_EMAIL_VERIFICATION, null)
  }

  return (
    <OnboardingPage maxWidth={ContainerWidth.MEDIUM}>
      <Center flexDir='column' textAlign='center' gap={1}>
        <Heading>Get Started with Altir</Heading>
        <Text>Let&apos;s get some information about you</Text>
      </Center>
      <UserFormComponent onSuccess={onSuccess}/>
    </OnboardingPage>
  )
}
