import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Center, Flex, Text } from '@chakra-ui/react'
import AccountColumn from './AccountColumn'
import AmountPopover from './AmountPopover'
import { getFromAndToAccountsForOriginatingTransfer } from '../../../transactions/components/utils'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { type GetTransactions, type GetTransactionsVariables } from '@/graphql/__generated__/GetTransactions'
import { GET_TRANSACTIONS } from '@/graphql/queries/transactions/GetTransactions'
import { TransactionsPendingFilter, TransferType } from '@/graphql/__generated__/globalTypes'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import Table, { type TableData, type TableHeader } from '@/library/table/Table'
import { type TransactionFragment } from '@/graphql/__generated__/TransactionFragment'
import { nonNull } from '@/utils/arrayUtils'
import { isFutureDate, parseDate } from '@/utils/dateUtils'
import { ROUTES } from '@/api/routes'
import Button, { ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { IconSize } from '@/theme/theme'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface PendingTransferTableRow extends TableData {
  toAccount: { value: ReactElement, sortValue: string }
  amount: { value: ReactElement, sortValue: number }
}

const PENDING_TRANSFER_TABLE_METADATA: Array<TableHeader<PendingTransferTableRow>> = [
  { header: 'To', key: 'toAccount', isReactElement: true },
  { header: 'Amount', key: 'amount', isReactElement: true, alignment: 'end' }
]

export default function PendingTransfersComponent (): ReactElement {
  const navigate = useAltirNavigate()
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const { data, loading, error } = useQuery<GetTransactions, GetTransactionsVariables>(
    GET_TRANSACTIONS,
    {
      variables: {
        organizationId,
        filter: {
          types: [TransferType.SAMEDAY_ACH, TransferType.CHECK, TransferType.WIRE],
          pendingFilter: TransactionsPendingFilter.PENDING_ONLY
        }
      }
    }
  )

  function onViewAllClick (e: React.MouseEvent | null): void {
    const bankAccountIds = nonNull(filteredTransactions.map(txn => txn.originatingTransfer?.amplifyAccount?.accountId))
    const accountFilter = bankAccountIds.length > 0 ? { state: { bankAccountIds } } : {}
    navigate(ROUTES.TRANSACTIONS, e, accountFilter)
  }

  const transactions = data?.currentUser?.selectedOrganization?.transactions?.transactions ?? []
  const filteredTransactions = transactions.filter(txn => {
    const settlementDate = parseDate(txn.originatingTransfer?.estimatedSettlementDate)
    return isFutureDate(settlementDate)
  })

  if (!loading && filteredTransactions.length < 1) {
    return <></>
  }

  const tableData = nonNull(filteredTransactions.map(txn => getPendingTransferTableRow(txn)))

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={3} alignItems='start'>
        <Flex justifyContent='space-between' w='100%'>
          <Text>Pending Transfers</Text>
        </Flex>
        <Table
          metadata={PENDING_TRANSFER_TABLE_METADATA}
          data={tableData}
        />
        <Flex w='100%' justifyContent='end'>
          <Button
            text='View All'
            onClick={(e) => { onViewAllClick(e) }}
            variant={ButtonVariant.GREY}
            width='auto'
            afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
          />
        </Flex>
      </Center>
    </AltirSkeleton>
  )
}

function getPendingTransferTableRow (transaction: TransactionFragment): PendingTransferTableRow | null {
  if (transaction.originatingTransfer == null) {
    return null
  }
  const { toAccount } = getFromAndToAccountsForOriginatingTransfer(transaction.originatingTransfer)
  if (toAccount == null) {
    return null
  }

  return {
    toAccount: { value: <AccountColumn account={toAccount}/>, sortValue: toAccount.nameLine1 },
    amount: { value: <AmountPopover transaction={transaction}/>, sortValue: transaction.amount }
  }
}
