import React, { type ReactElement } from 'react'
import { Text, Flex, Heading, Modal, ModalContent, ModalOverlay, Divider } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import { useQuery } from '@apollo/client'
import { InfoBox } from './InfoxBox'
import { BorderRadius, Color } from '@/theme/theme'
import Button from '@/library/button/Button'
import TransferIcon from '@/library/icons/TransferIcon'
import { ROUTES } from '@/api/routes'
import ModalCloseHeader from '@/library/modal/ModalCloseHeader'
import { CopyTextPill } from '@/library/text/CopyTextPill'
import {
  type GetAmplifyAccountCardData_amplifyAccounts as AmplifyAccount
} from '@/graphql/__generated__/GetAmplifyAccountCardData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { AuthAction, hasAccess, INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import { AuthResource, BankPartner } from '@/graphql/__generated__/globalTypes'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { GET_USER_AUTHORIZATION } from '@/graphql/queries/user/GetUserAuthorization'
import {
  type GetUserAuthorization,
  type GetUserAuthorizationVariables
} from '@/graphql/__generated__/GetUserAuthorization'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface FundAccountModalProps {
  isOpen: boolean
  amplifyAccount?: AmplifyAccount
  onClose: () => void
}

export default function FundAccountModal ({
  amplifyAccount, isOpen, onClose
}: FundAccountModalProps): ReactElement {
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const { data, loading, error } = useQuery<GetUserAuthorization, GetUserAuthorizationVariables>(
    GET_USER_AUTHORIZATION,
    {
      variables: { organizationId }
    }
  )
  const navigate = useAltirNavigate()

  // Authorization Checks
  const canTransfer = hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, data?.currentUser?.authorization ?? [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      preserveScrollBarGap
      size='lg'
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='column'
        borderRadius={BorderRadius.CARD}
        bg={Color.LIGHT_GREY}
        alignItems='center'
        justifyContent='center'
        width='100%'
        px={4}
        pt={4}
        pb={8}
      >
        <ModalCloseHeader onClose={onClose}/>
        <AltirSkeleton isLoading={loading} error={error}>
          <Flex flexDir='column' w='100%' alignItems='center' gap={8} px={4}>
            <Heading size='lg' color={Color.DARK_BLUE}>Fund your Amplify Account</Heading>
            <InfoBox>
              <Flex flexDir='column' gap={2}>
                <Heading size='sm' color={Color.DARK_BLUE}>Via your Linked Accounts</Heading>
                <Text>
                  Make a transfer using the accounts you&apos;ve already connected to the platform.
                </Text>
              </Flex>
              <Button
                text='Continue to Transfer'
                beforeIcon={<TransferIcon/>}
                onClick={(e) => { navigate(ROUTES.TRANSFER, e) }}
                onClickEventType={Event.OPEN_TRANSFER_PAGE_CLICK}
                isDisabled={!canTransfer}
                toolTipText={!canTransfer ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
              />
            </InfoBox>
            <Flex w='100%' alignItems='center' gap={4}>
              <Divider/>
              <Text>or</Text>
              <Divider/>
            </Flex>
            <InfoBox>
              <Flex flexDir='column' gap={2}>
                <Heading size='sm' color={Color.DARK_BLUE}>Via Bank & Wire Transfer</Heading>
                <Text>
                  Use the Amplify Account information below to deposit money from
                  another bank.
                </Text>
              </Flex>
              <Flex flexDir='column' gap={2}>
                <Flex justifyContent='space-between'>
                  <Text>Institution</Text>
                  <Text color={Color.DARK_BLUE}>
                    {
                      amplifyAccount?.amplifyAccount?.bankPartner === BankPartner.FIRST_BANK
                        ? 'FirstBank'
                        : 'Grasshopper'
                    }
                  </Text>
                </Flex>
                <Divider/>
                <Flex justifyContent='space-between'>
                  <Text>Account Type</Text>
                  <Text color={Color.DARK_BLUE}>Checking</Text>
                </Flex>
              </Flex>
              <Flex flexDir='column' gap={4}>
                <CopyTextPill name='Account Number' number={amplifyAccount?.achAccountNumber ?? ''}/>
                <CopyTextPill name='Routing Number' number={amplifyAccount?.achRoutingNumber ?? ''}/>
              </Flex>
            </InfoBox>
          </Flex>
        </AltirSkeleton>
      </ModalContent>
    </Modal>
  )
}
