import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import FranchiseeTable from './franchisee_table/FranchiseeTable'
import { GET_BRAND_FRANCHISEES } from '@/graphql/queries/brand/GetFranchisees'
import {
  type GetBrandFranchisees,
  type GetBrandFranchiseesVariables
} from '@/graphql/__generated__/GetBrandFranchisees'
import AltirSkeleton from '@/library/loading/AltirSkeleton'

interface FranchiseesSectionComponentProps {
  franchiseGroupId: number
}

export default function FranchiseesSectionComponent ({
  franchiseGroupId
}: FranchiseesSectionComponentProps): ReactElement {
  const { data, loading, error } = useQuery<GetBrandFranchisees, GetBrandFranchiseesVariables>(
    GET_BRAND_FRANCHISEES,
    { variables: { franchiseGroupId } }
  )

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center w='100%'>
        <FranchiseeTable franchisees={data?.franchiseGroup?.brand.franchisees ?? []}/>
      </Center>
    </AltirSkeleton>
  )
}
