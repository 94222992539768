import React, { Fragment, type ReactElement } from 'react'
import TableCell from './TableCell'
import { type TableData, type TableHeader } from '../../Table'
import { type Color } from '@/theme/theme'

interface TableBodyRowProps<T> {
  tableMetadata: Array<TableHeader<T>>
  row: T
  onDropdownToggle: () => void
  isDropdownOpen: boolean

  textColor: Color
}

/**
 * Given a row of table data + the table's metadata,
 *  Formats each cell as prescribed by the tablemetadata
 */
export default function TableBodyRow<T extends TableData> ({
  tableMetadata,
  row,
  onDropdownToggle,
  isDropdownOpen,
  textColor
}: TableBodyRowProps<T>): ReactElement {
  return (
    <Fragment>
      {tableMetadata.map((columnMetadata, colIndex) => {
        return (
          <TableCell
            key={colIndex}
            row={row}
            columnMetadata={columnMetadata}
            columnIndex={colIndex}
            isDropdownOpen={isDropdownOpen}
            onDropdownToggle={onDropdownToggle}
            textColor={textColor}
          />
        )
      })}
    </Fragment>
  )
}
