import { gql } from '@apollo/client'
import {
  FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT
} from '@/graphql/fragments/financial_accounts/FinancialAccountExtendedFragment'

export const GET_ORGANIZATION_AMPLIFY_ACCOUNTS = gql`
    ${FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT}
    query GetOrganizationAmplifyAccounts($organizationId: String, $financialAccountFilter: FinancialAccountFilterInput) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                franchiseGroups {
                    financialAccounts(financialAccountFilter: $financialAccountFilter) {
                        ...FinancialAccountExtendedFragment
                    }
                }
            }
        }
    }    
`
