import React, { useState, type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import ReportIncorrectDataModal from './ReportIncorrectDataModal'
import { type FinancialAccountFragment } from '@/graphql/__generated__/FinancialAccountFragment'
import Button, { ButtonVariant } from '@/library/button/Button'
import HelpCenterIcon from '@/library/icons/HelpCenterIcon'
import { IconSize } from '@/theme/theme'

interface ReportIncorrectDataComponentProps {
  account: FinancialAccountFragment
}

export default function ReportIncorrectDataComponent ({ account }: ReportIncorrectDataComponentProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Center w='100%'>
      <Button
        text='Report Incorrect Data'
        variant={ButtonVariant.LINK_TRANSPARENT}
        beforeIcon={<HelpCenterIcon size={IconSize.SMALL}/>}
        onClick={() => { setIsOpen(true) }}
      />
      <ReportIncorrectDataModal account={account} isOpen={isOpen} onClose={() => { setIsOpen(false) }}/>
    </Center>
  )
}
