import React, { useState, type ReactElement } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { type TransactionFragment } from '@/graphql/__generated__/TransactionFragment'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import Popover, { PopoverContent, PopoverTrigger } from '@/library/popover/Popover'
import { Color } from '@/theme/theme'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import { getFromAndToAccountsForOriginatingTransfer } from '@/components/pages/transactions/components/utils'
import { getExpectedSettlementDateTime } from '@/utils/transferUtils'

interface AmountPopoverProps {
  transaction: TransactionFragment

}

export default function AmountPopover ({ transaction }: AmountPopoverProps): ReactElement {
  const [isHovered, setIsHovered] = useState(false)
  const { fromAccount, toAccount } = transaction.originatingTransfer != null
    ? getFromAndToAccountsForOriginatingTransfer(transaction.originatingTransfer)
    : { fromAccount: undefined, toAccount: undefined }
  const amount = getCurrencyFormatted(transaction.amount)
  const date = getFormattedDateString(transaction.date)
  const expectedSettlementDate = getExpectedSettlementDateTime(transaction, DateTimeTemplate.MONTH_YEAR_SHORT)
  return (
    <Popover isOpen={isHovered} size='lg'>
      <PopoverTrigger>
        <Flex gap={2}
          alignItems='center'
          onMouseEnter={() => { setIsHovered(true) }}
          onMouseLeave={() => { setIsHovered(false) }}
        >
          <Text color={Color.DARK_BLUE}>{amount}</Text>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <Flex w='100%' flexDir='column' gap={2}>
          <AmountPopoverRow description='From' value={fromAccount?.name}/>
          <Divider/>
          <AmountPopoverRow description='To' value={toAccount?.name}/>
          <Divider/>
          <AmountPopoverRow description='Date' value={date ?? undefined}/>
          <Divider/>
          <AmountPopoverRow description='Settling On' value={expectedSettlementDate ?? undefined}/>
        </Flex>
      </PopoverContent>
    </Popover>
  )
}

function AmountPopoverRow ({ description, value }: { description: string, value?: string }): ReactElement {
  if (value == null) return <></>
  return (
    <Flex justifyContent='space-between' w='100%'>
      <Text noOfLines={1}>{description}</Text>
      <Text noOfLines={1} color={Color.DARK_BLUE}>{value}</Text>
    </Flex>
  )
}
