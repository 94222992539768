import React, { type ReactElement } from 'react'
import {
  Text, Grid, GridItem
} from '@chakra-ui/react'
import TransactionRowDropInfoDropdown from './TransactionRowDropInfoDropdown'
import { getFormattedTransaction } from './utils/transactionUtils'
import AnimatedDropDownRow, {
  AnimatedDropDownRowExpandedContent,
  AnimatedDropDownRowRowContent
} from '../layout/AnimatedDropDownRow'
import TransferStatusBadge from '../badge/TransferStatusBadge'
import {
  Color
} from '@/theme/theme'
import { type BankAccountIdMap } from '@/components/pages/transactions/components/utils'
import {
  type TransactionFragment as Transaction
} from '@/graphql/__generated__/TransactionFragment'
import { TransferStatus } from '@/graphql/__generated__/globalTypes'
import { getFormattedTransferType } from '@/utils/transferUtils'

interface TransactionRowProps {
  index: number
  isSelected: boolean
  transaction: Transaction
  setSelectedTransactionIndex: (index: number | null) => void
  bankAccountIdMap?: BankAccountIdMap

  isCheckManagementEnabled: boolean
}

export default function TransactionRow (
  {
    index,
    transaction,
    isSelected,
    setSelectedTransactionIndex,
    bankAccountIdMap,
    isCheckManagementEnabled
  }: TransactionRowProps
): ReactElement {
  const {
    amountFormatted,
    categoryLabelFormatted,
    dateFormatted,
    longDescription,
    merchantFormatted,
    isPending,
    baseFontColor,
    fromAccount,
    toAccount,
    expectedSettlementDate
  } = getFormattedTransaction(transaction, bankAccountIdMap)

  const templateColumns = expectedSettlementDate != null
    ? '7rem 4rem 5fr 4fr 6rem 2fr'
    : '7rem 4rem 9fr 0rem 6rem 2fr'

  return (
    <AnimatedDropDownRow
      isSelected={isSelected}
      onClick={() => { setSelectedTransactionIndex(isSelected ? null : index) }}
      isRowClickable={true}
    >
      <AnimatedDropDownRowRowContent>
        <Grid
          w='100%'
          gridAutoFlow='column'
          templateColumns={templateColumns}
          alignContent='center'
          gap={6}
        >
          {/* Date */}
          <GridItem alignSelf='center'>
            <Text color={Color.DARK_GREY}>
              {dateFormatted}
            </Text>
          </GridItem>

          {/* Transfer Type */}
          <GridItem alignSelf='center'>
            {
              transaction.originatingTransfer?.type != null &&
                <Text
                  color={baseFontColor}
                  noOfLines={1}
                >
                  {getFormattedTransferType(transaction.originatingTransfer.type)}
                </Text>
            }
          </GridItem>

          {/* Description */}
          <GridItem alignSelf='center' overflow='hidden'>
            <Text
              color={baseFontColor}
              noOfLines={1}
            >
              {merchantFormatted ?? longDescription}
            </Text>
          </GridItem>

          {/* Expected Settlement Date */}
          <GridItem alignSelf='center' overflow='hidden'>
            {
              expectedSettlementDate != null &&
              <Text noOfLines={1}>{`Expected ${expectedSettlementDate}`}</Text>
            }
          </GridItem>

          {/* Transfer Status Badge */}
          <GridItem alignSelf='center' justifySelf='center'>
            <TransferStatusBadge
              status={isPending ? TransferStatus.PENDING : transaction.originatingTransfer?.status ?? undefined}
              expectedSettlementDate={transaction.originatingTransfer?.estimatedSettlementDate ?? null}
            />
          </GridItem>

          {/* Amount */}
          <GridItem alignSelf='center'>
            <Text color={baseFontColor} textAlign='right' >
              {amountFormatted}
            </Text>
          </GridItem>
        </Grid>
      </AnimatedDropDownRowRowContent>
      <AnimatedDropDownRowExpandedContent>
        <TransactionRowDropInfoDropdown
          isSelected={isSelected}
          categoryLabelFormatted={categoryLabelFormatted}
          longDescription={longDescription}
          fromAccount={fromAccount}
          toAccount={toAccount}
          dateFormatted={dateFormatted}
          isCheckManagementEnabled={isCheckManagementEnabled}
          originatingTransfer={transaction.originatingTransfer}
          expectedSettlementDate={expectedSettlementDate}
        />
      </AnimatedDropDownRowExpandedContent>
    </AnimatedDropDownRow>
  )
}
