import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Flex, Text } from '@chakra-ui/react'
import { getTotalCapitalOSBalance } from './utils'
import CapitalOSBusinessInfoComponent from './components/CapitalOSBusinessInfoComponent'
import CapitalOSPromoCardComponent from '../treasury/components/capital_os/CapitalOSPromoCardComponent'
import Page from '@/library/page/Page'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import {
  type GetCapitalOSPageData,
  type GetCapitalOSPageDataVariables
} from '@/graphql/__generated__/GetCapitalOSPageData'
import { GET_CAPITAL_OS_PAGE_DATA } from '@/graphql/queries/capital_os/GetCapitalOSPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import HeaderStatistic from '@/library/text/HeaderStatistic'
import { BorderRadius, Color, ContainerWidth, IconSize } from '@/theme/theme'
import { AvatarComponent } from '@/library/utility/AvatarComponent'
import { getCurrencyFormatted, safeGetFirstLetter } from '@/utils/stringUtils'
import { RouteName } from '@/api/routes'
import { CapitalOSAccountStatus } from '@/graphql/__generated__/globalTypes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function CardsPage (): ReactElement {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const navigate = useAltirNavigate()
  const {
    data,
    loading,
    error
  } = useQuery<GetCapitalOSPageData, GetCapitalOSPageDataVariables>(
    GET_CAPITAL_OS_PAGE_DATA, {
      variables: { organizationId: selectedOrganizationId }
    }
  )

  const businesses = data?.currentUser?.selectedOrganization?.franchiseGroups ?? []

  const totalBalanceFormatted = getTotalCapitalOSBalance(businesses)

  const hasActiveCard = businesses
    .some(business => business.capitalOSConfiguration?.account?.status === CapitalOSAccountStatus.APPROVED)

  return (
    <Page maxWidth={ContainerWidth.FULL_PAGE}>
      <AltirSkeleton isLoading={loading} error={error}>
        <Flex justifyContent='center' w='100%'>
          <Flex flexDir='column' gap={6} w='100%'>
            {!hasActiveCard && <CapitalOSPromoCardComponent/>}
            <HeaderStatistic
              description='Total Altir Card Balance'
              statistic={totalBalanceFormatted}
              isDisabled={!hasActiveCard}
            />
            <Flex flexDir='column' gap={3}>
              <Text>Altir Cards</Text>
              <Flex flexDir='column' gap={2}>
                {
                businesses.map((business, index) => {
                  const link = `/${String(RouteName.CAPTIAL_OS_CARDS)}/${business.id}`
                  const account = business.capitalOSConfiguration?.account
                  const isActive = account?.status === CapitalOSAccountStatus.APPROVED
                  return (
                    <Flex
                      key={index}
                      bg={isActive ? Color.WHITE : Color.GREY}
                      borderRadius={BorderRadius.CARD}
                      px={6}
                      py={4}
                      alignItems='center'
                      justifyContent='space-between'
                      minH='80px'
                      _hover={isActive ? { bg: Color.GREY, cursor: 'pointer' } : {}}
                      onClick={(e) => { isActive && navigate(link, e) }}
                    >
                      <Flex alignItems='center' gap={6}>
                        <AvatarComponent
                          text={safeGetFirstLetter(business.name)}
                          size={IconSize.SMALL}
                          backgroundColor={isActive ? Color.GREY : Color.WHITE}
                        />
                        <Flex flexDir='column'>
                          <Text color={Color.DARK_BLUE}>{business.name}</Text>
                          {isActive &&
                            <Text>{getCurrencyFormatted(account.currentLimit?.amount)} Limit</Text>
                          }
                        </Flex>
                      </Flex>
                      <CapitalOSBusinessInfoComponent business={business}/>
                    </Flex>
                  )
                })
              }
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </AltirSkeleton>
    </Page>
  )
}
