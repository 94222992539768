import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../../fragments/AuthorizationFragment'
import { FINANCIAL_ACCOUNT_SHORT_FRAGMENT } from '../../fragments/financial_accounts/FinancialAccountShortFragment'
import { TRANSFER_RULE_FRAGMENT } from '../../fragments/TransferRuleFragment'
export const GET_TRANSFER_RULES = gql`
  ${AUTHORIZATION_FRAGMENT}
  ${FINANCIAL_ACCOUNT_SHORT_FRAGMENT}
  ${TRANSFER_RULE_FRAGMENT}
  query GetTransferRules($organizationId: String!, $franchiseGroupId: Int!) {
    currentUser {
      franchiseGroup(id: $franchiseGroupId) {
        id
        hasAcknowledgedFraudulentReturnMessage
        transferRules {
            ...TransferRuleFragment
            fromAccount {
              ...FinancialAccountShortFragment
            }
            toAccount {
              ...FinancialAccountShortFragment
            }
        }
      }
      authorization(organizationId: $organizationId, franchiseGroupId: $franchiseGroupId) {
        ...AuthorizationFragment
      }
    }
  }
`
