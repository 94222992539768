import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import RecipientDetailCard from './RecipientDetailCard'
import RecipientTransferRulesWidget from './RecipientTransferRulesWidget'

interface RecipientMetadataComponentProps {
  counterpartyId: string
}

export default function RecipientMetadataComponent ({ counterpartyId }: RecipientMetadataComponentProps): ReactElement {
  return (
    <Center flexDir='column' gap={4} w='100%'>
      <RecipientDetailCard counterpartyId={counterpartyId}/>
      <RecipientTransferRulesWidget counterpartyId={counterpartyId}/>
    </Center>
  )
}
