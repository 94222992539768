import React, { type ReactElement } from 'react'
import { Text } from '@chakra-ui/react'
import {
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
}
  from '@/graphql/__generated__/GetTransferRules'
import { type FinancialAccountShortFragment } from '@/graphql/__generated__/FinancialAccountShortFragment'
import { getFormattedBalanceRule, isBalanceRule } from '@/utils/transferRuleUtils'
import { TransferRuleType } from '@/graphql/__generated__/globalTypes'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { Color } from '@/theme/theme'

interface TransferRuleAccountCellBalanceDescriptionProps {
  rule: TransferRule
  context: 'from' | 'to'
  account: FinancialAccountShortFragment
}

export default function TransferRuleAccountCellBalanceDescription ({
  rule,
  context,
  account
}: TransferRuleAccountCellBalanceDescriptionProps): ReactElement {
  if (!isBalanceRule(rule.transferRuleType)) {
    return <></>
  }

  const showThreshold = (context === 'to' && rule.transferRuleType === TransferRuleType.MIN_TARGET_BALANCE) ||
    (context === 'from' && rule.transferRuleType === TransferRuleType.TARGET_BALANCE)

  const { balance: threshold } = getFormattedBalanceRule(rule)

  const balance = getCurrencyFormatted(account.liveBalance?.availableBalance?.amount)
  return (
    <Text color={rule.isActive ? Color.DARK_BLUE : Color.DARK_GREY}>
      {balance} {showThreshold && <Text as='span' color={Color.DARK_GREY}>/ {threshold}</Text>}
    </Text>
  )
}
