import { gql } from '@apollo/client'
import { TRANSACTION_FRAGMENT } from '../../fragments/TransactionFragment'

export const GET_TRANSACTIONS = gql`
    ${TRANSACTION_FRAGMENT}
    query GetTransactions($organizationId: String!, $sort: TransactionSortInput, $filter:TransactionFilterInput, $offset: Int, $limit: Int, $includePending: Boolean) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                id
                transactions(sort: $sort, filter: $filter, offset: $offset, limit: $limit, includePending: $includePending) {
                    transactions {
                        ...TransactionFragment
                    }
                }
            }
        }
    }
`
