import React, { type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import AutomatedTransferRuleBalanceMetadata from './AutomatedTransferRuleBalanceMetadata'
import AutomatedTransferRuleTimeMetadata from './AutomatedTransferRuleTimeMetadata'
import RuleManagementModal from '../management/RuleManagementModal'
import TransferRuleDropdownErrorMessage from '../dropdown/TransferRuleDropdownErrorMessage'
import { type GetTransferRule_transferRule as TransferRule } from '@/graphql/__generated__/GetTransferRule'
import { isBalanceRule } from '@/utils/transferRuleUtils'
import { BorderRadius, Color } from '@/theme/theme'
import TransferCounterpartyComponent from '@/library/accounts/counterparty/TransferCounterpartyComponent'
import Badge, { BadgeVariant } from '@/library/badge/Badge'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'

interface AutomatedTransferRuleMetadataComponentProps {
  rule: TransferRule
  refetch: () => void
}

export default function AutomatedTransferRuleMetadataComponent ({
  rule,
  refetch
}: AutomatedTransferRuleMetadataComponentProps): ReactElement {
  const isBalanceBasedRule = isBalanceRule(rule.transferRuleType)
  return (
    <Center flexDir='column' w='100%' alignItems='start' gap={4}>
      <Center flexDir='column' w='100%' alignItems='start' gap={3}>
        <Text>Automated Transfer Rule Details</Text>
        <Center
          bg={Color.WHITE}
          p={6}
          borderRadius={BorderRadius.CARD}
          w='100%'
          flexDir='column'
          gap={4}
        >
          <Flex
            justifyContent='space-between'
            w='100%'
            gap={4}
          >
            <TransferCounterpartyComponent
              fromAccount={rule.fromAccount}
              toAccount={rule.toAccount}
            />
            {
                !rule.isActive &&
                <Flex alignItems='flex-start'>
                  <Badge title='Deactivated' badgeVariant={BadgeVariant.OUTLINE}/>
                </Flex>
            }
          </Flex>
          {
            isBalanceBasedRule
              ? <AutomatedTransferRuleBalanceMetadata rule={rule}/>
              : <AutomatedTransferRuleTimeMetadata rule={rule}/>
            }
          <TransferRuleDropdownErrorMessage transferRule={rule}/>
          {
            !rule.isActive &&
            <StackedInfoAttribute
              header='Deactivated On'
              subtext={`${getFormattedDateString(rule.updatedAt, DateTimeTemplate.MM_DD_YY_SLASH) ?? 'N/A'}`}
            />
            }
        </Center>
      </Center>
      <RuleManagementModal rule={rule} canEditTransferRules={true} onUpdate={refetch}/>
    </Center>
  )
}
