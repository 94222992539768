import React, { type ReactElement } from 'react'

import { useQuery } from '@apollo/client'
import {
  type GetActiveTransferRules,
  type GetActiveTransferRulesVariables
} from '@/graphql/__generated__/GetActiveTransferRules'
import { GET_ACTIVE_TRANSFER_RULES } from '@/graphql/queries/transfer_rules/GetActiveTransferRules'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import TransferRulesCountDropDown from '@/library/transfer_rules/TransferRulesCountDropDown'

// TODO(ALT 1878): add number of transfers
export default function ViewAutomatedTransferRulesCard (): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const { data, loading, error } = useQuery<GetActiveTransferRules, GetActiveTransferRulesVariables>(
    GET_ACTIVE_TRANSFER_RULES,
    {
      variables: { franchiseGroupId: selectedFranchiseGroupId }
    }
  )

  const transferRuleCount = (data?.currentUser?.franchiseGroup?.transferRules ?? []).length

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <TransferRulesCountDropDown count={transferRuleCount}/>
    </AltirSkeleton>
  )
}
