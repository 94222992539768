import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { SortDirection, type TableColumnSortSelection } from './TableHeaderRow'
import { TABLE_DROPDOWN_CONTENT, type TableHeader } from '../../Table'
import ArrowUpIcon from '@/library/icons/ArrowUpIcon'
import ArrowDownIcon from '@/library/icons/ArrowDownIcon'
import { Color, IconSize } from '@/theme/theme'

interface TableHeaderCellProps<T> {
  column: TableHeader<T>
  onColumnClick?: (key: keyof T) => void
  sortSelection?: TableColumnSortSelection<T>

}

export default function TableHeaderCell<T> ({
  column,
  onColumnClick = () => {},
  sortSelection
}: TableHeaderCellProps<T>): ReactElement {
  if (column.key === TABLE_DROPDOWN_CONTENT) {
    return <></>
  }

  const isSelected = sortSelection?.key === column.key
  return (
    <Flex
      gap={1}
      onClick={() => { onColumnClick(column.key) }}
      textAlign={column.alignment ?? 'left'}
      justifyContent={column.alignment ?? 'start'}
      alignItems='center'
      _hover={{ cursor: 'pointer' }}
    >
      <Text
        color={isSelected ? Color.DARK_BLUE : Color.DARK_GREY}
        fontSize='sm'
      >
        {column.header}
      </Text>
      {
          isSelected
            ? sortSelection.direction === SortDirection.ASC
              ? <ArrowUpIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>
              : <ArrowDownIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>
            : null
        }
    </Flex>
  )
}
