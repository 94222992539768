import React, { type ReactElement } from 'react'
import { Center, Flex } from '@chakra-ui/react'
import BrandMetadataComponent from './components.tsx/BrandMetadataComponent'
import FranchiseesSectionComponent from './components.tsx/FranchiseesSectionComponent'
import Page from '@/library/page/Page'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function FranchisorPage (): ReactElement {
  const franchiseGroupId = useAltirStore(store => store.selectedFranchiseGroupId)
  return (
    <Page>
      <Center w='100%' gap={6} alignItems='start'>
        <Flex flex={1}>
          <BrandMetadataComponent franchiseGroupId={franchiseGroupId}/>
        </Flex>
        <Flex flex={3}>
          <FranchiseesSectionComponent franchiseGroupId={franchiseGroupId}/>
        </Flex>
      </Center>
    </Page>
  )
}
