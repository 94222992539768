import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import CheckRecipientDetailsFormSection from './CheckRecipientDetailsFormSection'
import { FormInput } from '@/library/form/text/FormInput'
import { Color } from '@/theme/theme'
import FormTextArea from '@/library/form/text/FormTextArea'

export interface CheckIssuanceFormState {
  memo?: string
  message?: string
  areCounterpartyCheckFieldsValid: boolean
}

interface CheckIssuanceFormSectionsProps {
  selectedCounterpartyId: string | null
  formState: CheckIssuanceFormState
  onStateUpdate: (state: CheckIssuanceFormState) => void
}

export default function CheckIssuanceFormSection ({
  selectedCounterpartyId,
  formState,
  onStateUpdate
}: CheckIssuanceFormSectionsProps): ReactElement {
  function onChange (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const { name, value } = event.target
    updateForm(name, value)
  }
  function onSuccessfulCounterpartyUpdate (): void {
    updateForm('areCounterpartyCheckFieldsValid', true)
  }

  function updateForm (name: string, value: string | boolean): void {
    onStateUpdate({
      ...formState,
      [name]: value
    })
  }

  return (
    <Center flexDir='column' w='100%' alignItems='start' gap={4}>
      {
        selectedCounterpartyId != null &&
          <CheckRecipientDetailsFormSection
            selectedCounterpartyId={selectedCounterpartyId}
            onSuccessfulCounterpartyUpdate={onSuccessfulCounterpartyUpdate}
          />
      }
      <FormInput
        fieldName='memo'
        label='Memo'
        value={formState.memo}
        fieldError={undefined}
        onChange={onChange}
        isRequired={true}
        placeholder='i.e. Design Services'
        backgroundColor={Color.WHITE}
      />
      <FormTextArea
        fieldName='message'
        label='Message'
        value={formState.message}
        onChange={onChange}
        placeholder='i.e. Thank you!'
      />
    </Center>
  )
}
