import React, { type ReactElement } from 'react'
import { Box, Button as BaseButton, Flex, Tooltip, useBoolean } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import {
  BorderRadius,
  ButtonHeight,
  Color,
  FontWeight
} from '../theme/theme'
import Loader from '../loading/Loader'
import { logEvent } from '@/utils/metricUtils'
import { type CustomTheme } from '@/theme/customThemes'

export enum ButtonVariant {
  PRIMARY,
  PRIMARY_TRANSPARENT,
  WHITE,
  WHITE_GREY_BACKGROUND,
  WHITE_OUTLINE,
  DESTRUCTIVE,
  DESTRUCTIVE_TRANSPARENT,
  DESTRUCTIVE_GHOST,
  GHOST,
  GREY,
  LINK_TRANSPARENT
}

export enum ButtonSize {
  X_SMALL,
  SMALL,
  MEDIUM,
  LARGE
}

export interface ButtonVariantProps {
  color: string
  hoverColor: string
  backgroundColor: string
  hoverBackgroundColor: string
  borderColor: string
  hoverBorderColor: string
  removePadding: boolean
  fontWeight: FontWeight
}

export interface ButtonSizeProps {
  height: string
  fontSize: string
  spacing: number
}

type ButtonStyleProps = ButtonVariantProps & ButtonSizeProps

export interface ButtonProps {
  // Elements
  text?: string | null
  beforeIcon?: ReactElement
  afterIcon?: ReactElement
  toolTipText?: string

  // State
  isLoading?: boolean
  isDisabled?: boolean

  // Variants
  variant?: ButtonVariant
  size?: ButtonSize

  // Styles
  width?: string
  justifyContent?: string
  customTheme?: CustomTheme
  flex?: number

  // Actions
  onClick?: (e: React.MouseEvent | null) => void
  onClickEventType?: Event
}

export default function Button (
  {
    text,
    beforeIcon,
    afterIcon,
    toolTipText,
    isDisabled = false,
    isLoading = false,
    variant = ButtonVariant.PRIMARY,
    size = ButtonSize.MEDIUM,
    onClick,
    onClickEventType = Event.BUTTON_CLICK,
    justifyContent = 'center',
    width = '100%',
    customTheme,
    flex
  }: ButtonProps
): ReactElement {
  const [isHovered, setIsHovered] = useBoolean()

  function onClickWithTracking (e: React.MouseEvent): void {
    if (!isDisabled) {
      logEvent(onClickEventType)
      onClick?.(e)
    }
  }

  const buttonProps = getButtonProps(variant, size, isDisabled, customTheme)
  const cursor = isDisabled ? 'not-allowed' : 'pointer'

  // Pass hover state to icon
  const beforeIconWithProps = beforeIcon != null
    ? React.cloneElement(beforeIcon, { color: isHovered ? buttonProps.hoverColor : buttonProps.color })
    : null
  const afterIconWithProps = afterIcon != null
    ? React.cloneElement(afterIcon, { color: isHovered ? buttonProps.hoverColor : buttonProps.color })
    : null

  const hasBeforeElement = isLoading || beforeIconWithProps != null

  return (
    <Tooltip
      label={toolTipText}
    >
      <BaseButton
        onClick={onClickWithTracking}
        flex={flex}
        bg={buttonProps.backgroundColor}
        color={buttonProps.color}
        h={buttonProps.height}
        borderRadius={BorderRadius.BUTTON}
        _hover={{
          cursor,
          backgroundColor: buttonProps.hoverBackgroundColor,
          color: buttonProps.hoverColor,
          borderColor: buttonProps.hoverBorderColor
        }}
        border={`1px solid ${buttonProps.borderColor}`}
        type='submit'
        fontSize={buttonProps.fontSize}
        disabled={isDisabled}
        w={buttonProps.removePadding ? undefined : width}
        onMouseEnter={setIsHovered.on}
        onMouseLeave={setIsHovered.off}
        px={buttonProps.removePadding ? 0 : 4}
      >
        <Flex
          justifyContent={justifyContent}
          alignItems='center'
          w='100%'
          px={buttonProps.removePadding ? 0 : 4}
          gap={buttonProps.spacing}
        >
          {hasBeforeElement && <Flex alignItems='center' justifyContent='center'>
            {isLoading ? <Loader size='12px' py='0px'/> : null}
            {!isLoading && beforeIconWithProps != null ? beforeIconWithProps : null}
          </Flex>}
          <Box fontWeight={buttonProps.fontWeight} fontSize={getFontSize(size)}>
            {text}
          </Box>
          {afterIconWithProps != null ? <Box>{afterIconWithProps}</Box> : null}
        </Flex>
      </BaseButton>
    </Tooltip>
  )
}

function getButtonProps (
  variant: ButtonVariant,
  size: ButtonSize,
  isDisabled: boolean,
  customTheme?: CustomTheme
): ButtonStyleProps {
  return {
    ...getButtonSizeProps(size),
    ...getButtonStyleProps(variant, isDisabled, customTheme)
  }
}

function getButtonSizeProps (size: ButtonSize): ButtonSizeProps {
  switch (size) {
    case ButtonSize.X_SMALL:
      return {
        height: ButtonHeight.X_SMALL,
        fontSize: 'sm',
        spacing: 2
      }
    case ButtonSize.SMALL:
      return {
        height: ButtonHeight.SMALL,
        fontSize: 'md',
        spacing: 2
      }
    case ButtonSize.MEDIUM:
      return {
        height: ButtonHeight.MEDIUM,
        fontSize: 'lg',
        spacing: 4
      }
    case ButtonSize.LARGE:
      return {
        height: ButtonHeight.LARGE,
        fontSize: 'xl',
        spacing: 4
      }
  }
}

// TODO (PJ): make da links thin
function getButtonStyleProps (
  variant: ButtonVariant,
  isDisabled: boolean,
  customTheme?: CustomTheme
): ButtonVariantProps {
  const defaultButtonProps = {
    removePadding: false,
    fontWeight: FontWeight.MEDIUM
  }
  if (isDisabled) {
    const isOnGreyBackground = variant === ButtonVariant.WHITE_GREY_BACKGROUND
    return {
      ...defaultButtonProps,
      color: Color.DARK_GREY,
      hoverColor: Color.DARK_GREY,
      backgroundColor: Color.GREY,
      hoverBackgroundColor: Color.GREY,
      borderColor: isOnGreyBackground ? Color.DARK_GREY : Color.GREY,
      hoverBorderColor: isOnGreyBackground ? Color.DARK_GREY : Color.GREY
    }
  }

  switch (variant) {
    case ButtonVariant.PRIMARY:
      return {
        ...defaultButtonProps,
        color: Color.WHITE,
        hoverColor: Color.WHITE,
        backgroundColor: customTheme?.color.primary ?? Color.BRIGHT_BLUE,
        hoverBackgroundColor: customTheme?.color.primaryHover ?? Color.BRIGHT_BLUE_HOVER,
        borderColor: customTheme?.color.primary ?? Color.BRIGHT_BLUE,
        hoverBorderColor: customTheme?.color.primaryHover ?? Color.BRIGHT_BLUE_HOVER
      }
    case ButtonVariant.PRIMARY_TRANSPARENT:
      return {
        ...defaultButtonProps,
        color: Color.BRIGHT_BLUE,
        hoverColor: Color.WHITE,
        backgroundColor: 'transparent',
        hoverBackgroundColor: Color.BRIGHT_BLUE,
        borderColor: Color.BRIGHT_BLUE,
        hoverBorderColor: Color.BRIGHT_BLUE
      }
    case ButtonVariant.DESTRUCTIVE:
      return {
        ...defaultButtonProps,
        color: Color.WHITE,
        hoverColor: Color.WHITE,
        backgroundColor: Color.ERROR_RED,
        hoverBackgroundColor: Color.ERROR_RED_HOVER,
        borderColor: Color.ERROR_RED,
        hoverBorderColor: Color.ERROR_RED_HOVER
      }
    case ButtonVariant.DESTRUCTIVE_TRANSPARENT:
      return {
        ...defaultButtonProps,
        color: Color.ERROR_RED,
        hoverColor: Color.ERROR_RED_HOVER,
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'transparent',
        borderColor: Color.ERROR_RED,
        hoverBorderColor: Color.ERROR_RED_HOVER
      }
    case ButtonVariant.DESTRUCTIVE_GHOST:
      return {
        ...defaultButtonProps,
        color: Color.ERROR_RED,
        hoverColor: Color.ERROR_RED_HOVER,
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'transparent',
        borderColor: 'transparent',
        hoverBorderColor: 'transparent',
        removePadding: true,
        fontWeight: FontWeight.NORMAL
      }
    case ButtonVariant.WHITE:
      return {
        ...defaultButtonProps,
        color: Color.DARK_BLUE,
        hoverColor: Color.DARK_BLUE,
        backgroundColor: Color.WHITE,
        hoverBackgroundColor: Color.GREY,
        borderColor: Color.WHITE,
        hoverBorderColor: Color.GREY
      }
    case ButtonVariant.WHITE_GREY_BACKGROUND:
      return {
        ...defaultButtonProps,
        color: Color.DARK_BLUE,
        hoverColor: Color.DARK_BLUE,
        backgroundColor: Color.WHITE,
        hoverBackgroundColor: Color.LIGHT_GREY,
        borderColor: Color.WHITE,
        hoverBorderColor: Color.GREY
      }
    case ButtonVariant.WHITE_OUTLINE:
      return {
        ...defaultButtonProps,
        color: Color.DARK_BLUE,
        hoverColor: Color.DARK_BLUE,
        backgroundColor: Color.WHITE,
        hoverBackgroundColor: Color.GREY,
        borderColor: Color.DARK_BLUE,
        hoverBorderColor: Color.DARK_BLUE
      }
    case ButtonVariant.GHOST:
      return {
        ...defaultButtonProps,
        color: Color.GREY_BLUE,
        hoverColor: Color.BRIGHT_BLUE_HOVER,
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'transparent',
        borderColor: 'transparent',
        hoverBorderColor: 'transparent'
      }
    case ButtonVariant.GREY:
      return {
        ...defaultButtonProps,
        color: Color.DARK_BLUE,
        hoverColor: Color.DARK_BLUE,
        backgroundColor: Color.GREY,
        hoverBackgroundColor: Color.MEDIUM_GREY,
        borderColor: Color.GREY,
        hoverBorderColor: Color.MEDIUM_GREY
      }
    case ButtonVariant.LINK_TRANSPARENT:
      return {
        ...defaultButtonProps,
        color: Color.BRIGHT_BLUE_HOVER,
        hoverColor: Color.DARK_BLUE,
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'transparent',
        borderColor: 'transparent',
        hoverBorderColor: 'transparent',
        removePadding: true,
        fontWeight: FontWeight.NORMAL
      }
  }
}

function getFontSize (size: ButtonSize): string {
  switch (size) {
    case ButtonSize.X_SMALL:
      return 'xs'
    case ButtonSize.SMALL:
      return 'md'
    case ButtonSize.MEDIUM:
      return 'md'
    case ButtonSize.LARGE:
      return 'lg'
  }
}
