import React, { Fragment, useState, type ReactElement } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import TableBodyRow from './TableBodyRow'
import TableDivider from './TableDivider'
import { TABLE_DROPDOWN_CONTENT, type TableHeader, type TableData } from '../../Table'
import { BorderRadius, type Color } from '@/theme/theme'

interface TableBodyProps<T extends TableData> {
  tableMetadata: Array<TableHeader<T>>
  data: T[]
  templateColumns: string
  gridHorizontalPadding: number

  // Styles
  textColor: Color
}

export default function TableBody<T extends TableData> ({
  tableMetadata,
  data,
  templateColumns,
  gridHorizontalPadding,
  textColor
}: TableBodyProps<T>): ReactElement {
  const [selectedRow, setSelectedRow] = useState<number | null>(null)
  return (
    <Grid
      templateColumns={templateColumns}
      w='100%'
      rowGap={2}
      alignItems='center'
      overflow='hidden'
      borderRadius={BorderRadius.CARD}
      px={gridHorizontalPadding}
      py={4}
    >
      {
        data.map((row, index) => {
          const dropDownContent = getDropDownContent(row)
          return (
            <Fragment key={index}>
              <TableBodyRow
                tableMetadata={tableMetadata}
                row={row}
                onDropdownToggle={() => { setSelectedRow(selectedRow === index ? null : index) }}
                isDropdownOpen={selectedRow === index}
                textColor={textColor}
              />
              {selectedRow === index && dropDownContent != null && (
                <GridItem colStart={1} colEnd={tableMetadata.length + 1}>
                  {dropDownContent}
                </GridItem>
              )}
              <TableDivider currentRowIndex={index} totalRows={data.length} totalColumns={tableMetadata.length}/>
            </Fragment>
          )
        })
    }
    </Grid>
  )
}

function getDropDownContent<T extends TableData> (row: T): ReactElement | null {
  if (typeof row === 'object' && row != null && TABLE_DROPDOWN_CONTENT in row) {
    return row[TABLE_DROPDOWN_CONTENT]?.value as ReactElement
  }
  return null
}
