import {
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Radio,
  Text
} from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import NewExternalRecipientCard from './NewExternalRecipientCard'
import TransferFeeLineItem from './TransferFeeLineItem'
import Button, { ButtonVariant } from '@/library/button/Button'
import { BorderRadius, Color, FontWeight } from '@/theme/theme'
import { type TransferType } from '@/graphql/__generated__/globalTypes'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { getTransferComponentContent } from '@/utils/transferUtils'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import TransferCounterpartyComponent from '@/library/accounts/counterparty/TransferCounterpartyComponent'

export interface ConfirmTransferModalProps {
  isOpen: boolean
  onClose: () => void
  amount: number
  fromAccount?: FinancialAccountExtendedFragment
  toAccount?: FinancialAccountExtendedFragment
  onConfirm: () => void
  isTransferCreationLoading: boolean
  transferType: TransferType
}

export function ConfirmTransferModal ({
  isOpen,
  onClose,
  amount,
  fromAccount,
  toAccount,
  onConfirm,
  isTransferCreationLoading,
  transferType
}: ConfirmTransferModalProps): ReactElement {
  const isUnverifiedExternalCounterparty =
    toAccount?.counterparty != null && !toAccount.counterparty.isOwnershipVerified
  const {
    transferTotal,
    agreementUrl,
    transferTypeFormatted,
    transferFee
  } = getTransferComponentContent(amount, transferType)
  const amountFormattedString = getCurrencyFormatted(Number(amount), { minimumFractionDigits: 2 })
  const totalFormattedString = getCurrencyFormatted(Number(transferTotal), { minimumFractionDigits: 2 })

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      <Center flexDir='column' w='100%' gap={6}>
        <Heading size='lg'>Confirm Transfer</Heading>
        <Heading size='2xl'>{amountFormattedString}</Heading>
        <Flex
          flexDirection='column'
          width='100%'
          borderRadius={BorderRadius.CARD}
          gap={8}
        >

          <TransferCounterpartyComponent fromAccount={fromAccount} toAccount={toAccount}/>
          <Grid
            padding={6}
            gap={1}
            templateColumns='repeat(2, minmax(0, 1fr))'
            backgroundColor={Color.WHITE}
            borderRadius={BorderRadius.CARD}
            alignItems='center'
          >
            <GridItem justifySelf='start'>
              <Text color={Color.DARK_GREY}>
                Method
              </Text>
            </GridItem>
            <GridItem justifySelf='end'>
              <Text color={Color.DARK_BLUE}>{transferTypeFormatted}</Text></GridItem>
            <GridItem justifySelf='start'>
              <Text color={Color.DARK_GREY}>Amount</Text>
            </GridItem>
            <GridItem justifySelf='end'>
              <Text color={Color.DARK_BLUE}>{amountFormattedString}</Text></GridItem>
            <GridItem justifySelf='start'>
              <Text color={Color.DARK_GREY}>Fee</Text>
            </GridItem>
            <TransferFeeLineItem transferFee={transferFee}/>
            <GridItem colStart={1} colEnd={3}>
              <Divider my={2} color={Color.GREY}/>
            </GridItem>
            <GridItem justifySelf='start'>
              <Text color={Color.DARK_GREY}>
                Total Withdrawn
              </Text>
            </GridItem>
            <GridItem justifySelf='end'>
              <Text color={Color.DARK_BLUE}>{totalFormattedString}</Text></GridItem>
          </Grid>
          {isUnverifiedExternalCounterparty && <NewExternalRecipientCard/>}
          {
            agreementUrl != null &&
              <Flex gap={4}>
                <Radio isChecked size='lg' colorScheme='selectableInput'/>
                <Flex flexDirection='column'>
                  <Text>I have read and understand the</Text>
                  <Link href={agreementUrl} isExternal>
                    <Text
                      color={Color.BRIGHT_BLUE}
                      fontWeight={FontWeight.BOLD}
                      textDecoration='underline'
                      _hover={{ color: Color.BRIGHT_BLUE_HOVER }}
                    >
                      {transferTypeFormatted} Authorization Agreement
                    </Text>
                  </Link>
                </Flex>
              </Flex>
          }
          <Flex flexDirection='column' gap={3}>
            <Flex>
              <Button
                text='Confirm'
                onClick={onConfirm}
                isLoading={isTransferCreationLoading}
                isDisabled={isTransferCreationLoading}
                onClickEventType={Event.CONFIRM_TRANSFER_CLICK}
              />
            </Flex>
            <Button
              text='Go Back'
              onClick={onClose}
              variant={ButtonVariant.WHITE}
            />
          </Flex>
        </Flex>
      </Center>
    </ModalComponent>
  )
}
