import React, { useState, type ReactElement } from 'react'
import { Center, Flex, Heading, Text } from '@chakra-ui/react'
import ExternalPersonalCreationPlaidWarning from './ExternalPersonalCreationPlaidWarning'
import { type AddBankingDetailsFormData } from './banking_details/AddBankingDetailsForm'
import RecipientBankingDetailsDropdown from './banking_details/RecipientBankingDetailsDropdown'
import RecipientCheckDetailsDropdown from './check_details/RecipientCheckDetailsDropdown'
import { FormInput } from '@/library/form/text/FormInput'
import SlideSelect from '@/library/slide_select/SlideSelect'
import { CounterpartyType } from '@/graphql/__generated__/globalTypes'
import { type ErrorWithContent, type FormErrorState } from '@/types/types'
import Button, { ButtonVariant } from '@/library/button/Button'
import ErrorInline from '@/library/errors/ErrorInline'
import MoreInfoTooltip from '@/library/utility/MoreInfoTooltip'
import {
  type CheckRecipientDetailsFormData
} from '@/components/pages/transfer/components/check_details/CheckRecipientDetailsForm'

const RECIPIENT_INFO_TEXT = `
  A recipient is either a trusted vendor you transact with or an account you own that cannot be linked to Altir. 
`

export interface AddNewRecipientDetailsFormData {
  recipientNickname?: string
  counterpartyType: CounterpartyType
  bankingDetails: AddBankingDetailsFormData | null
  checkDetails: CheckRecipientDetailsFormData | null
}
export type AddNewRecipientDetailsFormSubmissionData = Omit<AddNewRecipientDetailsFormData, 'recipientNickname'> & {
  recipientNickname: string
}

type AddNewRecipientDetailsFormErrorState = FormErrorState<AddNewRecipientDetailsFormData>

interface AddNewRecipientDetailsComponentProps {
  isSubmissionLoading: boolean
  submissionError?: ErrorWithContent
  onSubmit: (formData: AddNewRecipientDetailsFormSubmissionData) => void
  onCancel: () => void
  isCheckIssuanceEnabled: boolean
}

export default function AddNewRecipientDetailsComponent ({
  isSubmissionLoading,
  submissionError,
  onSubmit,
  onCancel,
  isCheckIssuanceEnabled
}: AddNewRecipientDetailsComponentProps): ReactElement {
  const [formData, setFormData] = useState<AddNewRecipientDetailsFormData>({
    counterpartyType: CounterpartyType.EXTERNAL_VENDOR,
    bankingDetails: null,
    checkDetails: null
  })
  const [inlineErrorState, setInlineErrorState] = useState<AddNewRecipientDetailsFormErrorState>({})

  function handleRecipientTypeSelection (value: string): void {
    if (value === CounterpartyType.EXTERNAL_PERSONAL || value === CounterpartyType.EXTERNAL_VENDOR) {
      updateForm('counterpartyType', value)
    }
  }

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    updateForm(name, value)
  }

  function updateForm (name: string, value: string): void {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function onBankingDetailsUpdate (newBankingDetails: AddBankingDetailsFormData): void {
    setFormData((prevFormData) => ({
      ...prevFormData,
      bankingDetails: newBankingDetails
    }))
  }

  function onCheckDetailsUpdate (newCheckDetails: CheckRecipientDetailsFormData): void {
    setFormData((prevFormData) => ({
      ...prevFormData,
      checkDetails: newCheckDetails
    }))
  }

  function handleFormSubmission (): void {
    if (formData.recipientNickname == null) {
      setInlineErrorState({ recipientNickname: 'Field required' })
      return
    }
    onSubmit(formData as AddNewRecipientDetailsFormSubmissionData)
  }

  const isFormValidForSubmission = (formData.bankingDetails != null) &&
    formData.recipientNickname != null

  const disabledTooltipText = !isFormValidForSubmission
    ? getDisabledMessage(formData)
    : undefined
  return (
    <Center flexDir='column' alignItems='start' gap={6} w='100%'>
      <Center alignItems='start' flexDir='column' w='100%' gap={1}>
        <Flex alignItems='center' gap={2}>
          <Heading size='md'>
            Add a New Recipient
          </Heading>
          <MoreInfoTooltip tooltipText={RECIPIENT_INFO_TEXT}/>
        </Flex>
        <Text>Add either banking information, check information, or both.</Text>
      </Center>
      <SlideSelect
        selectedValue={formData.counterpartyType}
        options={[CounterpartyType.EXTERNAL_VENDOR, CounterpartyType.EXTERNAL_PERSONAL]}
        handleSelection={handleRecipientTypeSelection}
        formatValue={value => {
          return value === CounterpartyType.EXTERNAL_PERSONAL ? 'This is my Account' : 'This is a Vendor'
        }}
        width='346px'
      />
      {
        formData.counterpartyType === CounterpartyType.EXTERNAL_PERSONAL &&
        <ExternalPersonalCreationPlaidWarning onSuccessfulLink={onCancel}/>
        }
      <FormInput
        fieldName='recipientNickname'
        label='Recipient Nickname'
        value={formData.recipientNickname ?? ''}
        fieldError={inlineErrorState.recipientNickname}
        onChange={onChange}
        placeholder='i.e. Sysco Foods'
        isRequired
      />
      <Center w='100%' flexDir='column' gap={2}>
        <RecipientBankingDetailsDropdown
          onSubmit={onBankingDetailsUpdate}
          globalFormData={formData.bankingDetails}
        />
        {
          isCheckIssuanceEnabled &&
            <RecipientCheckDetailsDropdown
              globalFormData={formData.checkDetails}
              onSubmit={onCheckDetailsUpdate}
            />
        }
      </Center>
      <ErrorInline error={submissionError}/>
      <Center w='100%' flexDir='column' gap={4}>
        <Button
          text='Continue'
          isLoading={isSubmissionLoading}
          isDisabled={!isFormValidForSubmission}
          onClick={() => { handleFormSubmission() }}
          toolTipText={disabledTooltipText}
        />
        <Button
          text='Cancel'
          onClick={onCancel}
          variant={ButtonVariant.WHITE}
        />
      </Center>
    </Center>
  )
}

function getDisabledMessage (
  formData: AddNewRecipientDetailsFormData
): string | undefined {
  if (formData.recipientNickname == null) return 'Please enter a nickname for the recipient.'

  if (formData.bankingDetails == null && formData.checkDetails == null) {
    return 'Please add your recipient\'s banking details or check details.'
  }

  return undefined
}
