import React, { type ReactElement } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import StackedInfoAttribute from '../utility/StackedInfoAttribute'
import {
  type TransferFragment as Transfer
} from '@/graphql/__generated__/TransferFragment'
import { isEmpty } from '@/utils/stringUtils'
import CheckManagementComponent from '@/components/pages/checks/components/CheckManagementComponent'
import { TransferType } from '@/graphql/__generated__/globalTypes'

export interface TransactionRowDropdownContentProps {
  categoryLabelFormatted: string
  longDescription: string
  fromAccount: string | null
  toAccount: string | null
  dateFormatted: string | null
  timeFormatted?: string
  originatingTransfer: Transfer | null
  expectedSettlementDate: string | null

  isCheckManagementEnabled: boolean
}

export default function TransactionRowDropdownContent ({
  categoryLabelFormatted,
  longDescription,
  fromAccount,
  toAccount,
  dateFormatted,
  timeFormatted,
  originatingTransfer,
  expectedSettlementDate,
  isCheckManagementEnabled
}: TransactionRowDropdownContentProps): ReactElement {
  return (
    <Grid templateColumns='repeat(6, 1fr)' templateRows='1' rowGap={4}>
      {
      fromAccount != null &&
        <GridItem colSpan={1}>
          <StackedInfoAttribute header='From' subtext={fromAccount}/>
        </GridItem>
    }
      {
      toAccount != null &&
        <GridItem colSpan={1}>
          <StackedInfoAttribute header='To' subtext={toAccount}/>
        </GridItem>
    }
      {
      dateFormatted != null &&
        <GridItem colSpan={1}>
          <StackedInfoAttribute header='Date' subtext={dateFormatted}/>
        </GridItem>
    }
      {
      expectedSettlementDate != null &&
        <GridItem colSpan={1}>
          <StackedInfoAttribute header='Expected Settlement Date' subtext={expectedSettlementDate}/>
        </GridItem>
    }
      {
      timeFormatted != null &&
        <GridItem colSpan={1}>
          <StackedInfoAttribute header='Time' subtext={timeFormatted}/>
        </GridItem>
    }
      <GridItem colSpan={6}>
        <StackedInfoAttribute header='Category' subtext={categoryLabelFormatted}/>
      </GridItem>
      {
      !isEmpty(longDescription) &&
        <GridItem colSpan={6}>
          <StackedInfoAttribute header='Description' subtext={longDescription}/>
        </GridItem>
    }
      {
      originatingTransfer?.checkMemo != null &&
        <GridItem colSpan={6}>
          <StackedInfoAttribute header='Check Memo' subtext={originatingTransfer.checkMemo}/>
        </GridItem>
    }
      {
      originatingTransfer?.checkMessage != null &&
        <GridItem colSpan={6}>
          <StackedInfoAttribute header='Check Message' subtext={originatingTransfer.checkMessage}/>
        </GridItem>
    }
      {
      isCheckManagementEnabled && originatingTransfer?.type === TransferType.CHECK &&
        <GridItem colSpan={6}>
          <CheckManagementComponent check={originatingTransfer}/>
        </GridItem>
    }
    </Grid>
  )
}
