import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import {
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
}
  from '@/graphql/__generated__/GetTransferRules'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import { Color } from '@/theme/theme'

interface TransferRuleRecentTransferCellProps {
  rule: TransferRule
}

export default function TransferRuleRecentTransferCell ({ rule }: TransferRuleRecentTransferCellProps): ReactElement {
  const mostRecentTransfer = rule.mostRecentTransfer
  if (mostRecentTransfer == null) {
    return <></>
  }

  const amount = getCurrencyFormatted(mostRecentTransfer.amount.amount)
  const date = getFormattedDateString(mostRecentTransfer.createdAt, DateTimeTemplate.MM_DD_YY_SLASH)
  return (
    <Flex flexDir='column'>
      <Text color={Color.DARK_BLUE}>{date}</Text>
      <Text>{amount}</Text>
    </Flex>
  )
}
