import { gql } from '@apollo/client'
import { FINANCIAL_ACCOUNT_FRAGMENT } from '../../fragments/financial_accounts/FinancialAccountFragment'
import { TRANSFER_FRAGMENT } from '../../fragments/TransferFragment'

export const GET_RECENT_RECIPIENTS_PAGE = gql`
    ${TRANSFER_FRAGMENT}
    ${FINANCIAL_ACCOUNT_FRAGMENT}
    query GetRecentRecipientsPage(
        $counterpartyTypes: [CounterpartyType!], 
        $franchiseGroupId: Int!,
        $transferLimit: Int 
    ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                amplifyAccounts {
                    ...FinancialAccountFragment
                }
                counterparties(counterpartyTypes: $counterpartyTypes) {
                    treasuryPrimeId
                    counterpartyType
                    isOwnershipVerified
                    createdAt
                    name
                    achAccountNumber
                    transfers(limit: $transferLimit) {
                        ...TransferFragment
                    }
                }
            }
        }
    }
`
