import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { type GetHomePageData } from '@/graphql/__generated__/GetHomePageData'
import { BorderRadius, BoxShadow, Color, IconSize } from '@/theme/theme'
import { AvatarComponent } from '@/library/utility/AvatarComponent'
import { safeGetFirstLetter } from '@/utils/stringUtils'
import PopoverIconMenu from '@/library/popoverMenu/PopoverIconMenu'
import { ROUTES } from '@/api/routes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface OrganizationSectionProps {
  data?: GetHomePageData | null
}

export default function OrganizationSection ({ data }: OrganizationSectionProps): ReactElement {
  const navigate = useAltirNavigate()

  const organization = data?.currentUser?.selectedOrganization
  const numberOfUsers = organization?.users.length ?? 0
  const numberOfBusinesses = organization?.franchiseGroups?.length ?? 0
  const numberOfAmplifyAccounts = organization
    ?.franchiseGroups
    ?.map(group => group.financialAccounts)
    .flat()
    ?.filter(account => account?.amplifyAccount?.id != null)
    .length ?? 0
  return (
    <Flex flexDir='column' gap={3}>
      <Text>My Organization</Text>
      <Flex
        bg={Color.LIGHT_GREY}
        borderRadius={BorderRadius.CARD}
        boxShadow={BoxShadow.LIGHT}
        p={6}
        alignItems='center'
        justifyContent='space-between'
      >
        <Flex alignItems='center' gap={6}>
          <AvatarComponent
            text={safeGetFirstLetter(organization?.name)}
            size={IconSize.MEDIUM}
            backgroundColor={Color.WHITE}
          />
          <Text color={Color.DARK_BLUE}>{organization?.name}</Text>
        </Flex>
        <Flex alignItems='center' gap={6} justifyContent='end' textAlign='end'>
          {/* TODO (PJ) Populate org metadata */}
          <Text>{getMetadataString(numberOfUsers, numberOfBusinesses, numberOfAmplifyAccounts)}</Text>
          <PopoverIconMenu
            backgroundColor={Color.LIGHT_GREY}
            menuOptions={[{
              text: 'Manage Organization',
              onClick: (e: React.MouseEvent | null) => { navigate(ROUTES.SETTINGS, e) }
            }]}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

function getMetadataString (numUsers: number, numBusinesses: number, numAccounts: number): string {
  const businessString = numBusinesses === 1 ? '1 Business' : `${numBusinesses} Businesses`
  const accountsString = numAccounts === 1 ? '1 Amplify Account' : `${numAccounts} Amplify Accounts`
  const userString = numUsers === 1 ? '1 User' : `${numUsers} Users`
  return `${businessString} | ${accountsString} | ${userString}`
}
