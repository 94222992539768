/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function ArrowDownIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M5.00001 9.17662L8.00011 12.0002L11.0002 9.17662M8.00011 3.99997L8.00011 11.7649' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </IconContainer>
  )
}
