import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import TransferRuleAccountCellBalanceDescription from './TransferRuleAccountCellBalanceDescription'
import {
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
}
  from '@/graphql/__generated__/GetTransferRules'
import { Color } from '@/theme/theme'
import { formattedCounterpartyForRule } from '@/utils/transferRuleUtils'
import { getStatementPageUrl, isAccountBroken } from '@/utils/financialAccountUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface TransferRuleAccountCellProps {
  rule: TransferRule
  context: 'from' | 'to'
}

export default function TransferRuleAccountCell ({ rule, context }: TransferRuleAccountCellProps): ReactElement {
  const navigate = useAltirNavigate()
  const account = context === 'from' ? rule.fromAccount : rule.toAccount

  const name = formattedCounterpartyForRule(account)
  const statementPageUrl = getStatementPageUrl(account)
  const isAccountUnhealthy = isAccountBroken(account)
  const isRuleActive = rule.isActive

  return (
    <Flex flexDir='column'>
      <Text
        color={isRuleActive ? isAccountUnhealthy ? Color.ERROR_RED : Color.DARK_BLUE : Color.DARK_GREY}
        _hover={{ cursor: 'pointer', color: Color.BRIGHT_BLUE_HOVER }}
        onClick={(e) => { statementPageUrl != null && navigate(statementPageUrl, e) }}
        noOfLines={1}
      >
        {name}
      </Text>
      <TransferRuleAccountCellBalanceDescription rule={rule} account={account} context={context}/>
    </Flex>
  )
}
