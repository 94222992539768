import { gql } from '@apollo/client'
import { TRACKED_JOB_FRAGMENT } from '../TrackedJobFragment'

export const FINANCIAL_ACCOUNT_SHORT_FRAGMENT = gql`
    ${TRACKED_JOB_FRAGMENT}
    fragment FinancialAccountShortFragment on FinancialAccount {
        id
        accountId
        name
        lastFour
        business {
            name
        }
        liveBalance {
            availableBalance {
                amount
            }
        }
        status
        amplifyAccount {
            id
        }
        counterparty {
            treasuryPrimeId
            name
            nickname
            achAccountNumber
            counterpartyType
        }
        trackedJob {
            ...TrackedJobFragment
        }
    }
`
