import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { BorderRadius } from '@/theme/theme'

interface TableEmptyStateProps {
  heading?: string
  subheading?: string

  // Styles
  backgroundColor: string
  textColor: string
}

export default function TableEmptyState ({
  heading = 'No data.',
  subheading,
  backgroundColor,
  textColor
}: TableEmptyStateProps): ReactElement {
  return (
    <Center
      w='100%'
      bg={backgroundColor}
      gap={1}
      px={6}
      py={16}
      borderBottomRadius={BorderRadius.CARD}
      flexDir='column'
    >
      <Heading color={textColor} size='md'>{heading}</Heading>
      {subheading != null && <Text>{subheading}</Text>}
    </Center>
  )
}
