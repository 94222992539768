import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Text } from '@chakra-ui/react'
import Table, { type TableHeader, type TableData } from '@/library/table/Table'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import TransferStatusBadge from '@/library/badge/TransferStatusBadge'
import { getFormattedTransferType } from '@/utils/transferUtils'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { type GetTransferHistory, type GetTransferHistoryVariables } from '@/graphql/__generated__/GetTransferHistory'
import { GET_TRANSFER_HISTORY } from '@/graphql/queries/GetTransferHistory'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { getFromAndToAccountsForOriginatingTransfer } from '@/components/pages/transactions/components/utils'
import { Color } from '@/theme/theme'

interface TransferTableData extends TableData {
  amount: { value: string }
  date: { value: string }
  transferStatus: { value: ReactElement | undefined }
  transferAccounts: { value: ReactElement | undefined, sortValue: string }
  method: { value: string | undefined }
}

interface TransferTableComponentProps {
  limit?: number
}

const TRANSFER_TABLE_METADATA: Array<TableHeader<TransferTableData>> = [
  { header: 'Date', key: 'date' },
  { header: 'Method', key: 'method' },
  { header: 'From -> To', key: 'transferAccounts', isReactElement: true },
  { header: 'Status', key: 'transferStatus', isReactElement: true },
  { header: 'Amount', key: 'amount', alignment: 'end' }
]

export default function TransferTableComponent ({ limit }: TransferTableComponentProps): ReactElement {
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const {
    loading,
    data,
    error
  } = useQuery<GetTransferHistory, GetTransferHistoryVariables>(GET_TRANSFER_HISTORY, {
    variables: {
      franchiseGroupId,
      limit
    }
  })
  const formattedTransfers: TransferTableData[] = (data?.transfers ?? []).map(transfer => {
    const { toAccount, fromAccount } = getFromAndToAccountsForOriginatingTransfer(transfer)
    return {
      amount: { value: getCurrencyFormatted(transfer.amount.amount) },
      date: { value: getFormattedDateString(transfer.createdAt, DateTimeTemplate.MM_DD_YYYY) ?? '' },
      transferStatus: {
        value: (
          <TransferStatusBadge
            status={transfer.status ?? undefined}
            expectedSettlementDate={transfer.estimatedSettlementDate}
          />
        )
      },
      transferAccounts: {
        value: (
          <Text color={Color.DARK_BLUE}>
            {fromAccount?.nameLine1 ?? ''}
            <Text as='span'>{' -> '}</Text>
            {toAccount?.nameLine1 ?? ''}
          </Text>
        ),
        sortValue: `${fromAccount?.nameLine1 ?? ''}`
      },
      method: { value: transfer.type != null ? getFormattedTransferType(transfer.type) : undefined }
    }
  })
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Table
        metadata={TRANSFER_TABLE_METADATA}
        data={formattedTransfers}
        templateColumns='1fr 1fr 3fr 1fr 1fr'
      />
    </AltirSkeleton>
  )
}
