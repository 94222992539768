import { gql } from '@apollo/client'
import {
  AMPLIFY_ACCOUNT_APPLICATION_FRAGMENT
} from '../fragments/amplify_account_application/AmplifyAccountApplicationFragment'
import { AMPLIFY_ACCOUNT_OWNERSHIP_INFO_FRAGMENT } from '../fragments/AmplifyAccountOwnershipInfoFragment'

export const GET_AMPLIFY_OWNER_DATA = gql`
  ${AMPLIFY_ACCOUNT_APPLICATION_FRAGMENT}
  ${AMPLIFY_ACCOUNT_OWNERSHIP_INFO_FRAGMENT}
  query GetAmplifyOwnerData($franchiseGroupId: Int!) {
    currentUser {
      franchiseGroup(id: $franchiseGroupId) {
        amplifyAccounts {
          amplifyAccount {
            ...AmplifyAccountOwnershipInfoFragment
          }
        }
        amplifyAccountApplication {
          ...AmplifyAccountApplicationFragment
        }
      }
    }
  }
`
