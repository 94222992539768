import React, { type ReactElement } from 'react'
import FinancialAccountRow from './FinancialAccountRow'
import DropDownComponent from '../layout/drop_down/DropDownComponent'
import MockDropDownComponent from '../layout/drop_down/MockDropDownComponent'
import { DropDownVariant } from '../layout/drop_down/types'
import {
  type FinancialAccountExtendedFragment as FinancialAccount
} from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import TreasuryAccountDropdownContent
  from '@/components/pages/treasury/components/treasury/treasury_row_dropdown/TreasuryAccountDropdownContent'
import { AccountLinkStatus, AmplifyAccountStatus, LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import { ERROR_BORDER } from '@/utils/errorUtils'
import { ROUTES } from '@/api/routes'
import { useTrackFinancialAccountJob } from '@/hooks/useTrackFinancialAccountJob'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface FinancialAccountDropDownComponentProps {
  account: FinancialAccount
  isTransactionRefreshEnabled: boolean
  refetch: () => void
}

export default function FinancialAccountDropDownComponent ({
  account,
  isTransactionRefreshEnabled,
  refetch
}: FinancialAccountDropDownComponentProps): ReactElement {
  const navigate = useAltirNavigate()

  const { isTracking } = useTrackFinancialAccountJob({
    accountId: account.accountId ?? 0, // this should never be null
    trackedJobId: account.trackedJob?.id
  })

  function onCardClick (e: React.MouseEvent): void {
    navigate(`${ROUTES.CARDS}/${String(account.accountId)}`, e)
  }

  const isBank = account.accountType === LinkedAccountType.BANK
  const borderStyle = account.status === AccountLinkStatus.LOGIN_REQUIRED ? ERROR_BORDER : undefined
  const isAccountClosed = account.amplifyAccount?.status === AmplifyAccountStatus.CLOSED
  const dropdownVariant = isAccountClosed ? DropDownVariant.DARK : DropDownVariant.DEFAULT

  const header = (
    <FinancialAccountRow
      account={account}
      hasPendingRefreshJob={isTracking}
      isTransactionRefreshEnabled={isTransactionRefreshEnabled}
    />
  )

  // For now, only enable DropDowns for bank accounts, not cards
  return isBank
    ? <DropDownComponent
        borderStyle={borderStyle}
        variant={dropdownVariant}
        customHeader={header}
        dropDownContent={
          <TreasuryAccountDropdownContent
            account={account}
            isTransactionRefreshEnabled={isTransactionRefreshEnabled}
            hasPendingRefreshJob={isTracking}
            refetch={refetch}
          />
        }
      />
    : <MockDropDownComponent
        borderStyle={borderStyle}
        customHeader={header}
        variant={dropdownVariant}
        onClick={onCardClick}
      />
}
