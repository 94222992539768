import React, { useState, type ReactElement } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Flex, Heading, MenuItem, Text, useToast } from '@chakra-ui/react'
import { ModalComponent } from './ModalComponent'
import PopoverMenu from '../popoverMenu/components/PopoverMenu'
import PopoverMenuSingleOptionSelect from '../popoverMenu/PopoverMenuSingleOptionSelect'
import Button, { ButtonVariant } from '../button/Button'
import CarrotComponent from '../utility/toggle/CarrotComponent'
import { BorderRadius, Color, FormInputHeight, IconSize } from '@/theme/theme'
import {
  type GetAllStoreLocationsVariables,
  type GetAllStoreLocations, type GetAllStoreLocations_currentUser_franchiseGroup_storeLocations as StoreLocation
} from '@/graphql/__generated__/GetAllStoreLocations'
import { GET_ALL_STORE_LOCATIONS } from '@/graphql/queries/GetAllStoreLocations'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'
import { REMOVE_DEFAULT_STORE_LOCATION } from '@/graphql/mutations/RemoveDefaultStoreLocation'
import { SET_DEFAULT_STORE_LOCATION } from '@/graphql/mutations/SetDefaultStoreLocation'
import {
  type SetDefaultStoreLocation,
  type SetDefaultStoreLocationVariables
} from '@/graphql/__generated__/SetDefaultStoreLocation'
import {
  type GetTreasuryPageData_currentUser_selectedOrganization_selectedFranchiseGroup_financialAccounts as FinancialAccount
} from '@/graphql/__generated__/GetTreasuryPageData'
import { ROUTES } from '@/api/routes'
import {
  type RemoveDefaultStoreLocation,
  type RemoveDefaultStoreLocationVariables
} from '@/graphql/__generated__/RemoveDefaultStoreLocation'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { IconFacing } from '@/types/types'
import useAltirNavigate from '@/hooks/useAltirNavigate'

const SELECTED_STORE_LOCATION_EXPLANATION =
  'All transactions in this bank account will be tagged with the following location'

interface DefaultStoreLocationModalProps {
  isOpen: boolean
  onClose: () => void
  financialAccount?: FinancialAccount
  refetch: () => void
}

export default function DefaultStoreLocationModal ({
  isOpen,
  onClose,
  financialAccount,
  refetch
}: DefaultStoreLocationModalProps): ReactElement {
  const toast = useToast()
  const [selectedStoreLocationId, setSelectedStoreLocationId] = useState<string | undefined>(
    financialAccount?.defaultStoreLocation?.id
  )
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const { loading: storeLocationsLoading, data: storeLocationsData, error: storeLocationsQueryError } =
    useQuery<GetAllStoreLocations, GetAllStoreLocationsVariables>(GET_ALL_STORE_LOCATIONS, {
      variables: { franchiseGroupId: selectedFranchiseGroupId }
    })

  const [updateDefaultStoreLocation] = useMutation<SetDefaultStoreLocation, SetDefaultStoreLocationVariables>(
    SET_DEFAULT_STORE_LOCATION, {
      onCompleted: onUpdateSuccess,
      onError: onUpdateError
    })

  const [removeDefaultStoreLocation] = useMutation<RemoveDefaultStoreLocation, RemoveDefaultStoreLocationVariables>(
    REMOVE_DEFAULT_STORE_LOCATION, {
      onCompleted: onRemoveSuccess,
      onError: onRemoveError
    })

  const navigate = useAltirNavigate()

  function getStoreLocationById (storeLocationId?: string): StoreLocation | undefined {
    return storeLocations.find((storeLocation) => storeLocation.id === storeLocationId)
  }

  async function handleSubmit (): Promise<void> {
    if (financialAccount?.accountId == null) {
      onUpdateError()
      return
    }
    if (selectedStoreLocationId == null) {
      await removeDefaultStoreLocation({
        variables: {
          bankAccountId: financialAccount.accountId
        }
      })
      return
    }
    await updateDefaultStoreLocation({
      variables: {
        bankAccountId: financialAccount?.accountId,
        storeLocationId: selectedStoreLocationId
      }
    })
  }

  function onRemoveSuccess (): void {
    refetch()
    toast(getSuccessToast('Default store location removed'))
    onClose()
  }

  function onRemoveError (): void {
    toast(getErrorToast('Unable to remove default store location'))
  }

  function onUpdateSuccess (): void {
    refetch()
    toast(getSuccessToast('Default store location set'))
    onClose()
  }
  function onUpdateError (): void {
    toast(getErrorToast('Unable to set default store location'))
  }

  const storeLocations = storeLocationsData?.currentUser?.franchiseGroup?.storeLocations ?? []
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex flexDir='column' w='100%' alignItems='center' textAlign='center' gap={6} mb={4} >
        <Heading color={Color.DARK_BLUE} size='lg'>Edit Default Store Location</Heading>
        <Heading color={Color.DARK_GREY} size='sm'>{financialAccount?.name}</Heading>
        <Text>{SELECTED_STORE_LOCATION_EXPLANATION}</Text>
        <PopoverMenu
          popoverMenuTitle={getStoreLocationById(selectedStoreLocationId)?.name ?? 'Select a Store Location'}
          onSubmit={() => {}}
          borderRadius={BorderRadius.CARD}
          border='0px'
          menuButtonHeight={FormInputHeight.DEFAULT}
          shouldRotateMenuIcon={true}
          isLoading={storeLocationsLoading}
          menuLoadingError={storeLocationsQueryError}
          maxMenuHeight='40vh'
          shouldMatchWidth={true}
          allowInteriorScroll
        >
          <PopoverMenuSingleOptionSelect
            options={storeLocations}
            setSelectedOptionOrUndefined={(storeLocation) => { setSelectedStoreLocationId(storeLocation?.id) }}
            selectedOption={getStoreLocationById(selectedStoreLocationId)}
            formattingFunction={(storeLocation) => ({
              title: storeLocation.name ?? 'Unnamed Store Location',
              subtitle: storeLocation.storeId ?? ''
            })}
            noOfLines={1}
            clearSelectionTitle='Remove Default Store Location'
            callToActionElement={
              <MenuItem
                closeOnSelect={true}
                onClick={(e) => {
                  navigate(ROUTES.SETTINGS,
                    e, {
                      state: {
                        isStoreLocationSelected: true,
                        isBusinessSettingPreSelected: true
                      }
                    }
                  )
                }}
              >
                <Flex flexDir='row' align='center' justify='space-between' w='100%'>
                  <Text color={Color.DARK_BLUE}>Add a Store Location</Text>
                  <CarrotComponent facing={IconFacing.RIGHT} color={Color.DARK_BLUE} size={IconSize.SMALL}/>
                </Flex>
              </MenuItem>
             }
          />
        </PopoverMenu>
        <Button
          text='Continue'
          variant={ButtonVariant.PRIMARY}
          onClick={handleSubmit}
        />
        <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={onClose}/>
      </Flex>
    </ModalComponent>
  )
}
