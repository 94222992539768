import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { GET_RECIPIENT_TRANSFER_RULES } from '@/graphql/queries/recipients/GetRecipientTransferRules'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import {
  type GetRecipientTransferRules,
  type GetRecipientTransferRulesVariables
} from '@/graphql/__generated__/GetRecipientTransferRules'
import TransferRulesCountDropDown from '@/library/transfer_rules/TransferRulesCountDropDown'

interface RecipientTransferRulesWidgetProps {
  counterpartyId: string
}

export default function RecipientTransferRulesWidget ({
  counterpartyId
}: RecipientTransferRulesWidgetProps): ReactElement {
  const { data, loading, error } = useQuery<GetRecipientTransferRules, GetRecipientTransferRulesVariables>(
    GET_RECIPIENT_TRANSFER_RULES,
    { variables: { counterpartyId } }
  )

  const numberOfRules = (data?.counterparty.transferRules ?? []).length

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <TransferRulesCountDropDown count={numberOfRules}/>
    </AltirSkeleton>
  )
}
