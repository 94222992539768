import { gql } from '@apollo/client'

export const GET_BRAND_METADATA = gql`
    query GetBrandMetadata($franchiseGroupId: Int!) {
        franchiseGroup(franchiseGroupId: $franchiseGroupId) {
            brand {
                id
                name
                logoAssetUrl
            }
        }
    }
`
