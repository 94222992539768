import React, { type ReactElement } from 'react'
import { Center, Flex, Heading, Text } from '@chakra-ui/react'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import MoreInfoTooltip from '@/library/utility/MoreInfoTooltip'
import { DateTimeTemplate, dateTimeToDateTimeTemplate } from '@/utils/dateUtils'
import { calculateSettleETA, getTransferTooltipMessage } from '@/utils/transferUtils'
import { TransferType } from '@/graphql/__generated__/globalTypes'

interface TransferEtaComponentProps {
  transferType: TransferType
}

export default function TransferEtaComponent ({ transferType }: TransferEtaComponentProps): ReactElement {
  // Book transfers settle instantly
  if (transferType === TransferType.BOOK) {
    return <></>
  }

  return (
    <Center
      width='100%'
      borderRadius={BorderRadius.CARD}
      backgroundColor={Color.LIGHT_GREY}
      p={4}
    >
      <Center flexDirection='column' gap={2}>
        <Flex gap={2}>
          <Text color={Color.DARK_GREY}> Transfer ETA </Text>
          <MoreInfoTooltip iconSize={IconSize.SMALL} tooltipText={getTransferTooltipMessage(transferType)}/>
        </Flex>
        <Heading size='sm'>
          {dateTimeToDateTimeTemplate(calculateSettleETA(transferType), DateTimeTemplate.MM_DD_YY_SLASH)}
        </Heading>
      </Center>
    </Center>
  )
}
