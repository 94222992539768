import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import CancelCheckModal from './CancelCheckModal'
import StopPaymentRequestModal from './StopPaymentRequestModal'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import {
  type TransferFragment as Transfer
} from '@/graphql/__generated__/TransferFragment'
import CloseIcon from '@/library/icons/CloseIcon'
import { IconSize } from '@/theme/theme'
import { TransferCancellationType } from '@/graphql/__generated__/globalTypes'

interface CheckManagementComponentProps {
  check: Transfer
}
const VALID_CHECK_CANCELLATION_TYPES: TransferCancellationType[] = [
  TransferCancellationType.CANCELED,
  TransferCancellationType.STOP_PAYMENT
]

export default function CheckManagementComponent ({ check }: CheckManagementComponentProps): ReactElement {
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false)
  const [isRequestStopPaymentModalOpen, setIsRequestStopPaymentModalOpen] = useState(false)

  function onButtonClick (): void {
    check.eligibleCancellationType === TransferCancellationType.CANCELED
      ? setIsCancellationModalOpen(true)
      : setIsRequestStopPaymentModalOpen(true)
  }

  if (!VALID_CHECK_CANCELLATION_TYPES.includes(check.eligibleCancellationType)) {
    return <></>
  }

  return (
    <Flex>
      <Button
        text={
            check.eligibleCancellationType === TransferCancellationType.CANCELED
              ? 'Cancel Check'
              : 'Request Stop Payment'
        }
        variant={ButtonVariant.DESTRUCTIVE_TRANSPARENT}
        onClick={onButtonClick}
        width='auto'
        size={ButtonSize.SMALL}
        beforeIcon={<CloseIcon size={IconSize.SMALL}/>}
      />
      <CancelCheckModal
        isOpen={isCancellationModalOpen}
        onClose={() => { setIsCancellationModalOpen(false) }}
        check={check}
      />
      <StopPaymentRequestModal
        isOpen={isRequestStopPaymentModalOpen}
        onClose={() => { setIsRequestStopPaymentModalOpen(false) }}
        check={check}
      />
    </Flex>
  )
}
