import React, { type ReactElement } from 'react'
import '../../../assets/css/App.css'
import { Center, Flex } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import OrganizationSection from './components/OrganizationSection'
import NextStepsSection from './components/NextStepsSection'
import QuickActionsSection from './components/QuickActionsSection'
import BusinessSection from './components/BusinessSection'
import AggregateCashSection from './components/AggregateCashSection'
import AmplifyAccountSection from './components/AmplifyAccountSection'
import PendingTransfersComponent from './components/pending_transfers/PendingTransfersComponent'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import Page from '@/library/page/Page'
import { GET_HOME_PAGE_DATA } from '@/graphql/queries/GetHomePageData'
import { type GetHomePageData, type GetHomePageDataVariables } from '@/graphql/__generated__/GetHomePageData'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { LinkedAccountType } from '@/graphql/__generated__/globalTypes'

export default function HomePage (): ReactElement<string, string> {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const { data, loading: isDataLoading, error: dataError } = useQuery<GetHomePageData, GetHomePageDataVariables>(
    GET_HOME_PAGE_DATA,
    {
      variables: {
        organizationId: selectedOrganizationId,
        financialAccountFilter: { accountType: LinkedAccountType.BANK }
      }
    }
  )

  return (
    <Page>
      <AltirSkeleton isLoading={isDataLoading} error={dataError}>
        <Center flexDir='column' w='100%' gap={6}>
          <Flex flexDir='row' gap={6} w='100%'>
            <Flex flex={3} flexDir='column' gap={6}>
              <OrganizationSection data={data}/>
              <Flex flexDir='row' gap={6}>
                <Flex flex={2}>
                  <BusinessSection data={data}/>
                </Flex>
                <Flex flex={1} flexDir='column' gap={6}>
                  <AggregateCashSection />
                  <AmplifyAccountSection/>
                </Flex>
              </Flex>
            </Flex>
            <Flex flex={1} flexDir='column' gap={6}>
              <NextStepsSection/>
              <QuickActionsSection data={data}/>
              <PendingTransfersComponent/>
            </Flex>
          </Flex>
        </Center>
      </AltirSkeleton>
    </Page>
  )
}
