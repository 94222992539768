import {
  useNavigate,
  type To,
  type NavigateOptions
} from 'react-router-dom'

type EnhancedNavigateFunction = (
  to: To,
  e: React.MouseEvent | MouseEvent | null,
  options?: NavigateOptions,
) => void

/**
 * A thin wrapper on react-router-dom's useNavigate that allows us to support cmd+click opening
 * links in a new tab
 */
export default function useAltirNavigate (): EnhancedNavigateFunction {
  const navigate = useNavigate()

  function altirNavigate (
    to: To,
    e: React.MouseEvent | MouseEvent | null,
    options?: NavigateOptions
  ): void {
    if ((e != null) && (e.metaKey || e.ctrlKey)) {
      const url = typeof to === 'string' ? to : to.pathname ?? '/'
      window.open(url, '_blank')
    } else {
      navigate(to, options)
    }
  }

  return altirNavigate
}
