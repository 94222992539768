import { Center, Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import { useQuery } from '@apollo/client'
import AccountDocumentsComponent from './documents/AccountDocumentsComponent'
import AccountTransactionsComponent from './AccountTransactionsComponent'
import AccountRefreshButton from './account_refresh/AccountRefreshButton'
import ReportIncorrectDataComponent from './incorrect_data_report/ReportIncorrectDataComponent'
import { ROUTES } from '@/api/routes'
import FinancialAccountDetailComponent from '@/library/accounts/FinancialAccountDetailComponent'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { ReLinkErrorBanner } from '@/library/errors/ReLinkErrorBanner'
import TransferIcon from '@/library/icons/TransferIcon'
import ListIcon from '@/library/icons/ListIcon'
import { IconSize } from '@/theme/theme'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { GET_FINANCIAL_ACCOUNT } from '@/graphql/queries/accounts/GetFinancialAccount'
import {
  type GetFinancialAccount,
  type GetFinancialAccountVariables
} from '@/graphql/__generated__/GetFinancialAccount'
import FinancialAccountBalanceDisplayComponent from '@/library/accounts/FinancialAcountBalanceDisplayComponent'
import { getTimeSinceLastUpdate, isAccountBroken } from '@/utils/financialAccountUtils'
import { useTrackFinancialAccountJob } from '@/hooks/useTrackFinancialAccountJob'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface TreasuryDetailComponentProps {
  accountId: number
}

export default function AccountStatementComponent (
  {
    accountId
  }: TreasuryDetailComponentProps
): ReactElement<string, string> {
  const navigate = useAltirNavigate()
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery<GetFinancialAccount, GetFinancialAccountVariables>(
    GET_FINANCIAL_ACCOUNT,
    { variables: { accountId } }
  )

  const account = data?.financialAccount
  const isAmplifyAccount = account?.amplifyAccount?.id != null
  const needsPlaidReLink = account != null && isAccountBroken(account)
  // TODO PAL: Fix queries to ensure the reLink banner can directly fix link
  const plaidAccessToken = account?.plaidAccessToken ?? undefined
  const institutionName = account?.institution?.name ?? undefined

  // Balances
  const availableBalance = account?.liveBalance?.availableBalance?.amount ?? null
  const currentBalance = account?.liveBalance?.currentBalance?.amount ?? null

  const { isTracking } = useTrackFinancialAccountJob({
    accountId,
    trackedJobId: account?.trackedJob?.id
  })

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={6} w='100%'>
        {needsPlaidReLink &&
          <ReLinkErrorBanner
            franchiseGroupId={franchiseGroupId}
            plaidAccessToken={plaidAccessToken}
            refetchData={refetch}
            institutionName={institutionName}
          />
        }
        <Flex
          width='100%'
          flexDirection='row'
          gap={6}
        >
          <Flex
            flexDirection='column'
            flex={1}
            gap={6}
          >
            <FinancialAccountBalanceDisplayComponent
              availableBalance={availableBalance}
              currentBalance={currentBalance}
            />
            <FinancialAccountDetailComponent accountId={accountId}/>
            {isAmplifyAccount && <AccountDocumentsComponent accountId={accountId}/>}
            <Flex flexDir='column' gap={4}>
              <Button
                text='Transfer'
                onClick={(e) => { navigate(ROUTES.TRANSFER, e) }}
                beforeIcon={<TransferIcon/>}
                size={ButtonSize.MEDIUM}
                onClickEventType={Event.OPEN_TRANSFER_PAGE_CLICK}
              />
              <Button
                text='Transfer History'
                onClick={(e) => { navigate(ROUTES.TRANSFER_HISTORY, e) }}
                beforeIcon={<ListIcon size={IconSize.SMALL}/>}
                variant={ButtonVariant.WHITE_OUTLINE}
                onClickEventType={Event.VIEW_ALL_TRANSFERS_CLICK}
              />
              {account != null && <ReportIncorrectDataComponent account={account}/>}
            </Flex>
          </Flex>
          <Flex
            flexDirection='column'
            flex={2}
            alignItems='start'
            gap={3}
            w='100%'
          >
            <Flex flexDir='row' gap={1} alignItems='center' justifyContent='space-between' w='100%'>
              <Text>Transaction History</Text>
              {
                account?.plaidItemId != null &&
                  <Flex alignItems='center' gap={2}>
                    <Text fontSize='sm'>
                      Last Updated: {getTimeSinceLastUpdate(account)}
                    </Text>
                    <AccountRefreshButton
                      hasPendingRefreshJob={isTracking}
                      plaidItemId={account?.plaidItemId}
                      buttonSize={ButtonSize.X_SMALL}
                      hideText={true}
                    />
                  </Flex>
              }
            </Flex>
            <AccountTransactionsComponent accountId={accountId}/>
          </Flex>
        </Flex>
      </Center>
    </AltirSkeleton>
  )
}
