import React, { type ReactElement } from 'react'
import {
  Flex,
  Text
} from '@chakra-ui/react'
import TransferLimitInfoPopover from './TransferLimitInfoPopover'
import FormDollarInput from '@/library/form/number/FormDollarInput'
import { Color } from '@/theme/theme'
import { NumberInputSizeVariant } from '@/library/form/number/FormNumberInput'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

interface TransferAmountFieldProps {
  amount?: string
  setAmount: (amount: string) => void
  amplifyAccounts: FinancialAccountExtendedFragment[]
}

export default function TransferAmountField ({
  amount,
  amplifyAccounts,
  setAmount
}: TransferAmountFieldProps): ReactElement {
  return (
    <Flex w='100%'>
      <FormDollarInput
        fieldName='amount'
        value={amount ?? ''}
        label='Amount'
        onChange={setAmount}
        placeholder='100'
        fieldInteriorLabel={<Text color={Color.DARK_BLUE}>$</Text>}
        backgroundColor={Color.WHITE}
        variant={NumberInputSizeVariant.HERO}
        secondaryLabelElement={<TransferLimitInfoPopover amplifyAccounts={amplifyAccounts}/>}
      />
    </Flex>
  )
}
