import { gql } from '@apollo/client'

export const GET_COUNTERPARTIES_PENDING_VERIFICATION = gql`
    query GetCounterpartiesPendingVerification(
        $counterpartyTypes: [CounterpartyType!], 
        $isOwnershipVerified: Boolean,
        $franchiseGroupId: Int!
    ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                amplifyAccounts {
                    amplifyAccount {
                        id
                    }
                }
                counterparties(counterpartyTypes: $counterpartyTypes, isOwnershipVerified: $isOwnershipVerified) {
                    treasuryPrimeId
                    isOwnershipVerified
                    nameOnAccount
                    name
                }
            }
        }
    }
`
