import React, { type ReactElement, useState } from 'react'
import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { AutomatedRuleTreasuryDropdownCardTextRow } from './AutomatedRuleTreasuryDropdownCardTextRow'
import { Color, IconSize } from '@/theme/theme'
import { getFormattedBalanceRule, getFormattedTimeRule, isBalanceRule } from '@/utils/transferRuleUtils'
import RecurringRuleBalanceIcon from '@/library/icons/RecurringRuleBalanceIcon'
import RecurringRuleTimeIcon from '@/library/icons/RecurringRuleTimeIcon'
import MenuIcon from '@/library/icons/MenuIcon'
import { ROUTES } from '@/api/routes'
import TransferRuleActivationModal, { ActivationDirection }
  from '@/components/pages/transfer_rules/components/TransferRuleActivationModal'
import {
  type GetTransferRule,
  type GetTransferRuleVariables,
  type GetTransferRule_transferRule as TransferRule
} from '@/graphql/__generated__/GetTransferRule'
import { GET_TRANSFER_RULE } from '@/graphql/queries/transfer_rules/GetTransferRule'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface AutomatedRuleTreasuryDropdownCardProps {
  transferRuleId: string
  onDelete: () => void
}
export default function AutomatedRuleTreasuryDropdownCard (
  { transferRuleId, onDelete }: AutomatedRuleTreasuryDropdownCardProps
): ReactElement {
  const navigate = useAltirNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data, loading, error } = useQuery<GetTransferRule, GetTransferRuleVariables>(GET_TRANSFER_RULE, {
    variables: { id: transferRuleId }
  })

  function balanceDescription (transferRule: TransferRule): ReactElement {
    const formattedRule = getFormattedBalanceRule(transferRule)

    return <>
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Balance Threshold'
        value={formattedRule.balance}
        isLargeText={true}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='To'
        value={formattedRule.to}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Description'
        value={formattedRule.sentenceDescription}
        shouldIncludeDivider={false}
        flexHeader={5}
        flexValue={5}
      />
    </>
  }

  function timeDescription (transferRule: TransferRule): ReactElement {
    const formattedRule = getFormattedTimeRule(transferRule)

    return <>
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Amount'
        value={formattedRule.amount}
        isLargeText={true}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='To'
        value={formattedRule.to}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='From'
        value={formattedRule.from}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Frequency'
        value={formattedRule.frequency}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Next Transfer'
        value={formattedRule.nextTransfer}
        shouldIncludeDivider={false}
      />
    </>
  }

  const menuButton = (
    <Flex>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          variant='ghost'
          icon={<MenuIcon color={Color.DARK_GREY} size={IconSize.SMALL} />}
        />
        <MenuList p ={2}>
          <MenuItem onClick={(e) => { navigate(ROUTES.AUTOMATED_TRANSFERS, e) }}>
            <Text>Manage Rules</Text>
          </MenuItem>
          <MenuItem onClick={() => { setIsModalOpen(true) }}>
            <Text>Deactivate</Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )

  const transferRule = data?.transferRule

  if (!loading && transferRule == null) {
    return <></>
  }

  const isRuleBalanceRule = transferRule != null && isBalanceRule(transferRule.transferRuleType)

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex flex={15} grow={1} gap={4}>
        <Flex direction='column' grow={1} gap={2}>
          <Flex justifyContent='space-between' alignItems='center'>
            <Text color={Color.DARK_BLUE}>Automated Transfer Rule</Text>
            <Flex justifyContent='end' gap={2} alignItems='center'>
              {
                isRuleBalanceRule
                  ? <RecurringRuleBalanceIcon color={Color.DARK_GREY}/>
                  : <RecurringRuleTimeIcon color={Color.DARK_GREY}/>
              }
              <Text whiteSpace='pre-line'>
                {isRuleBalanceRule ? 'Balance-Based' : 'Time-Based'}
              </Text>
              {menuButton}
            </Flex>
          </Flex>
          {
            transferRule != null
              ? isRuleBalanceRule
                ? balanceDescription(transferRule)
                : timeDescription(transferRule)
              : <>/</>}
        </Flex>
      </Flex>
      {
        transferRule != null &&
          <TransferRuleActivationModal
            transferRuleId={transferRule.id}
            isModalOpen={isModalOpen}
            onModalClose={() => { setIsModalOpen(false) }}
            onUpdate={() => { onDelete() }}
            activationDirection={ActivationDirection.Deactivate}
          />
      }
    </AltirSkeleton>
  )
}
