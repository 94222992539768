import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import TransactionsRowListComponent from '@/library/transactions/TransactionsRowListComponent'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import NoTransactionsFoundComponent from '@/components/pages/transactions/components/NoTransactionsFoundComponent'
import { useFetchAccountTransactions } from '@/hooks/data/useFetchAccountTransactions'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { IconSize } from '@/theme/theme'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface AmplifyRecentTransactionsComponentProps {
  accountId: number
}

export default function AmplifyRecentTransactionsComponent (
  {
    accountId
  }: AmplifyRecentTransactionsComponentProps
): ReactElement {
  const navigate = useAltirNavigate()
  const { data: transactions, loading, error } = useFetchAccountTransactions(accountId, {
    limit: 10,
    shouldIncludePending: true
  })

  return (
    <Flex flexDir='column' w='100%' gap={3}>
      <Flex w='100%' justifyContent='space-between' alignItems='center'>
        <Text>Recent Transactions</Text>
        <Button
          text='View All'
          size={ButtonSize.SMALL}
          variant={ButtonVariant.GHOST}
          afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
          onClick={(e) => { navigate(`${ROUTES.TREASURY}/${accountId ?? ''}`, e) }}
          width='flex'
        />
      </Flex>
      <AltirSkeleton isLoading={loading} error={error}>
        {transactions.length < 1
          ? <NoTransactionsFoundComponent/>
          : <TransactionsRowListComponent transactionsData={transactions}/>
        }
      </AltirSkeleton>
    </Flex>
  )
}
