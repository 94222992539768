import { Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { Color } from '@/theme/theme'

export interface MetricCardRowProps {
  label: string
  value?: string
  valueColor?: string
  valueElement?: ReactElement
  isHeaderRow?: boolean
}

export default function MetricCardRow (
  {
    label,
    value,
    valueColor = Color.DARK_BLUE,
    isHeaderRow = false,
    valueElement
  }: MetricCardRowProps
): ReactElement {
  return (
    <Flex
      justifyContent='space-between'
      w='100%'
    >
      <Text
        color={Color.DARK_GREY}
      >
        {label}
      </Text>
      {
        valueElement != null
          ? valueElement
          : <Text
              textAlign='right'
              color={valueColor}
              fontSize={isHeaderRow ? '2xl' : 'md'}
            >
            {value}
          </Text>
      }
    </Flex>
  )
}
