import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { getCTAText } from '../utils'
import {
  type GetCapitalOSPageData_currentUser_selectedOrganization_franchiseGroups as Business
} from '@/graphql/__generated__/GetCapitalOSPageData'
import { CapitalOSAccountStatus } from '@/graphql/__generated__/globalTypes'
import { Color, IconSize } from '@/theme/theme'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { RouteName } from '@/api/routes'
import Button, { ButtonSize } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface CapitalOSBusinessInfoComponentProps {
  business: Business
}

export default function CapitalOSBusinessInfoComponent (
  { business }: CapitalOSBusinessInfoComponentProps
): ReactElement {
  const navigate = useAltirNavigate()
  const link = `/${RouteName.CAPTIAL_OS_CARDS}/${business.id}`
  const account = business.capitalOSConfiguration?.account
  if (account?.status === CapitalOSAccountStatus.APPROVED) {
    return (
      <Flex flexDir='column' gap={1} textAlign='end'>
        <Text color={Color.DARK_BLUE}>
          <Text as='span' color={Color.DARK_GREY}>Balance: </Text>
          {getCurrencyFormatted(account.currentBalance?.amount, { minimumFractionDigits: 2 })}
        </Text>
      </Flex>
    )
  }
  return (
    <Flex>
      <Button
        text={getCTAText(account?.status)}
        onClick={(e) => { navigate(link, e) }}
        afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
        size={ButtonSize.SMALL}
      />
    </Flex>
  )
}
