import React, { type ReactElement } from 'react'
import RecipientDropDownContent from './RecipientDropDownContent'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import { Color, IconSize } from '@/theme/theme'
import ClockIcon from '@/library/icons/ClockIcon'
import { type TransferFragment } from '@/graphql/__generated__/TransferFragment'

export interface Recipient {
  treasuryPrimeId: string
  name: string
  isVerified: boolean
  transfers: TransferFragment[]
}

interface RecipientDropDownProps {
  recipient: Recipient
}

export default function RecipientDropDown ({ recipient }: RecipientDropDownProps): ReactElement {
  const icon = recipient.isVerified
    ? <CircleCheckIcon color={Color.DARK_BLUE} size={IconSize.SCHMEDIUM}/>
    : <ClockIcon color={Color.DARK_GREY} size={IconSize.SCHMEDIUM}/>
  return (
    <DropDownComponent
      title={recipient.name}
      icon={icon}
      dropDownContent={<RecipientDropDownContent recipient={recipient}/>}
    />
  )
}
