import { gql } from '@apollo/client'

export const GET_TRANSFER_CONTEXT = gql`
    query GetTransferContext($organizationId: String) {
        currentUser {
            id
            selectedOrganization(organizationId: $organizationId) {
                id
                franchiseGroups {
                    id
                    amplifyAccounts {
                        accountId
                    }
                }
            }
        }
    }
`
