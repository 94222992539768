import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { type GetTransferRule_transferRule as TransferRule } from '@/graphql/__generated__/GetTransferRule'
import { getFormattedTimeRule } from '@/utils/transferRuleUtils'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'
import RecurringRuleTimeIcon from '@/library/icons/RecurringRuleTimeIcon'
import { Color, IconSize } from '@/theme/theme'
import { getFormattedDateString } from '@/utils/dateUtils'

interface AutomatedTransferRuleTimeMetadataProps {
  rule: TransferRule
}

export default function AutomatedTransferRuleTimeMetadata ({
  rule
}: AutomatedTransferRuleTimeMetadataProps): ReactElement {
  const { amount, frequency, nextTransfer } = getFormattedTimeRule(rule)
  const createdAt = getFormattedDateString(rule.createdAt)
  return (
    <Flex flexDir='column' w='100%' gap={4}>
      <StackedInfoAttribute
        header='Type'
        subElement={
          <Flex alignItems='center' gap={1}>
            <RecurringRuleTimeIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>
            <Text>Balance-Based</Text>
          </Flex>
        }
      />
      <StackedInfoAttribute header='Amount' subElement={<Heading size='md'>{amount}</Heading>}/>
      <StackedInfoAttribute header='Created' subtext={createdAt ?? ''}/>
      <StackedInfoAttribute header='Frequency' subtext={frequency}/>
      <StackedInfoAttribute header='Next Transfer' subtext={nextTransfer}/>
    </Flex>
  )
}
