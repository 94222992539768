import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import StackedInfoAttribute from '../../../../library/utility/StackedInfoAttribute'
import { getFormattedTimeRule } from '@/utils/transferRuleUtils'
import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { TransferRuleType } from '@/graphql/__generated__/globalTypes'

export interface TimeRuleDropdownComponentProps {
  transferRule: TransferRule
}

export default function TimeRuleDropdownComponent (
  { transferRule }: TimeRuleDropdownComponentProps
): ReactElement {
  const formattedRule = getFormattedTimeRule(transferRule)
  const transferRuleType = transferRule.transferRuleType

  function getNextTransfer (): ReactElement {
    return (
      <StackedInfoAttribute
        header={transferRuleType === TransferRuleType.ONE_TIME ? 'Date of Transfer' : 'Next Transfer'}
        subtext={transferRuleType === TransferRuleType.ONE_TIME
          ? formattedRule.ruleStartDate ?? ''
          : formattedRule.nextTransfer}
      />
    )
  }

  function getSubFrequency (): string {
    if (transferRuleType === TransferRuleType.WEEKLY) {
      return formattedRule.dayOfWeek != null ? `on ${formattedRule.dayOfWeek}` : ''
    } else if (transferRuleType === TransferRuleType.MONTHLY) {
      return formattedRule.dateOfMonth != null ? `on the ${formattedRule.dateOfMonth}` : ''
    }
    return ''
  }

  return (
    <Flex flexDir='column' gap={4} w='100%'>
      <Flex direction='column' gap={5} flex={1}>
        <StackedInfoAttribute header='Amount' subtext={formattedRule.amount}/>
        <StackedInfoAttribute header='Frequency' subtext={`${formattedRule.frequency} ${getSubFrequency()}`}/>
        {getNextTransfer()}
      </Flex>
    </Flex>
  )
}
