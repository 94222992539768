import { gql } from '@apollo/client'

export const GET_RECENT_RECIPIENTS = gql`
    query GetRecentRecipients(
        $counterpartyTypes: [CounterpartyType!], 
        $franchiseGroupId: Int!,
    ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                counterparties(counterpartyTypes: $counterpartyTypes) {
                    treasuryPrimeId
                    isOwnershipVerified
                    nameOnAccount
                    name
                }
            }
        }
    }
`
