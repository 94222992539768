import React, { type ReactElement } from 'react'
import FranchiseeNameCell from './FranchiseeNameCell'
import Table, { type TableData, type TableHeader } from '@/library/table/Table'
import {
  type GetBrandFranchisees_franchiseGroup_brand_franchisees as Franchisee
} from '@/graphql/__generated__/GetBrandFranchisees'

export interface FranchiseeTableData extends TableData {
  name: { value: ReactElement, sortValue: string }
}

export const FRANCHISEE_TABLE_METADATA: Array<TableHeader<FranchiseeTableData>> = [
  { header: 'Name', key: 'name', isReactElement: true }
]

interface FranchiseeTableProps {
  franchisees: Franchisee[]
}

export default function FranchiseeTable ({ franchisees }: FranchiseeTableProps): ReactElement {
  const data = franchisees.map(franchisee => franchiseeTableRow(franchisee))
  return (
    <Table metadata={FRANCHISEE_TABLE_METADATA} data={data}/>
  )
}

function franchiseeTableRow (franchisee: Franchisee): FranchiseeTableData {
  return {
    name: { value: <FranchiseeNameCell franchisee={franchisee}/>, sortValue: franchisee.name ?? '' }
  }
}
