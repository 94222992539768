import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '@/graphql/fragments/AuthorizationFragment'

export const GET_AMPLIFY_ACCOUNT_CARD_DATA = gql`
    ${AUTHORIZATION_FRAGMENT}
    query GetAmplifyAccountCardData($organizationId: String!, $franchiseGroupId: Int!) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                id
                name
                subscriptionLevel
                franchiseGroups {
                    amplifyAccountApplicationStatus
                }
            }
            franchiseGroup(id: $franchiseGroupId) {
                amplifyAccountApplicationStatus
            }
            authorization(organizationId: $organizationId, franchiseGroupId: $franchiseGroupId) {
                ...AuthorizationFragment
            }
        }
        amplifyAccounts(franchiseGroupId: $franchiseGroupId) {
            accountId
            name
            liveBalance {
                availableBalance {
                    amount
                }
            }
            amplifyAccount {
                earningsToDate {
                    amount
                }
                interestRate
                bankPartner
            }
            achAccountNumber
            achRoutingNumber
        }
        amplifyAccountPromotionalOffer {
            potentialYearlyEarningsFormatted
            subscribedAnnualPercentageYield
            unsubscribedAnnualPercentageYield
        }
    }
`
