import React, { useState, type ReactElement } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import FundAccountModal from './fund_account_modal/FundAccountModal'
import AmplifyUpgradeCardComponent from '../AmplifyUpgradeCardComponent'
import { ROUTES } from '@/api/routes'
import { BorderRadius, Color, FontWeight, IconSize } from '@/theme/theme'
import { Graphic } from '@/library/utility/Graphic'
import { V2_ALTIR_LOGO_BLUE } from '@/library/theme/graphics'
import {
  type GetAmplifyAccountCardData_amplifyAccounts as AmplifyAccount
} from '@/graphql/__generated__/GetAmplifyAccountCardData'
import { CardDivider, CardRow } from '@/library/layout/CardComponets'
import Button, { ButtonVariant } from '@/library/button/Button'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import DollarCircleIcon from '@/library/icons/DollarCircleIcon'
import { getCurrencyFormatted, getPercentageFormatted } from '@/utils/stringUtils'
import { isSubscribed } from '@/utils/subscriptionUtils'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import { type AltirSubscriptionLevel } from '@/graphql/__generated__/globalTypes'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface ActiveAmplifyAccountCardProps {
  subscriptionLevel?: AltirSubscriptionLevel
  amplifyAccount: AmplifyAccount
  hasTransferPermission: boolean
}

export default function ActiveAmplifyAccountCard ({
  subscriptionLevel,
  amplifyAccount,
  hasTransferPermission
}: ActiveAmplifyAccountCardProps): ReactElement {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false)
  const navigate = useAltirNavigate()
  const amplifyAccountBankId = Number(amplifyAccount.accountId)
  const availableBalance = amplifyAccount.liveBalance?.availableBalance?.amount
  const availableBalanceFormatted = getCurrencyFormatted(availableBalance)
  const earningsToDate = amplifyAccount?.amplifyAccount?.earningsToDate?.amount
  const isProSubscriber = isSubscribed(subscriptionLevel)

  const hasDeposits = (availableBalance ?? 0) > 0

  return (
    <Box
      bg={Color.WHITE}
      borderRadius={BorderRadius.CARD}
      paddingX={6}
      paddingY={6}
    >
      <FundAccountModal
        amplifyAccount={amplifyAccount ?? undefined}
        isOpen={isTransferModalOpen}
        onClose={() => { setIsTransferModalOpen(false) }}
      />
      <Flex flexDirection='column' color={Color.DARK_GREY} gap={6}>
        <Flex justifyContent='space-between' width='100%' paddingBottom={16}>
          <Flex flexDirection='column'>
            <Heading color={Color.DARK_BLUE} size='sm' paddingBottom={1}>Altir Amplify</Heading>
            <Heading color={Color.DARK_GREY} size='sm'>Checking</Heading>
          </Flex>
          <Graphic
            w='auto'
            h='20px'
            src={V2_ALTIR_LOGO_BLUE}
          />
        </Flex>
        <Box>
          <CardRow>
            <Text>Balance</Text>
            <Heading
              fontWeight={FontWeight.MEDIUM}
              color={availableBalanceFormatted != null ? Color.DARK_BLUE : Color.DARK_GREY}
            >
              {availableBalanceFormatted ?? EMPTY_BALANCE_VALUE}
            </Heading>
          </CardRow>
          <CardDivider/>
          {isProSubscriber &&
            <Flex flexDir='column'>
              <CardRow>
                <Text>Annual Percentage Yield (APY)</Text>
                <Text color={Color.DARK_BLUE}>
                  {getPercentageFormatted(amplifyAccount.amplifyAccount?.interestRate ?? null)}
                </Text>
              </CardRow>
              <CardDivider/>
            </Flex>
          }
          <CardRow>
            <Text>Earnings to Date</Text>
            <Text color={Color.DARK_BLUE}>
              {getCurrencyFormatted(earningsToDate, { minimumFractionDigits: 2 })}
            </Text>
          </CardRow>
        </Box>
        <Flex
          w='100%'
        >
          <AmplifyUpgradeCardComponent subscriptionLevel={subscriptionLevel} />
        </Flex>
        <Flex
          w='100%'
          flexDir='column'
          gap={4}
        >
          {
            !hasDeposits &&
              <Button
                text='Fund your Account'
                onClick={() => { setIsTransferModalOpen(true) }}
                beforeIcon={<DollarCircleIcon color={Color.WHITE} size={5}/>}
                onClickEventType={Event.OPEN_FUND_YOUR_ACCOUNT_MODAL_CLICK}
                isDisabled={!hasTransferPermission}
                toolTipText={!hasTransferPermission ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
              />
          }
          <Flex w='100%' justifyContent='end'>
            <Button
              text='View Account Details'
              variant={hasDeposits ? ButtonVariant.GREY : ButtonVariant.WHITE_OUTLINE}
              onClick={(e) => { navigate(`${ROUTES.ACCOUNT}/${amplifyAccountBankId}`, e) }}
              onClickEventType={Event.VIEW_AMPLIFY_ACCOUNT_DETAILS_CLICK}
              width={hasDeposits ? 'auto' : '100%'}
              afterIcon={hasDeposits ? <RightArrowIcon size={IconSize.SMALL}/> : undefined}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
