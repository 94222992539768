import React, { type ReactElement } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Center } from '@chakra-ui/react'
import OwnershipAttestationFormComponent from './OwnershipAttestationFormComponent'
import { type AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import {
  type UpdateOwnershipAttestationVariables,
  type UpdateOwnershipAttestation
} from '@/graphql/__generated__/UpdateOwnershipAttestation'
import { UPDATE_OWNERSHIP_ATTESTATION } from '@/graphql/mutations/UpdateOwnershipAttestation'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import AmplifyApplicationHeader
  from '@/components/pages/v2/sign_up/routes/amplify/routes/components/AmplifyApplicationHeader'
import { GET_AMPLIFY_BUSINESS_APPLICATION } from '@/graphql/queries/amplify_application/GetAmplifyBusinessApplication'
import {
  type GetAmplifyBusinessApplication,
  type GetAmplifyBusinessApplicationVariables
} from '@/graphql/__generated__/GetAmplifyBusinessApplication'
import { routeWithParams } from '@/utils/routerUtils'
import { AmplifyApplicationStep, getAmplifyApplicationRouteForContext } from '@/utils/amplifyApplicationUtils'
import { RouteParam } from '@/api/routes'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface AccountInfoComponentProps {
  franchiseGroupId: number
  applicationId: string
  backLink: string
  context: AmplifyApplicationContext
}

export default function OwnershipAttestationComponent (
  {
    applicationId,
    franchiseGroupId,
    backLink,
    context
  }: AccountInfoComponentProps
): ReactElement {
  const navigate = useAltirNavigate()

  const {
    data,
    loading,
    error
  } = useQuery<GetAmplifyBusinessApplication, GetAmplifyBusinessApplicationVariables>(
    GET_AMPLIFY_BUSINESS_APPLICATION,
    {
      variables: { franchiseGroupId },
      notifyOnNetworkStatusChange: true
    }
  )

  const [
    updateOwnershipAttestation,
    { loading: updateOwnershipAttestationLoading, error: updateOwnershipAttestationError }
  ] = useMutation<UpdateOwnershipAttestation, UpdateOwnershipAttestationVariables>(
    UPDATE_OWNERSHIP_ATTESTATION,
    {
      onCompleted: () => {
        // Navigate to business page
        const businessApplicationId = String(data?.amplifyBusinessApplication.id)
        const baseRoute = getAmplifyApplicationRouteForContext(AmplifyApplicationStep.BUSINESS, context)
        navigate(
          routeWithParams(
            baseRoute,
            [
              { param: RouteParam.BUSINESS_ID, value: String(franchiseGroupId) },
              { param: RouteParam.BUSINESS_APPLICATION_ID, value: businessApplicationId }
            ]
          ),
          null
        )
      },
      onError: () => {}
    }
  )

  async function handleSubmission (): Promise<void> {
    void updateOwnershipAttestation({ variables: { applicationId } })
  }

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={8} w='100%'>
        <AmplifyApplicationHeader
          backLink={backLink}
          businessName={data?.franchiseGroup?.name ?? null}
          header='Ownership Attestation'
        />
        <OwnershipAttestationFormComponent
          isLoading={updateOwnershipAttestationLoading}
          error={updateOwnershipAttestationError}
          onSubmit={handleSubmission}
        />
      </Center>
    </AltirSkeleton>
  )
}
