import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { Color } from '@/theme/theme'
import InstitutionLogo from '@/library/logo/InstitutionLogo'

interface CounterpartyGraphicComponentProps {
  logoAssetUrl?: string
}

export default function CounterpartyGraphicComponent ({
  logoAssetUrl
}: CounterpartyGraphicComponentProps): ReactElement {
  return (
    <Flex
      borderRadius='100%'
      backgroundColor={Color.WHITE}
      py={3}
      px={3}
    >
      <InstitutionLogo
        src={logoAssetUrl ?? undefined}
        height='20px'
      />
    </Flex>
  )
}
