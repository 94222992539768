import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import LeftArrowIcon from '@/library/icons/LeftArrowIcon'
import { IconSize } from '@/theme/theme'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface AmplifyApplicationHeaderProps {
  backLink: string
  businessName: string | null
  header: string
  description?: string
}

export default function AmplifyApplicationHeader ({
  backLink,
  businessName,
  header,
  description
}: AmplifyApplicationHeaderProps): ReactElement {
  const navigate = useAltirNavigate()
  return (
    <Center flexDir='column' w='100%' gap={8}>
      <Button
        text='Back'
        beforeIcon={<LeftArrowIcon size={IconSize.SMALL}/>}
        variant={ButtonVariant.GREY}
        onClick={() => { navigate(backLink, null) }}
        width='auto'
        size={ButtonSize.SMALL}
      />
      <Center flexDir='column' gap={1} w='100%' textAlign='center'>
        <Heading size='sm'>{businessName}</Heading>
        <Heading>{header}</Heading>
        {description != null && <Text>{description}</Text>}
      </Center>
    </Center>
  )
}
