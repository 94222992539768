import React, { useState, type ReactElement } from 'react'

import { Flex } from '@chakra-ui/react'
import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { TransferRuleErrorType } from '@/graphql/__generated__/globalTypes'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'
import { ROUTES } from '@/api/routes'
import ContactUsModal from '@/library/nav/help_center/ContactUsModal'
import { Color } from '@/theme/theme'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface TransferRuleDropdownErrorMessageProps {
  transferRule: TransferRule
}

export interface TransferRuleErrorAttributes {
  text: string
  linkText?: string
  onLinkClick?: () => void
  color?: Color
}

export default function TransferRuleDropdownErrorMessage (
  { transferRule }: TransferRuleDropdownErrorMessageProps
): ReactElement {
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false)
  const navigate = useAltirNavigate()

  function errorContentForRule (transferRule: TransferRule): TransferRuleErrorAttributes | null {
    if (transferRule.lastEvaluationErrorType == null) return null

    switch (transferRule.lastEvaluationErrorType) {
      case TransferRuleErrorType.LOGIN: {
        return {
          text: `This transfer rule failed because ${transferRule.counterparty.name} has been de-linked.`,
          linkText: 'Re-link the account',
          onLinkClick: () => { navigate(ROUTES.SETTINGS, null, { state: { isLinkedAccountsSelected: true } }) },
          color: Color.ERROR_RED
        }
      }
      case TransferRuleErrorType.LOGIN_RECENTLY_RESOLVED: {
        return {
          text: `This transfer rule failed because ${transferRule.counterparty.name} has been de-linked. It has since been resolved.`
        }
      }
      case TransferRuleErrorType.SYSTEM_ERROR: {
        return {
          text: 'This transfer rule failed due to an internal error.',
          linkText: 'Contact us',
          onLinkClick: () => { setIsContactUsModalOpen(true) },
          color: Color.ERROR_RED
        }
      }
    }
    return null
  }

  const errorAttributes = errorContentForRule(transferRule)
  if (errorAttributes == null) {
    return <></>
  }
  const linkOptions = errorAttributes.linkText != null && errorAttributes.onLinkClick != null
    ? {
        linkText: errorAttributes.linkText,
        onLinkClick: errorAttributes.onLinkClick
      }
    : undefined

  return (
    <Flex w='100%'>
      <StackedInfoAttribute
        header='Rule Activity'
        subtext={errorAttributes.text}
        customColor={errorAttributes.color}
        linkOptions={linkOptions}
      />
      <ContactUsModal isOpen={isContactUsModalOpen} setIsOpen={(isOpen) => { setIsContactUsModalOpen(isOpen) }}/>
    </Flex>
  )
}
