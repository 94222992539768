import React, { type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import MockDropDownComponent from '../layout/drop_down/MockDropDownComponent'
import RecurringRuleIcon from '../icons/RecurringRuleIcon'
import { Color, IconSize } from '@/theme/theme'
import useAltirNavigate from '@/hooks/useAltirNavigate'
import { logEvent } from '@/utils/metricUtils'
import { ROUTES } from '@/api/routes'

interface TransferRulesDropDownProps {
  count: number

}

export default function TransferRulesCountDropDown ({ count }: TransferRulesDropDownProps): ReactElement {
  const navigate = useAltirNavigate()
  if (count < 1) {
    return <></>
  }
  return (
    <MockDropDownComponent
      title='Automated Transfer Rules'
      icon={<RecurringRuleIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>}
      onClick={(e) => { navigate(ROUTES.AUTOMATED_TRANSFERS, e); logEvent(Event.TRANFER_PAGE_TO_RULES_PAGE) }}
      padding={6}
      rightDescription={`(${count})`}
    />
  )
}
