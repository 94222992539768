import React, { useState, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import TransactionsSortAndFilterComponent from './TransactionsSortAndFilterComponent'
import TransactionsQueryMetaRowComponent from './TransactionsQueryMetaRowComponent'
import NoTransactionsFoundComponent from './NoTransactionsFoundComponent'
import { createBankAccountIdMap } from './utils'

import { GET_TRANSACTIONS_META } from '@/graphql/queries/GetTransactionsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import TransactionsRowListComponent from '@/library/transactions/TransactionsRowListComponent'
import {
  type GetTransactionsMetaData,
  type GetTransactionsMetaDataVariables
} from '@/graphql/__generated__/GetTransactionsMetaData'
import { type GetTransactionsPageData } from '@/graphql/__generated__/GetTransactionsPageData'
import { useNavigationState } from '@/hooks/useNavigationState'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { GET_TRANSACTIONS } from '@/graphql/queries/transactions/GetTransactions'
import { TransactionSortType, type DerivedTransactionCategory } from '@/graphql/__generated__/globalTypes'
import { type GetTransactions, type GetTransactionsVariables } from '@/graphql/__generated__/GetTransactions'

export const TRANSACTIONS_PAGE_QUERY_LIMIT = 100

export interface TransactionsComponentProps {
  organizationId: string
  transactionsPageData?: GetTransactionsPageData
  isPageDataLoading?: boolean
  // ID of bank account for which TransactionsComponent should be localized
  // Only pass if you want to globally restrict the component to this specific account
  singleAccountViewAccountId?: number
}

interface TransactionComponentNavigationState {
  categoryLabels: DerivedTransactionCategory[]
  bankAccountIds: number[]
  shouldPreFilterOnSelectedBusiness?: boolean
}

// Type of GetTransactionsDataVariables without organizationId
export type TransactionsQueryVariables = Omit<GetTransactionsMetaDataVariables, 'organizationId'>

export default function TransactionsComponent (
  {
    transactionsPageData,
    isPageDataLoading,
    organizationId,
    singleAccountViewAccountId
  }: TransactionsComponentProps
): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const [paginationOffset, setPaginationOffset] = useState(0)
  const navigationState = useNavigationState<TransactionComponentNavigationState>()
  const initialCategoryFilter = navigationState?.categoryLabels ?? undefined
  const initialBankAccountFilter = navigationState?.bankAccountIds ?? undefined
  const initialFranchiseGroupFilter = navigationState?.shouldPreFilterOnSelectedBusiness === true
    ? [selectedFranchiseGroupId]
    : undefined

  console.log(navigationState)
  const [
    transactionsQueryVariables,
    setTransactionsQueryVariables
  ] = useState<TransactionsQueryVariables>({
    filter: {
      categoryLabels: initialCategoryFilter,
      bankAccountIds: singleAccountViewAccountId != null ? [singleAccountViewAccountId] : initialBankAccountFilter,
      franchiseGroupIds: initialFranchiseGroupFilter
    },
    sort: { type: TransactionSortType.Date, ascending: false }
  })
  const {
    data: transactionsQueryData,
    loading: isTransactionsDataLoading,
    error: transactionsDataError
  } = useQuery<GetTransactions, GetTransactionsVariables>(GET_TRANSACTIONS, {
    variables: {
      organizationId,
      ...transactionsQueryVariables,
      offset: paginationOffset,
      limit: TRANSACTIONS_PAGE_QUERY_LIMIT,
      includePending: true
    }
  })

  const {
    data: transactionsQueryMetaData,
    loading: isTransactionsMetaDataLoading,
    error: transactionsMetaDataError
  } = useQuery<GetTransactionsMetaData, GetTransactionsMetaDataVariables>(GET_TRANSACTIONS_META, {
    variables: {
      organizationId,
      ...transactionsQueryVariables
    }
  })

  function updatePaginationOffset (increase: boolean): void {
    const updatedOffset = increase
      ? paginationOffset + TRANSACTIONS_PAGE_QUERY_LIMIT
      : paginationOffset - TRANSACTIONS_PAGE_QUERY_LIMIT
    setPaginationOffset(updatedOffset)
  }

  function handleSortAndFilterChange (updatedVariables: TransactionsQueryVariables): void {
    // Allow SortAndFilter component to update a subset of search params
    setTransactionsQueryVariables({
      ...transactionsQueryVariables,
      ...updatedVariables
    })

    // Reset pagination whenever search/sort params change
    setPaginationOffset(0)
  }

  const transactionsMeta = transactionsQueryMetaData?.currentUser
    ?.selectedOrganization?.transactions.meta ?? undefined
  const transactionsData = transactionsQueryData?.currentUser
    ?.selectedOrganization?.transactions.transactions ?? undefined
  const isTransactionsArrayEmpty = transactionsData?.length === 0
  const totalTransactionCount = transactionsMeta?.totalTransactions
  const financialAccounts = transactionsPageData?.currentUser?.selectedOrganization?.financialAccounts ?? undefined
  const bankAccountIdMap = financialAccounts != null ? createBankAccountIdMap(financialAccounts) : undefined
  return (
    <Flex flexDir='column' gap={6}>
      <TransactionsSortAndFilterComponent
        transactionsPageData={transactionsPageData}
        isPageDataLoading={isPageDataLoading}
        transactionsQueryVariables={transactionsQueryVariables}
        updateTransactionsQueryVariables={handleSortAndFilterChange}
        totalTransactionCount={totalTransactionCount}
        isSingleAccountView={singleAccountViewAccountId != null}
      />
      <Flex flexDir='column' gap={3}>
        <TransactionsQueryMetaRowComponent
          transactionsMeta={transactionsMeta}
          isLoading={isTransactionsMetaDataLoading}
          error={transactionsMetaDataError}
          paginationOffset={paginationOffset}
          onUpdatePaginationOffset={updatePaginationOffset}
        />
        <AltirSkeleton isLoading={isTransactionsDataLoading} error={transactionsDataError} loadingHeight='800px'>
          {isTransactionsArrayEmpty
            ? <NoTransactionsFoundComponent/>
            : <TransactionsRowListComponent
                bankAccountIdMap={bankAccountIdMap}
                transactionsData={transactionsData}
              />
      }
        </AltirSkeleton>
        <TransactionsQueryMetaRowComponent
          transactionsMeta={transactionsMeta}
          isLoading={isTransactionsMetaDataLoading}
          error={transactionsMetaDataError}
          paginationOffset={paginationOffset}
          onUpdatePaginationOffset={updatePaginationOffset}
        />
      </Flex>
    </Flex>
  )
}
