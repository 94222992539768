import { DateTimeTemplate, getFormattedDateString } from './dateUtils'
import { getMask } from './financialAccountUtils'
import { getCurrencyFormatted } from './stringUtils'
import { areArraysEqual } from './arrayUtils'
import { AccountLinkStatus, TransferRuleDayOfWeek, TransferRuleErrorType, TransferRuleLastEvalResult, TransferRuleType }
  from '@/graphql/__generated__/globalTypes'
import {
  // eslint-disable-next-line max-len
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
} from '@/graphql/__generated__/GetTransferRules'
import { type GetTransferRule_transferRule as TransferRuleExtended } from '@/graphql/__generated__/GetTransferRule'
import { type FinancialAccountShortFragment } from '@/graphql/__generated__/FinancialAccountShortFragment'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

const WEEKDAYS = [
  TransferRuleDayOfWeek.MONDAY,
  TransferRuleDayOfWeek.TUESDAY,
  TransferRuleDayOfWeek.WEDNESDAY,
  TransferRuleDayOfWeek.THURSDAY,
  TransferRuleDayOfWeek.FRIDAY
]

export enum GenericTransferRuleType {
  BALANCE,
  TIME
}

export interface FormattedBalanceRule {
  to: string
  from: string
  balance: string
  threshold: string
  mostRecentTransferDate: string | null
  sentenceDescription: string
  updatedAt: string
  executionDays: string
}

export interface FormattedTimeRule {
  to: string
  from: string
  amount: string
  dayOfWeek?: string
  dateOfMonth?: string
  ruleStartDate?: string
  mostRecentTransferDate: string | null
  nextTransfer: string
  frequency: string
  updatedAt: string
}

export function getFormattedBalanceRule (transferRule: TransferRule): FormattedBalanceRule {
  const balance = transferRule.targetBalance != null
    ? getCurrencyFormatted(transferRule.targetBalance, { minimumFractionDigits: 2 })
    : 'N/A'
  const formatted = {
    balance,
    threshold: transferRule.transferRuleType === TransferRuleType.TARGET_BALANCE ? `> ${balance}` : `< ${balance}`,
    to: formattedCounterpartyForRule(transferRule.toAccount),
    from: formattedCounterpartyForRule(transferRule.fromAccount),
    mostRecentTransferDate: getFormattedDateString(transferRule.mostRecentTransferEST, DateTimeTemplate.FULL),
    updatedAt: getFormattedDateString(transferRule.updatedAt, DateTimeTemplate.FULL) ?? 'N/A',
    executionDays: getBalanceRuleExecutionDays(transferRule)
  }
  const sentenceDescription = transferRule.transferRuleType === TransferRuleType.TARGET_BALANCE
    ? `When ${formatted.from} exceeds ${formatted.balance}, excess funds will be transferred into ${formatted.to}.`
    : `When ${formatted.to} falls below ${formatted.balance}, funds will be pulled from ${formatted.from}.`

  return {
    ...formatted,
    sentenceDescription
  }
}

export function getFormattedTimeRule (transferRule: TransferRule): FormattedTimeRule {
  return {
    amount: transferRule.transferAmount != null
      ? getCurrencyFormatted(transferRule.transferAmount, { minimumFractionDigits: 2 })
      : 'N/A',
    to: formattedCounterpartyForRule(transferRule.toAccount),
    from: formattedCounterpartyForRule(transferRule.fromAccount),
    mostRecentTransferDate: getFormattedDateString(transferRule.mostRecentTransferEST, DateTimeTemplate.FULL),
    nextTransfer: getFormattedDateString(transferRule.nextScheduledTransferEST, DateTimeTemplate.FULL) ?? 'N/A',
    frequency: formatTransferRuleType(transferRule.transferRuleType),
    dayOfWeek: transferRule.targetDay ?? '',
    dateOfMonth: transferRule.targetDate != null
      ? formatDateOfMonthToString(transferRule.targetDate)
      : undefined,
    ruleStartDate: getFormattedDateString(transferRule.ruleStartDate, DateTimeTemplate.FULL) ?? undefined,
    updatedAt: getFormattedDateString(transferRule.updatedAt, DateTimeTemplate.FULL) ?? 'N/A'
  }
}

// TODO (PJ): Unify counterparty name formatting
// financialAccountUtils.ts -> getCounterpartyShortName()
export function formattedCounterpartyForRule (account: FinancialAccountShortFragment): string {
  const counterpartyName = account.name ?? account.counterparty?.nickname ?? ''
  const counterpartyAchNumber = getMask(account.lastFour ?? account.counterparty?.achAccountNumber ?? null) ?? ''
  return `${String(counterpartyName)} ${String(counterpartyAchNumber)}`
}

export function formatTransferRuleType (ruleType: TransferRuleType): string {
  switch (ruleType) {
    case TransferRuleType.DAILY: return 'Daily'
    case TransferRuleType.WEEKLY: return 'Weekly'
    case TransferRuleType.MONTHLY: return 'Monthly'
    case TransferRuleType.ONE_TIME: return 'Once'
    case TransferRuleType.TARGET_BALANCE :
    case TransferRuleType.MIN_TARGET_BALANCE: {
      return 'Balance-Based'
    }
    default:
      throw Error('Invalid transfer rule type')
  }
}

export function formatDateOfMonthToString (date: number): string {
  switch (date) {
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    case 29:
    case 30:
    case 31:
      return String(date) + 'th (or last of the month)'
    default:
      return String(date) + 'th'
  }
}

export function isBalanceRule (ruleType: TransferRuleType): boolean {
  return ruleType === TransferRuleType.TARGET_BALANCE || ruleType === TransferRuleType.MIN_TARGET_BALANCE
}

export function isRuleInUnaddressedErrorState (rule: TransferRule): boolean {
  if (rule.toAccount.status === AccountLinkStatus.LOGIN_REQUIRED ||
    rule.fromAccount.status === AccountLinkStatus.LOGIN_REQUIRED
  ) {
    return true
  }
  return rule.lastEvaluationStatus === TransferRuleLastEvalResult.ERROR &&
    rule.lastEvaluationErrorType !== TransferRuleErrorType.LOGIN_RECENTLY_RESOLVED
}

/**
 * Returns the account for which we need an active connection to enable rule execution
 */
export function getOperativeConnectedAccount (rule: TransferRuleExtended): FinancialAccountExtendedFragment {
  if (rule.transferRuleType === TransferRuleType.MIN_TARGET_BALANCE) return rule.toAccount

  return rule.fromAccount
}

export function getBalanceRuleExecutionDays (rule: TransferRule): string {
  if (areArraysEqual(rule.balanceRuleExecutionDaysOfWeek, WEEKDAYS)) {
    return 'Weekdays'
  }

  return rule.balanceRuleExecutionDaysOfWeek.map(day => getWeekdayFormatted(day)).join(', ')
}

export function getWeekdayFormatted (dayOfWeek: TransferRuleDayOfWeek): string {
  switch (dayOfWeek) {
    case TransferRuleDayOfWeek.SUNDAY:
      return 'Sun'
    case TransferRuleDayOfWeek.MONDAY:
      return 'Mon'
    case TransferRuleDayOfWeek.TUESDAY:
      return 'Tue'
    case TransferRuleDayOfWeek.WEDNESDAY:
      return 'Wed'
    case TransferRuleDayOfWeek.THURSDAY:
      return 'Thu'
    case TransferRuleDayOfWeek.FRIDAY:
      return 'Fri'
    case TransferRuleDayOfWeek.SATURDAY:
      return 'Sat'
  }
}
