import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import TransferRuleActivationModal, { ActivationDirection } from '../TransferRuleActivationModal'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import { type TransferRuleFragment } from '@/graphql/__generated__/TransferRuleFragment'

interface RuleManagementModalProps {
  rule: TransferRuleFragment
  onUpdate: () => void
  canEditTransferRules: boolean
}

export default function RuleManagementModal ({
  rule,
  canEditTransferRules,
  onUpdate
}: RuleManagementModalProps): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { text: activationText, variant: activationVariant } = getActivationButtonProps(rule.isActive)
  return (
    <Flex>
      <Button
        text={activationText}
        variant={activationVariant}
        isDisabled={!canEditTransferRules}
        toolTipText={!canEditTransferRules ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
        onClick={() => { setIsModalOpen(true) }}
        width='auto'
        size={ButtonSize.SMALL}
      />
      <TransferRuleActivationModal
        transferRuleId={rule.id}
        isModalOpen={isModalOpen}
        onModalClose={() => { setIsModalOpen(false) }}
        onUpdate={onUpdate}
        activationDirection={rule.isActive ? ActivationDirection.Deactivate : ActivationDirection.ReActivate}
      />
    </Flex>
  )
}

function getActivationButtonProps (isActive: boolean): { text: string, variant: ButtonVariant } {
  return {
    text: isActive ? 'Deactivate Rule' : 'Re-Activate',
    variant: isActive ? ButtonVariant.DESTRUCTIVE_TRANSPARENT : ButtonVariant.PRIMARY
  }
}
