import { Box, Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { type QuickActionProps } from './QuickAction'
import { BorderRadius, Color } from '@/theme/theme'

type QuickActionBoxProps = QuickActionProps & { onClick: (e: React.MouseEvent) => void }

export function QuickActionBox ({
  isPopoverDisabled = false,
  icon,
  onClick,
  text
}: QuickActionBoxProps): ReactElement {
  return (
    <Box
      borderRadius={BorderRadius.CARD}
      bg={Color.WHITE}
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      minW='100px'
      minH='120px'
      _hover={{ cursor: 'pointer', bg: Color.GREY }}
      gap={2}
      onClick={(e) => { isPopoverDisabled && onClick(e) }}
      pointerEvents='auto'
      px={4}
      width='100%'
    >
      <Flex>
        {icon}
      </Flex>
      <Text fontSize='sm' textAlign='center' color={Color.DARK_BLUE}>{text ?? 'Quick Action'}</Text>
    </Box>
  )
}
