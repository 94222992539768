import { ASSET_HOSTING_URL } from './urls'

// Altir Logos
export const V2_ALTIR_LOGO_BLUE = `${ASSET_HOSTING_URL}/assets/v2_altir_logo_blue.svg`
export const V2_ALTIR_LOGO_BLUE_GRADIENT = `${ASSET_HOSTING_URL}/assets/v2_altir_logo_blue_gradient.svg`
export const V2_ALTIR_LOGO_BLUE_GRADIENT_SPARKLE = `${ASSET_HOSTING_URL}/assets/v2_altir_logo_blue_gradient_sparkle.svg`
export const V2_ALTIR_LOGO_BLUE_SQUARE_BOUNDING_BOX = `${ASSET_HOSTING_URL}/assets/v2_altir_logo_blue_square_bounding_box.svg`
export const V2_ALTIR_LOGO_WITH_BLUE_BOUNDING_BOX = `${ASSET_HOSTING_URL}/assets/v2_altir_logo_white_blue_bounding_box.svg`
export const V2_ALTIR_LOGO_BLUE_WITH_TEXT = `${ASSET_HOSTING_URL}/assets/v2_altir_logo_blue_with_text.svg`
export const V2_ALTIR_LOGO_GREY = `${ASSET_HOSTING_URL}/assets/v2_altir_logo_grey.svg`

// Graphics
export const ALTIR_PRO_BLUE_GRAPHIC = `${ASSET_HOSTING_URL}/assets/altir_pro_blue.svg`
export const ALTIR_DOLLAR_HEART_FIGURE = `${ASSET_HOSTING_URL}/assets/altir_dollar_heart_figure.svg`
export const BANK_PLUS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/bank_plus.svg`
export const CHAIN_LINK = `${ASSET_HOSTING_URL}/assets/chain_link.svg`
export const CARD_SPARKLE = `${ASSET_HOSTING_URL}/assets/card_sparkle.svg`
export const CHECK_SEND_DOLLAR_SIGN = `${ASSET_HOSTING_URL}/assets/check_send_dollar_sign.svg`
export const CIRCLE_CHECK_GRAPHIC = `${ASSET_HOSTING_URL}/assets/circle_check_graphic.svg`
export const DOLLAR_HEART = `${ASSET_HOSTING_URL}/assets/v2_dollar_heart.svg`
export const DOLLAR_HEART_SPARKLE = `${ASSET_HOSTING_URL}/assets/dollar_heart_sparkle.svg`
export const ERROR_OCTAGON_GRAPHIC = `${ASSET_HOSTING_URL}/assets/error_octagon.svg`
export const FDIC_PILLAR_GRAPHIC = `${ASSET_HOSTING_URL}/assets/fdic_pillar.svg`
export const GRAPH_GRAPHIC = `${ASSET_HOSTING_URL}/assets/graph.svg`
export const GRASSHOPPER_LOGO = `${ASSET_HOSTING_URL}/assets/grasshopper_logo.svg`
export const HANDHOLDING_PEOPLE = `${ASSET_HOSTING_URL}/assets/handholding_people.svg`
export const HANDHOLDING_PEOPLE_HEART = `${ASSET_HOSTING_URL}/assets/handholding_people_heart.svg`
export const HOUR_GLASS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/hour_glass.svg`
export const LOCK = `${ASSET_HOSTING_URL}/assets/lock.svg`
export const MAGNIFYING_GLASS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/magnifying_glass.svg`
export const MULTIPLE_BUSINESS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/multiple_business_graphic.svg`
export const NOT_AUTHORIZED_OCTAGON_GRAPHIC = `${ASSET_HOSTING_URL}/assets/not_authorized_octagon.svg`
export const PLAID_LOGO = `${ASSET_HOSTING_URL}/assets/plaid_logo.svg`
export const RECURRING_TRANSFER_SPARKLE = `${ASSET_HOSTING_URL}/assets/recurring_transfer_sparkle.svg`
export const SCREEN_404 = `${ASSET_HOSTING_URL}/assets/screen_404.svg`
export const SCREEN_500 = `${ASSET_HOSTING_URL}/assets/500_screen.svg`
export const STOPWATCH = `${ASSET_HOSTING_URL}/assets/stopwatch.svg`
export const SUNGLASSES = `${ASSET_HOSTING_URL}/assets/sunglasses.svg`
export const SUNGLASSES_SPARKLE = `${ASSET_HOSTING_URL}/assets/sunglasses_sparkle.svg`
export const TELESCOPE = `${ASSET_HOSTING_URL}/assets/telescope.svg`
export const THREE_TWO_FIVE_PERCENT_BANNER = `${ASSET_HOSTING_URL}/assets/three_two_five_percent_banner.svg`
export const V2_ALTIR_CARD = `${ASSET_HOSTING_URL}/assets/v2_altir_card.svg`
export const V2_ALTIR_CARD_DOUBLE = `${ASSET_HOSTING_URL}/assets/v2_altir_card_double.svg`

// Partner logos
export const DOYLE_LOGO = `${ASSET_HOSTING_URL}/logos/doyle_logo.svg`
export const HILINE_LOGO = `${ASSET_HOSTING_URL}/logos/hiline_logo.svg`
export const SULLY_FI_LOGO = `${ASSET_HOSTING_URL}/logos/sully_logo.svg`
export const SIB_LOGO = `${ASSET_HOSTING_URL}/logos/sib_logo.svg`
