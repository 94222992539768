import React, { useState, type ReactElement } from 'react'
import { Divider, Flex, Heading, Text } from '@chakra-ui/react'
import Popover, { PopoverContent, PopoverTrigger } from '@/library/popover/Popover'
import InfoIcon from '@/library/icons/InfoIcon'
import { Color, IconSize } from '@/theme/theme'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

interface TransferLimitInfoPopoverProps {
  amplifyAccounts: FinancialAccountExtendedFragment[]
}

export default function TransferLimitInfoPopover ({
  amplifyAccounts
}: TransferLimitInfoPopoverProps): ReactElement {
  const [isHovered, setIsHovered] = useState(false)

  // We can leverage the first one because these should all be the same
  const amplifyAccount = amplifyAccounts[0]
  const achCreditLimit = amplifyAccount?.amplifyAccount?.moneyMovementLimit?.dailyAchCreditOriginationLimit?.amount
  const achDebitLimit = amplifyAccount?.amplifyAccount?.moneyMovementLimit?.dailyAchDebitOriginationLimit?.amount
  const wireLimit = amplifyAccount?.amplifyAccount?.moneyMovementLimit?.dailyWireOriginationLimit?.amount

  const ctaColor = isHovered ? Color.BRIGHT_BLUE_HOVER : Color.DARK_GREY

  return (
    <Popover>
      <PopoverTrigger>
        <Flex gap={2}
          alignItems='center'
          onMouseEnter={() => { setIsHovered(true) }}
          onMouseLeave={() => { setIsHovered(false) }}
        >
          <Text color={ctaColor}>Transfer Limits</Text>
          <InfoIcon color={ctaColor} size={IconSize.SMALL}/>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <Flex w='100%' flexDir='column' gap={6}>
          <Heading size='sm'>Transfer Limits</Heading>
          <Flex flexDir='column' gap={2}>
            <Row title='ACH (Outgoing)' description={getLimitFormatted(achCreditLimit)}/>
            <Divider/>
            <Row title='ACH (Incoming)' description={getLimitFormatted(achDebitLimit)}/>
            <Divider/>
            <Row title='Wire' description={getLimitFormatted(wireLimit)}/>
          </Flex>
        </Flex>
      </PopoverContent>
    </Popover>
  )
}

function getLimitFormatted (amount: number | null | undefined): string {
  return `${getCurrencyFormatted(amount)} per day`
}

function Row ({ title, description }: { title: string, description: string }): ReactElement {
  return (
    <Flex w='100%' justifyContent='space-between'>
      <Text>{title}</Text>
      <Text color={Color.DARK_BLUE}>{description}</Text>
    </Flex>
  )
}
