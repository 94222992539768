import React, { type ReactElement } from 'react'
import { getFormattedTransaction } from './utils/transactionUtils'
import TransactionRowDropdownContent from './TransactionRowDropdownContent'
import TransferStatusBadge from '../badge/TransferStatusBadge'
import Table, { TableColumnType, type TableData, TABLE_DROPDOWN_CONTENT, type TableHeader } from '../table/Table'
import { type TransactionFragment } from '@/graphql/__generated__/TransactionFragment'
import { getFormattedDateString } from '@/utils/dateUtils'
import { getExpectedSettlementDateTime } from '@/utils/transferUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'

interface AutomatedTransferRuleTableRow extends TableData {
  date: { value: string }
  type: { value: string | null }
  expectedSettlementDate: { value: string }
  status: { value: ReactElement, sortValue: string }
  amount: { value: string, sortValue: number }
  [TABLE_DROPDOWN_CONTENT]: { value: ReactElement }
}

const AUTOMATED_TRANSFER_RULE_TRANSACTION_TABLE_METADATA: Array<TableHeader<AutomatedTransferRuleTableRow>> = [
  { header: 'Date', key: 'date', type: TableColumnType.DATE },
  { header: 'Type', key: 'type' },
  { header: 'Expected Settlement', key: 'expectedSettlementDate', type: TableColumnType.DATE, alignment: 'center' },
  { header: 'Status', key: 'status', isReactElement: true, alignment: 'center' },
  { header: 'Amount', key: 'amount', isReactElement: false, alignment: 'end' },
  { header: 'dropdownContent', key: TABLE_DROPDOWN_CONTENT, isReactElement: true, alignment: 'end' }
]

interface TransactionsWithOriginatingTransferTableProps {
  transactions: TransactionFragment[]
  emptyStateHeader?: string
  emptyStateSubHeader?: string
}

export default function TransactionsWithOriginatingTransferTable ({
  transactions,
  emptyStateHeader = 'No transactions here.',
  emptyStateSubHeader = 'Try updating your search parameters.'
}: TransactionsWithOriginatingTransferTableProps): ReactElement {
  const tableData = transactions.map(txn => getTableRow(txn))
  return (
    <Table
      isSearchEnabled={true}
      metadata={AUTOMATED_TRANSFER_RULE_TRANSACTION_TABLE_METADATA}
      data={tableData}
      emptyStateHeader={emptyStateHeader}
      emptyStateSubHeader={emptyStateSubHeader}
    />
  )
}

function getTableRow (transaction: TransactionFragment): AutomatedTransferRuleTableRow {
  const {
    categoryLabelFormatted,
    dateFormatted,
    longDescription,
    fromAccount,
    toAccount,
    expectedSettlementDate,
    transferType
  } = getFormattedTransaction(transaction)
  return {
    date: { value: getFormattedDateString(transaction.date) ?? '' },
    expectedSettlementDate: { value: getExpectedSettlementDateTime(transaction) ?? '' },
    status: {
      value: (
        <TransferStatusBadge
          status={transaction.originatingTransfer?.status ?? undefined}
          expectedSettlementDate={transaction.originatingTransfer?.estimatedSettlementDate ?? null}
        />
      ),
      sortValue: transaction.originatingTransfer?.status ?? ''

    },
    amount: { value: getCurrencyFormatted(transaction.amount), sortValue: transaction.amount },
    [TABLE_DROPDOWN_CONTENT]: {
      value: (
        <TransactionRowDropdownContent
          categoryLabelFormatted={categoryLabelFormatted}
          longDescription={longDescription}
          fromAccount={fromAccount}
          toAccount={toAccount}
          dateFormatted={dateFormatted}
          isCheckManagementEnabled={false}
          originatingTransfer={transaction.originatingTransfer}
          expectedSettlementDate={expectedSettlementDate}
        />
      )
    },
    type: { value: transferType }
  }
}
