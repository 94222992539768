import { type BusinessFilterParams } from './BusinessSelectorComponent'

export interface BusinessSelection {
  id?: string
  name?: string | null
  amplifyAccounts: Array<{
    accountId?: number | null
  }> | null
}

export function filterBusinesses (
  businesses: BusinessSelection[],
  businessFilterParams: BusinessFilterParams
): BusinessSelection[] {
  if (businessFilterParams.hasAmplify === true) {
    return businesses
      .filter(business => {
        return (business.amplifyAccounts ?? []).length > 0
      })
  } else if (businessFilterParams.needsToApplyForAmplify === true) {
    return businesses
      .filter(business => {
        return !((business.amplifyAccounts ?? []).length > 0)
      })
  }

  return businesses
}
