import React, { type ReactElement } from 'react'
import { Tooltip } from '@chakra-ui/react'
import AmplifyFormGroupComponent from '../AmplifyFormGroupComponent'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import Loader from '@/library/loading/Loader'
import BriefcaseIcon from '@/library/icons/BriefcaseIcon'
import {
  // eslint-disable-next-line max-len
  type GetAmplifyAccountApplication_currentUser_franchiseGroup_amplifyAccountApplication_businessApplication as BusinessApplicationData
} from '@/graphql/__generated__/GetAmplifyAccountApplication'
import { Color, IconSize } from '@/theme/theme'
import { RouteParam, ROUTES } from '@/api/routes'
import { routeWithParams } from '@/utils/routerUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface BusinessApplicationComponentProps {
  franchiseGroupId: number
  businessApplication?: BusinessApplicationData | null
  hasCompletedOwnershipAttestation: boolean
  context: AmplifyApplicationContext
}

const BUSINESS_NAME_PLACEHOLDER = 'Business, Inc.'

export default function BusinessApplicationRow (
  {
    franchiseGroupId,
    businessApplication,
    hasCompletedOwnershipAttestation,
    context
  }: BusinessApplicationComponentProps
): ReactElement {
  const navigate = useAltirNavigate()

  const businessName = businessApplication?.legalName ?? BUSINESS_NAME_PLACEHOLDER
  const isDisabled = !hasCompletedOwnershipAttestation

  function handleFormGroupClick (): void {
    if (!hasCompletedOwnershipAttestation) return
    const link = routeWithParams(
      getRouteForContext(context),
      [
        {
          param: RouteParam.BUSINESS_ID,
          value: String(franchiseGroupId)
        },
        {
          param: RouteParam.BUSINESS_APPLICATION_ID,
          value: String(businessApplication?.id)
        }
      ]
    )
    navigate(link, null)
  }

  if (businessApplication == null) {
    return <Loader/>
  }

  const tooltipLabel = !hasCompletedOwnershipAttestation
    ? 'Please complete the Ownership Attestation before beginning the business section'
    : undefined
  return (
    <Tooltip
      bg={Color.DARK_BLUE}
      color={Color.WHITE}
      label={tooltipLabel}
    >
      <AmplifyFormGroupComponent
        title='Business Profile'
        subtitle={businessName}
        status={businessApplication.status}
        icon={<BriefcaseIcon size={IconSize.SCHMEDIUM} color={isDisabled ? Color.DARK_GREY : Color.DARK_BLUE}/>}
        handleClick={handleFormGroupClick}
        isDisabled={!hasCompletedOwnershipAttestation}
      />
    </Tooltip>
  )
}

function getRouteForContext (context: AmplifyApplicationContext): string {
  switch (context) {
    case AmplifyApplicationContext.DEFAULT:
      return ROUTES.AMPLIFY_APP_BUSINESS
    case AmplifyApplicationContext.ONBOARDING:
      return ROUTES.V2_SIGN_UP_AMPLIFY_APP_BUSINESS
  }
}
