
import React, { useState, type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import TableHeaderRow, { SortDirection, type TableColumnSortSelection } from './components/header/TableHeaderRow'
import TableBody from './components/body/TableBody'
import { sortAndFilterTableData } from './utils/tableUtils'
import TableEmptyState from './components/TableEmptyState'
import SearchBar from '../form/text/SearchBar'
import { Color, BorderRadius } from '@/theme/theme'

export const TABLE_DROPDOWN_CONTENT = 'TABLE_DROPDOWN_CONTENT'

export enum TableColumnType {
  DATE = 'DATE'
}

export interface TableColumnFormat {
  value: any
  sortValue?: any
}

export type TableData = Record<string, TableColumnFormat>

export interface TableHeader<T> {
  key: keyof T
  header: string
  type?: TableColumnType
  width?: string

  alignment?: 'start' | 'end' | 'center'
  isReactElement?: boolean
}

export interface TableProps<T extends TableData> {
  metadata: Array<TableHeader<T>>
  data: T[]
  isSearchEnabled?: boolean
  templateColumns?: string

  emptyStateHeader?: string
  emptyStateSubHeader?: string

  // Styles
  backgroundColor?: Color
  textColor?: Color
}

export default function Table<T extends TableData> ({
  metadata,
  data,
  isSearchEnabled = false,
  templateColumns,
  backgroundColor = Color.WHITE,
  textColor = Color.DARK_BLUE,
  emptyStateHeader,
  emptyStateSubHeader
}: TableProps<T>
): ReactElement {
  const [searchQuery, setSearchQuery] = useState<string | undefined>()
  const [sortSelection, setSortSelection] = useState<TableColumnSortSelection<T> | undefined>()

  function handleColumnClick (key: keyof T): void {
    const isCurrentlySelected = key === sortSelection?.key
    if (!isCurrentlySelected) {
      setSortSelection({ key, direction: SortDirection.ASC })
    } else {
      if (sortSelection.direction === SortDirection.ASC) {
        setSortSelection({ key, direction: SortDirection.DEC })
      } else {
        setSortSelection(undefined)
      }
    }
  }

  const gridHorizontalPadding = 6

  // If the table has a dropdown, we need to add a column for the dropdown icon
  const templateColumnsFormatted = getTemplateColumnsFormatted(metadata, templateColumns)

  const sortedAndFilteredData = sortAndFilterTableData(data, metadata, searchQuery, sortSelection)

  const isTableEmpty = sortedAndFilteredData.length < 1

  return (
    <Center flexDir='column' w='100%' gap={3}>
      {isSearchEnabled && <SearchBar queryValue={searchQuery} onChange={(value) => { setSearchQuery(value) }}/>}
      <Center w='100%' flexDir='column' bg={backgroundColor} pt={6} borderRadius={BorderRadius.CARD}>
        {/* HEADER */}
        <TableHeaderRow
          tableMetadata={metadata}
          templateColumns={templateColumnsFormatted}
          gridHorizontalPadding={gridHorizontalPadding}
          onColumnClick={handleColumnClick}
          sortSelection={sortSelection}
        />
        {/* DATA */}
        {!isTableEmpty
          ? <TableBody
              data={sortedAndFilteredData}
              tableMetadata={metadata}
              templateColumns={templateColumnsFormatted}
              gridHorizontalPadding={gridHorizontalPadding}
              textColor={textColor}
            />
          : <TableEmptyState
              backgroundColor={backgroundColor}
              textColor={textColor}
              heading={emptyStateHeader}
              subheading={emptyStateSubHeader}
            />
        }
      </Center>
    </Center>
  )
}

function getTemplateColumnsFormatted<T> (metadata: Array<TableHeader<T>>, templateColumns?: string): string {
  const numberOfColumns = metadata.length
  const hasDropdown = metadata.some(column => column.key === TABLE_DROPDOWN_CONTENT)
  if (templateColumns == null) {
    return hasDropdown
      ? `repeat(${numberOfColumns - 1}, minmax(0, 1fr)) 40px`
      : `repeat(${numberOfColumns}, minmax(0, 1fr))`
  }

  return hasDropdown ? `${templateColumns} 40px` : templateColumns
}
