import React, { type ReactElement } from 'react'
import { Center, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { BorderRadius, Color } from '@/theme/theme'
import { type GetBrandMetadata, type GetBrandMetadataVariables } from '@/graphql/__generated__/GetBrandMetadata'
import { GET_BRAND_METADATA } from '@/graphql/queries/brand/GetBrandMetadata'
import AltirSkeleton from '@/library/loading/AltirSkeleton'

interface BrandMetadataComponentProps {
  franchiseGroupId: number
}

export default function BrandMetadataComponent ({ franchiseGroupId }: BrandMetadataComponentProps): ReactElement {
  const { data, loading, error } = useQuery<GetBrandMetadata, GetBrandMetadataVariables>(
    GET_BRAND_METADATA,
    { variables: { franchiseGroupId } }
  )
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center
        w='100%'
        bg={Color.WHITE}
        borderRadius={BorderRadius.CARD}
        flexDir='column'
        p={6}
      >
        <Text>{data?.franchiseGroup?.brand.name}</Text>
      </Center>
    </AltirSkeleton>
  )
}
