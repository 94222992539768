import React, { type ReactElement } from 'react'
import Badge, { BadgeVariant } from './Badge'
import { TransferStatus } from '@/graphql/__generated__/globalTypes'
import { isFutureDate, parseDate } from '@/utils/dateUtils'

interface TransferStatusBadgeProps {
  status?: TransferStatus
  expectedSettlementDate: string | null
}

export default function TransferStatusBadge ({
  status,
  expectedSettlementDate
}: TransferStatusBadgeProps): ReactElement {
  if (status == null) return <></>

  const badgeProps = getBadgeProps(status, expectedSettlementDate)
  if (badgeProps == null) return <></>

  return (
    <Badge badgeVariant={badgeProps.variant} title={badgeProps.title}/>
  )
}

function getBadgeProps (
  status: TransferStatus,
  expectedSettlementDate: string | null
): { title: string, variant: BadgeVariant } | null {
  switch (status) {
    case TransferStatus.PENDING:
    case TransferStatus.CREATED:
    case TransferStatus.PROCESSING:
    case TransferStatus.AWAITING_BALANCE:
    case TransferStatus.AWAITING_ADMIN_REVIEW:
      return { title: 'Pending', variant: BadgeVariant.GREY }
    case TransferStatus.ERROR:
    case TransferStatus.UNKNOWN:
      return { title: 'Error', variant: BadgeVariant.RED }
    case TransferStatus.CANCELED:
      return { title: 'Canceled', variant: BadgeVariant.RED }
    case TransferStatus.CHECK_STOP_PAYMENT_PENDING:
      return { title: 'Stop Payment Pending', variant: BadgeVariant.GREY }
    case TransferStatus.CHECK_STOP_PAYMENT:
      return { title: 'Stop Payment Approved', variant: BadgeVariant.RED }
    case TransferStatus.CHECK_EXPIRED:
      return { title: 'Expired', variant: BadgeVariant.GREY }
    case TransferStatus.RETURNED:
    case TransferStatus.RETURN_SETTLED:
      return { title: 'Returned', variant: BadgeVariant.RED }
    case TransferStatus.CHECK_CLEARED:
    case TransferStatus.RECEIVED:
      return null
    case TransferStatus.SENT: {
      if (expectedSettlementDate == null || !isFutureDate(parseDate(expectedSettlementDate))) {
        return null
      }
      return { title: 'Pending', variant: BadgeVariant.GREY }
    }
  }
}
