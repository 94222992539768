import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Text } from '@chakra-ui/react'
import Page from '@/library/page/Page'

export default function FranchiseeRoyaltyDetailPage (): ReactElement {
  const { BUSINESS_ID: businessId } = useParams()
  return (
    <Page>
      <Text>ID: {businessId}</Text>
    </Page>
  )
}
