import React, { type ReactElement } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import TableHeaderCell from './TableHeaderCell'
import { type TableHeader } from '../../Table'

interface TableHeaderRowProps<T> {
  tableMetadata: Array<TableHeader<T>>
  templateColumns: string
  gridHorizontalPadding: number
  onColumnClick?: (key: keyof T) => void
  sortSelection?: TableColumnSortSelection<T>
}

export enum SortDirection {
  ASC = 'ASC',
  DEC = 'DEC'
}

export interface TableColumnSortSelection<T> {
  key: keyof T
  direction: SortDirection
}

export default function TableHeaderRow<T> ({
  tableMetadata,
  templateColumns,
  gridHorizontalPadding,
  onColumnClick = () => {},
  sortSelection
}: TableHeaderRowProps<T>): ReactElement {
  return (
    <Grid
      templateColumns={templateColumns}
      w='100%'
      alignItems='center'
      px={gridHorizontalPadding}
    >
      {
        tableMetadata.map((column, index) => {
          return (
            <GridItem
              key={index}
              colStart={index + 1}
              justifySelf='left'
              w='100%'
              textAlign={column.alignment ?? 'left'}
            >
              <TableHeaderCell
                column={column}
                onColumnClick={onColumnClick}
                sortSelection={sortSelection}
              />
            </GridItem>
          )
        })
    }
    </Grid>
  )
}
