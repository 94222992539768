import { type BankAccountIdMap, getFromAndToAccounts } from '@/components/pages/transactions/components/utils'
import {
  type TransactionFragment as Transaction
} from '@/graphql/__generated__/TransactionFragment'
import { Color } from '@/theme/theme'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import { getCategoryLabelFormatted, getCurrencyFormatted } from '@/utils/stringUtils'
import { getExpectedSettlementDateTime, getFormattedTransferType } from '@/utils/transferUtils'

interface TransactionFormatted {
  amountFormatted: string | null
  categoryLabelFormatted: string
  dateFormatted: string | null
  longDescription: string
  merchantFormatted: string | null
  isPending: boolean
  baseFontColor: Color
  fromAccount: string | null
  toAccount: string | null
  expectedSettlementDate: string | null
  transferType: string | null
}

export function getFormattedTransaction (
  transaction: Transaction,
  bankAccountIdMap?: BankAccountIdMap
): TransactionFormatted {
  const isPending = transaction.isPendingTransaction
  const bankAccount = transaction.bankAccountId != null
    ? bankAccountIdMap?.get(transaction.bankAccountId)
    : { institutionName: undefined, lastFour: undefined }
  const { fromAccount, toAccount } = getFromAndToAccounts(transaction, bankAccount)
  const transferType = transaction.originatingTransfer?.type
  return {
    amountFormatted: transaction?.amount != null
      ? getCurrencyFormatted(transaction?.amount, { minimumFractionDigits: 2 })
      : null,
    categoryLabelFormatted: getCategoryLabelFormatted(transaction.categoryLabel),
    dateFormatted: getFormattedDateString(transaction.date),
    longDescription: transaction.originalDescription ?? '', // TODO Ensure this field is non-null
    merchantFormatted: transaction.merchantFormatted,
    isPending,
    baseFontColor: isPending ? Color.DARK_GREY : Color.DARK_BLUE,
    fromAccount: fromAccount ?? null,
    toAccount: toAccount ?? null,
    expectedSettlementDate: getExpectedSettlementDateTime(transaction, DateTimeTemplate.MONTH_YEAR_SHORT),
    transferType: transferType != null ? getFormattedTransferType(transferType) : null
  }
}
