import { SortDirection, type TableColumnSortSelection } from '../components/header/TableHeaderRow'
import { TableColumnType, type TableData, type TableHeader } from '../Table'
import { isEmpty } from '@/utils/stringUtils'
import { dateTimeFromDateTimeTemplate, DateTimeTemplate } from '@/utils/dateUtils'

export function sortAndFilterTableData<T extends TableData> (
  data: T[],
  metadata: Array<TableHeader<T>>,
  query?: string,
  sortSelection?: TableColumnSortSelection<T>
): T[] {
  return sortTableData(filterTableData(data, query), metadata, sortSelection)
}

/**
 * Returns true if any of a given row's values match a query string
 */
function filterTableData<T extends TableData> (data: T[], query?: string): T[] {
  if (isEmpty(query) || query == null) {
    return data
  }

  return data.filter(entry => {
    if (typeof entry !== 'object' || entry == null) {
      return true
    }

    const lowerCaseQuery = query.toLowerCase()

    return Object.values(entry).some(tableData => {
      const value = tableData.sortValue ?? tableData.value
      if (typeof value === 'string' || typeof value === 'number') {
        return value.toString().toLowerCase().includes(lowerCaseQuery)
      }
      return false
    })
  })
}

function sortTableData<T extends TableData> (
  data: T[],
  metadata: Array<TableHeader<T>>,
  sortSelection?: TableColumnSortSelection<T>): T[] {
  if (sortSelection == null) return data // If no sorting is specified, return the original data

  const { key, direction } = sortSelection

  return data.sort((a, b) => {
    const columnMeta = metadata.find(meta => meta.key === key)
    if (columnMeta == null) {
      return 0
    }

    const valueA = columnMeta.isReactElement === true ? a[key]?.sortValue : a[key]?.value
    const valueB = columnMeta.isReactElement === true ? b[key]?.sortValue : b[key]?.value

    // Handle different data types (strings, numbers)
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      if (columnMeta.type === TableColumnType.DATE) {
        return dateSort(valueA, valueB, sortSelection.direction)
      }
      return direction === SortDirection.ASC
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA)
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === SortDirection.ASC ? valueA - valueB : valueB - valueA
    } else if (valueA == null && valueB != null) {
      return sortSelection.direction === SortDirection.DEC ? 1 : -1
    } else if (valueA != null && valueB == null) {
      return sortSelection.direction === SortDirection.ASC ? 1 : -1
    } else {
      return 0
    }
  })
}

function dateSort (dateStringA: string, dateStringB: string, sortDirection: SortDirection): number {
  const dateA = dateTimeFromDateTimeTemplate(dateStringA, DateTimeTemplate.MM_DD_YYYY)
  const dateB = dateTimeFromDateTimeTemplate(dateStringB, DateTimeTemplate.MM_DD_YYYY)

  // Compare dates
  if (!dateA.isValid || !dateB.isValid) {
    return 0
  }

  return sortDirection === SortDirection.ASC
    ? dateA.toMillis() - dateB.toMillis()
    : dateB.toMillis() - dateA.toMillis()
}
