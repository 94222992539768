import React, { type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import CapitalOSPromoCardComponent from './CapitalOSPromoCardComponent'
import CapitalOSManageComponent from './CapitalOSManageComponent'
import { Color } from '@/theme/theme'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { CapitalOSAccountStatus } from '@/graphql/__generated__/globalTypes'
import { GET_CAPITAL_OS_ACCOUNT_CARD_DATA } from '@/graphql/queries/capital_os/GetCapitalOSAccountCardData'
import {
  type GetCapitalOSAccountCardDataVariables,
  type GetCapitalOSAccountCardData
} from '@/graphql/__generated__/GetCapitalOSAccountCardData'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function CapitalOSAccountCardComponent (): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const {
    data,
    loading,
    error
  } = useQuery<GetCapitalOSAccountCardData, GetCapitalOSAccountCardDataVariables>(
    GET_CAPITAL_OS_ACCOUNT_CARD_DATA,
    { variables: { franchiseGroupId: selectedFranchiseGroupId } }
  )
  const status = data?.currentUser?.capitalOsConfiguration?.account?.status
  const isAccountOpen = CapitalOSAccountStatus.APPROVED === status
  return (
    <Flex flexDirection='column' width='100%' gap={3}>
      <Heading
        size='sm'
        color={Color.DARK_GREY}
      >
        Altir Cards
      </Heading>
      <AltirSkeleton isLoading={loading} error={error}>
        {isAccountOpen
          ? <CapitalOSManageComponent account={data?.currentUser?.capitalOsConfiguration?.account ?? undefined}/>
          : <CapitalOSPromoCardComponent accountStatus={status ?? undefined}/>}
      </AltirSkeleton>
    </Flex>
  )
}
