import React, { type ReactElement, type ReactNode } from 'react'
import { QuickActionBox } from './QuickActionBox'
import BusinessSelectorPopover, { type QuickActionsUrlState } from '@/components/library/nav/BusinessSelectorPopover'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface QuickActionProps {
  icon?: ReactNode
  text?: string
  link: string
  urlState?: QuickActionsUrlState
  isAmplifyRequired?: boolean
  isPopoverDisabled?: boolean
  appendBusinessIdToRoute?: boolean
}

export default function QuickAction (
  {
    icon,
    text,
    link,
    urlState,
    isAmplifyRequired = false,
    isPopoverDisabled = false,
    appendBusinessIdToRoute = false
  }: QuickActionProps
): ReactElement {
  const navigate = useAltirNavigate()
  const box = <QuickActionBox
    isPopoverDisabled={isPopoverDisabled}
    icon={icon}
    link={link}
    text={text}
    onClick={(e) => { navigate(link, e, { state: urlState }) }}
              />
  if (isPopoverDisabled) {
    return box
  }
  return (
    <BusinessSelectorPopover
      quickActionRoute={link}
      isAmplifyRequired={isAmplifyRequired}
      appendBusinessIdToRoute={appendBusinessIdToRoute}
      urlState={urlState}
    >
      {box}
    </BusinessSelectorPopover>
  )
}
