import React, { type ReactElement } from 'react'
import { Center, Divider, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react'
import { useApolloClient } from '@apollo/client'
import TransferEtaComponent from './TransferEtaComponent'
import TransferFeeLineItem from '../confirmation_modal/TransferFeeLineItem'
import { ROUTES } from '@/api/routes'
import RacingFlagAnimation from '@/library/animations/RacingFlagAnimation'
import Button from '@/library/button/Button'
import { BorderRadius, Color } from '@/theme/theme'
import { getCounterpartyLongName } from '@/utils/financialAccountUtils'
import { type TransferType } from '@/graphql/__generated__/globalTypes'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import {
  getTransferComponentContent,
  getFormattedTransferType
} from '@/utils/transferUtils'
import { useScrollToTop } from '@/hooks/useScrollToTop'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface TransferSuccessComponentProps {
  amount: number
  toAccount?: FinancialAccountExtendedFragment
  fromAccount?: FinancialAccountExtendedFragment
  transferType: TransferType
}

export function TransferSuccessComponent (
  {
    amount,
    toAccount,
    fromAccount,
    transferType
  }: TransferSuccessComponentProps
): ReactElement {
  const client = useApolloClient()
  function refetchQueries (): void {
    void client.refetchQueries({ include: 'active' })
  }
  const navigate = useAltirNavigate()
  useScrollToTop({ behavior: 'smooth' })

  const { transferTotal, transferFee } = getTransferComponentContent(amount, transferType)
  const amountFormattedString = getCurrencyFormatted(Number(amount), { minimumFractionDigits: 2 })
  const totalFormattedString = getCurrencyFormatted(Number(transferTotal), { minimumFractionDigits: 2 })

  return (
    <Center
      width='100%'
      flexDirection='column'
      justifyContent='start'
      backgroundColor={Color.WHITE}
      borderRadius={BorderRadius.CARD}
      overflowY='auto'
      p={12}
    >
      <Center flexDirection='column' gap={6}>
        {/* Awkward padding because animation moves around its box */}
        <Flex mb={-8} mt={-14}>
          <RacingFlagAnimation size='250px'/>
        </Flex>
        <Heading color={Color.DARK_BLUE} size='xl'>Transfer Initiated</Heading>
        <TransferEtaComponent transferType={transferType}/>
        <Grid
          width='100%'
          gap={2}
          templateColumns='repeat(2, minmax(0, 1fr))'
          backgroundColor={Color.WHITE}
          borderRadius={BorderRadius.CARD}
        >
          <GridItem justifySelf='start'>
            <Text>From</Text>
          </GridItem>
          <GridItem justifySelf='end'>
            <Text
              color={Color.DARK_BLUE}
            >
              {getCounterpartyLongName(fromAccount)} ***{fromAccount?.lastFour}
            </Text>
          </GridItem>
          <GridItem justifySelf='start'>
            <Text>To</Text>
          </GridItem>
          <GridItem justifySelf='end'>
            <Text
              color={Color.DARK_BLUE}
              textAlign='end'
            >
              {getCounterpartyLongName(toAccount)} ***{toAccount?.lastFour}
            </Text>
          </GridItem>
          <GridItem justifySelf='start'>
            <Text color={Color.DARK_GREY}>
              Method
            </Text>
          </GridItem>
          <GridItem justifySelf='end'>
            <Text color={Color.DARK_BLUE}>{getFormattedTransferType(transferType)}</Text></GridItem>
          <GridItem justifySelf='start'>
            <Text color={Color.DARK_GREY}>Amount</Text>
          </GridItem>
          <GridItem justifySelf='end'>
            <Text color={Color.DARK_BLUE}>{amountFormattedString}</Text></GridItem>
          <GridItem justifySelf='start'>
            <Text color={Color.DARK_GREY}>Fee</Text>
          </GridItem>
          <TransferFeeLineItem transferFee={transferFee}/>
          <GridItem colStart={1} colEnd={3}>
            <Divider my={2} color={Color.GREY}/>
          </GridItem>
          <GridItem justifySelf='start'>
            <Text color={Color.DARK_GREY}>
              Total Withdrawn
            </Text>
          </GridItem>
          <GridItem justifySelf='end'>
            <Text color={Color.DARK_BLUE}>{totalFormattedString}</Text></GridItem>
        </Grid>
        <Button
          text='Done'
          onClick={(e) => {
            refetchQueries()
            navigate(ROUTES.TRANSFER_HISTORY, e)
          }}
        />
      </Center>
    </Center>
  )
}
