import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import AmplifyAccountPromoCard from './AmplifyAccountPromoCard'
import ActiveAmplifyAccountCard from './ActiveAmplifyAccountCard'
import {
  type GetAmplifyAccountCardDataVariables,
  type GetAmplifyAccountCardData
} from '@/graphql/__generated__/GetAmplifyAccountCardData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_AMPLIFY_ACCOUNT_CARD_DATA } from '@/graphql/queries/amplify_account/GetAmplifyAccountCardData'
import { AuthAction, hasAccess } from '@/utils/authUtils'
import { AuthResource } from '@/graphql/__generated__/globalTypes'

export interface AmplifyAccountCardComponentProps {
  organizationId: string
  franchiseGroupId: number
  totalBalance?: number
}

export default function AmplifyAccountCardComponent (
  { organizationId, franchiseGroupId, totalBalance }: AmplifyAccountCardComponentProps
): ReactElement {
  const {
    data,
    error,
    loading
  } = useQuery<GetAmplifyAccountCardData, GetAmplifyAccountCardDataVariables>(
    GET_AMPLIFY_ACCOUNT_CARD_DATA,
    {
      variables: {
        organizationId,
        franchiseGroupId
      }
    }
  )

  const amplifyAccounts = data?.amplifyAccounts ?? []
  const doesAmplifyAccountExist = amplifyAccounts.length > 0

  // Authorization Checks
  const canTransfer = hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, data?.currentUser?.authorization ?? [])

  // TODO (PJ): Think through this
  return (
    <Flex flexDirection='column' width='100%' gap={3}>
      <Text>Altir Accounts</Text>
      <AltirSkeleton isLoading={loading} error={error}>
        {
          doesAmplifyAccountExist
            ? <Flex flexDir='column' gap={4}>
              {amplifyAccounts.map((amplifyAccount, i) => {
                return (
                  <ActiveAmplifyAccountCard
                    key={i}
                    amplifyAccount={amplifyAccount}
                    subscriptionLevel={data?.currentUser?.selectedOrganization?.subscriptionLevel}
                    hasTransferPermission={canTransfer}
                  />
                )
              })}
            </Flex>
            : <AmplifyAccountPromoCard data={data} totalBalance={totalBalance}/>
        }
      </AltirSkeleton>
    </Flex>
  )
}
