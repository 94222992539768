import React, { useState, type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { Navigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import OnboardingBusinessDropDownComponent from './components/OnboardingBusinessDropDownComponent'
import OnboardingPage from '@/library/page/OnboardingPage'
import Button, { ButtonVariant } from '@/library/button/Button'
import PlusIcon from '@/library/icons/PlusIcon'
import { ContainerWidth, IconSize } from '@/theme/theme'
import { ROUTES } from '@/api/routes'
import {
  type UpdateOrganizationOnboardingState,
  type UpdateOrganizationOnboardingStateVariables
} from '@/graphql/__generated__/UpdateOrganizationOnboardingState'
import { UPDATE_ORGANIZATION_ONBOARDING_STATE } from '@/graphql/mutations/onboarding/UpdateOrganizationOnboardingState'
import ErrorInline from '@/library/errors/ErrorInline'
import { type GetOnboardingBusinessData } from '@/graphql/__generated__/GetOnboardingBusinessData'
import { GET_ONBOARDING_BUSINESS_DATA } from '@/graphql/queries/onboarding/GetOnboardingBusinessData'
import { type ErrorWithContent } from '@/types/types'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function OnboardingBusinessPage (): ReactElement {
  const navigate = useAltirNavigate()
  const [organizationError, setOrganizationError] = useState<Error | undefined>()
  const [submissionError, setSubmissionError] = useState<ErrorWithContent>()

  const {
    data,
    error,
    loading,
    refetch
  } = useQuery<GetOnboardingBusinessData>(
    GET_ONBOARDING_BUSINESS_DATA,
    { fetchPolicy: 'network-only' }
  )

  const [
    completeBusinessOnboarding,
    { loading: completeBusinessOnboardingLoading }
  ] = useMutation<UpdateOrganizationOnboardingState, UpdateOrganizationOnboardingStateVariables>(
    UPDATE_ORGANIZATION_ONBOARDING_STATE,
    {
      // TODO (PJ): Pass organizationId once we receive from auth context
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP_AMPLIFY_INTRO, null) },
      onError: (error) => {
        setSubmissionError({
          customContent: {
            title: 'Something went wrong',
            subtitle: 'Unable to create businesses. Please try again or contact support.'
          },
          error
        })
      }
    }
  )

  function onContinue (): void {
    const organizationId = data?.currentUser?.selectedOrganization?.id
    if (organizationId == null) {
      setOrganizationError(Error('Organization id is null.'))
      return
    }
    void completeBusinessOnboarding({ variables: { organizationId, isBusinessOnboardingComplete: true } })
  }

  const businesses = data?.currentUser?.selectedOrganization?.franchiseGroups ?? []

  if (!loading && error == null && businesses.length < 1) {
    return <Navigate to={ROUTES.V2_SIGN_UP_BUSINESS_INTRO}/>
  }

  return (
    <OnboardingPage maxWidth={ContainerWidth.EXTRA_LARGE} isLoading={loading} error={error}>
      <Center flexDir='column' gap={1} textAlign='center'>
        <Heading>Add one or more businesses</Heading>
        <Text>
          Once you are done adding all your businesses, continue. You’ll be able to edit this list at any time.
        </Text>
      </Center>
      <Center flexDir='column' gap={2} w='100%'>
        {
          businesses.map((business, index) => {
            return (
              <OnboardingBusinessDropDownComponent key={index} business={business} refetchBusinessData={refetch}/>
            )
          })
        }
      </Center>
      <Center flexDir='column' gap={4} w='100%'>
        <Button
          text='Add a business'
          beforeIcon={<PlusIcon size={IconSize.SMALL}/>}
          variant={ButtonVariant.WHITE}
          onClick={() => { navigate(ROUTES.V2_SIGN_UP_BUSINESS_CREATE, null) }}
        />
        <Button
          text='Continue'
          isDisabled={businesses.length < 1}
          onClick={onContinue}
          isLoading={completeBusinessOnboardingLoading}
        />
        <ErrorInline error={submissionError ?? organizationError}/>
      </Center>
    </OnboardingPage>
  )
}
