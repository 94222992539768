import React, { useState, type ReactElement } from 'react'
import { Text, Flex, Heading, Modal, ModalBody, ModalContent, ModalOverlay, Box } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { Event } from 'metrics/metrics'
import { ROUTES } from '@/api/routes'
import Button, { ButtonVariant } from '@/library/button/Button'
import { Graphic } from '@/library/utility/Graphic'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import Footnote from '@/library/legal/Footnote'
import { FDIC_PILLAR_GRAPHIC } from '@/library/theme/graphics'
import CloseIcon from '@/library/icons/CloseIcon'
import { GET_AMPLIFY_PROMOTIONAL_DATA } from '@/graphql/queries/GetAmplifyPromotionalData'
import { type GetAmplifyPromotionalData } from '@/graphql/__generated__/GetAmplifyPromotionalData'
import {
  BorderRadius,
  Color,
  FontWeight
} from '@/theme/theme'
import { DisclosureText } from '@/library/legal/disclosureText'
import { getPercentageFormatted } from '@/utils/stringUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export function FDICAmplifyInfoModal (): ReactElement {
  const navigate = useAltirNavigate()
  const [isOpen, setIsOpen] = useState(true)
  const {
    data,
    loading,
    error
  } = useQuery<GetAmplifyPromotionalData>(GET_AMPLIFY_PROMOTIONAL_DATA)

  function onClose (): void {
    setIsOpen(false)
  }

  const potentialYield = getPercentageFormatted(
    data?.amplifyAccountPromotionalOffer?.subscribedAnnualPercentageYield ?? null
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='4xl'
      isCentered={true}
      preserveScrollBarGap
    >
      <ModalOverlay/>
      <ModalContent borderRadius={BorderRadius.CARD}>
        <Flex>
          <ModalBody
            justifyContent='center'
            alignItems='center'
            display='flex'
            borderRadius={BorderRadius.CARD}
            bg={Color.WHITE}
          >
            <Graphic src={FDIC_PILLAR_GRAPHIC} h='288px' w='auto'/>
          </ModalBody>
          <ModalBody
            py={6}
            bg={Color.LIGHT_GREY}
            borderRightRadius={BorderRadius.CARD}
          >
            <AltirSkeleton isLoading={loading} error={error}>
              <Flex flexDirection='column'>
                <Flex justifyContent='end' alignItems='end'>
                  <Flex _hover={{ cursor: 'pointer' }} onClick={onClose}>
                    <CloseIcon color={Color.DARK_GREY}/>
                  </Flex>
                </Flex>
                <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  textAlign='center'
                  pt={2}
                  pb={2}
                  px={6}
                >
                  <Heading
                    size='lg'
                    fontWeight={FontWeight.MEDIUM}
                    color={Color.DARK_BLUE}
                    mb={2}
                  >
                    A safe way to <br/> grow your money.
                  </Heading>
                  <Text>
                    Through our banking partner,
                    Altir offers you access to a depository account that earns up to {potentialYield}{' '}
                    Annual Percentage Yield (APY).
                    Your deposits are backed by FDIC insurance up to $100 million.<Footnote number={1}/>
                  </Text>
                  <Box
                    height='150px'
                    overflowY='auto'
                    bg={Color.GREY}
                    py={4}
                    px={5}
                    my={8}
                    textAlign='start'
                    borderRadius={BorderRadius.CARD}
                  >
                    <Text fontSize='xs' color={Color.DARK_BLUE} pb={2}>
                      <Footnote number={1}/>{DisclosureText.ALTIR_IS_NOT_A_BANK}
                    </Text>
                    <Text fontSize='xs' color={Color.DARK_BLUE}>
                      {DisclosureText.INTRAFI_DISCLAIMER}
                    </Text>
                  </Box>
                  <Flex width='100%' px={8} mt={4}>
                    <Button
                      text="Let's Get Started"
                      onClick={(e) => { navigate(ROUTES.AMPLIFY_APP_APPLY, e) }}
                      variant={ButtonVariant.PRIMARY}
                      onClickEventType={Event.START_AMPLIFY_APPLICATION_CLICK}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </AltirSkeleton>
          </ModalBody>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
