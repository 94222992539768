import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_TRANSACTIONS } from '@/graphql/queries/transactions/GetTransactions'
import { type GetTransactions, type GetTransactionsVariables } from '@/graphql/__generated__/GetTransactions'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import TransactionsWithOriginatingTransferTable from '@/library/transactions/TransactionsWithOriginatingTransferTable'

interface RecipientTransactionsComponentProps {
  counterpartyId: string
}

export default function RecipientTransactionsComponent ({
  counterpartyId
}: RecipientTransactionsComponentProps): ReactElement {
  const organizationId = useAltirStore(store => store.selectedOrganizationState.selectedOrganizationId)
  const { data, loading, error } = useQuery<GetTransactions, GetTransactionsVariables>(
    GET_TRANSACTIONS,
    { variables: { organizationId, filter: { counterpartyIds: [counterpartyId] } } }
  )
  const transactions = data?.currentUser?.selectedOrganization?.transactions.transactions ?? []
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <TransactionsWithOriginatingTransferTable
        transactions={transactions}
        emptyStateSubHeader="Looks like you haven't paid this recipient yet."
      />
    </AltirSkeleton>
  )
}
