import React, { type ReactElement } from 'react'
import { Text } from '@chakra-ui/react'
import {
  type GetBrandFranchisees_franchiseGroup_brand_franchisees as Franchisee
} from '@/graphql/__generated__/GetBrandFranchisees'
import { Color } from '@/theme/theme'
import useAltirNavigate from '@/hooks/useAltirNavigate'
import { routeWithParams } from '@/utils/routerUtils'
import { RouteParam, ROUTES } from '@/api/routes'

interface FranchiseeNameCellProps {
  franchisee: Franchisee
}

export default function FranchiseeNameCell ({ franchisee }: FranchiseeNameCellProps): ReactElement {
  const navigate = useAltirNavigate()
  const url = routeWithParams(
    ROUTES.FRANCHISOR_ROYALTY_DETAIL,
    [{ param: RouteParam.BUSINESS_ID, value: franchisee.id }]
  )
  return (
    <Text
      color={Color.DARK_BLUE}
      _hover={{ cursor: 'pointer', color: Color.BRIGHT_BLUE_HOVER }}
      onClick={(e) => { navigate(url, e) }}
      noOfLines={1}
    >
      {franchisee.name}
    </Text>
  )
}
