import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { type GetTransferRule_transferRule as TransferRule } from '@/graphql/__generated__/GetTransferRule'
import { getFormattedBalanceRule } from '@/utils/transferRuleUtils'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'
import { getFormattedDateString } from '@/utils/dateUtils'
import RecurringRuleBalanceIcon from '@/library/icons/RecurringRuleBalanceIcon'
import { Color, IconSize } from '@/theme/theme'

interface AutomatedTransferRuleBalanceMetadataProps {
  rule: TransferRule
}

export default function AutomatedTransferRuleBalanceMetadata ({
  rule
}: AutomatedTransferRuleBalanceMetadataProps): ReactElement {
  const { threshold, sentenceDescription, executionDays } = getFormattedBalanceRule(rule)
  const createdAt = getFormattedDateString(rule.createdAt)
  return (
    <Flex flexDir='column' w='100%' gap={4}>
      <StackedInfoAttribute
        header='Type'
        subElement={
          <Flex alignItems='center' gap={1}>
            <RecurringRuleBalanceIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>
            <Text>Balance-Based</Text>
          </Flex>
        }
      />
      <StackedInfoAttribute header='Threshold' subElement={<Heading size='md'>{threshold}</Heading>}/>
      <StackedInfoAttribute header='Created' subtext={createdAt ?? ''}/>
      <StackedInfoAttribute header='Runs On' subtext={executionDays}/>
      <StackedInfoAttribute
        header='Description'
        subtext={sentenceDescription}
        allowMultiLineSubtext={true}
      />
    </Flex>
  )
}
