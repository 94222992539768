import React, { type ReactElement } from 'react'
import { Box, Flex, Text, Heading, Center } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { Event } from 'metrics/metrics'
import { BorderRadius, Color, Gradient } from '@/library/theme/theme'
import { Graphic } from '@/library/utility/Graphic'
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@/library/theme/urls'
import AltirProFeatureRow from '@/library/pro/AltirProFeatureRow'
import Button, { ButtonVariant } from '@/library/button/Button'
import AltirProFeatureBox from '@/library/pro/AltirProFeatureBox'
import NotABankDisclosure from '@/library/legal/NotABankDisclosure'
import { type CustomThemeType, getCustomTheme, getCustomThemeType, isDistributionPartner } from '@/theme/customThemes'
import { getQueryParamValue } from '@/utils/routerUtils'
import { useLoginWithPopup } from '@/hooks/auth/useLoginWithPopup'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import HelpCenterPopoverComponent from '@/library/nav/help_center/HelpCenterPopoverComponent'
import LinkText from '@/library/text/LinkText'
import { V2_ALTIR_LOGO_WITH_BLUE_BOUNDING_BOX } from '@/library/theme/graphics'
interface LoginBoxContent {
  header: string
  subHeader: string
  featureDescription: string
}

export default function LoginPage (): ReactElement {
  const location = useLocation()
  const { loginWithPopUp, isPopupOpen, isLoading } = useLoginWithPopup()

  const themeType = getCustomThemeType(getQueryParamValue(location, 'referrer'))
  const theme = getCustomTheme(themeType)
  const boxContent = getContent(themeType)

  return (
    <AltirSkeleton isLoading={isLoading}>
      <Center
        h='100%'
        w='100%'
        minHeight='100vh'
        opacity={isPopupOpen ? 0.3 : 1}
        bg={Gradient.BLUE_ORB}
      >
        {/* Top Logo */}
        <Flex
          position='fixed'
          top={0}
          left={0}
          w='100%'
          justifyContent='start'
          alignItems='center'
          p={10}
          gap={5}
        >
          <Graphic src={theme.logo.url} h={theme.logo.height}/>
          {
            theme.altirLogoUrl != null &&
              <Flex gap={5} alignItems='center' h='100%'>
                <Box bg={Color.DARK_GREY} h='100%' w='1px'/>
                <Graphic src={theme.altirLogoUrl} h='24px'/>
              </Flex>
          }
        </Flex>
        {/* Login Content */}
        <Center w='100%'>
          <Center
            flexDir='column'
            bg={Color.WHITE}
            borderRadius={BorderRadius.CARD}
            p={8}
            gap={6}
            boxShadow='0px 0px 70px 0px rgba(0, 0, 0, 0.10)'
          >
            <Graphic src={V2_ALTIR_LOGO_WITH_BLUE_BOUNDING_BOX} h='70px' borderRadius={BorderRadius.CARD}/>
            <Flex flexDir='column' alignItems='center' gap={1} textAlign='center'>
              <Heading color={Color.DARK_BLUE}>{boxContent.header}</Heading>
              <Text maxW={96}>{boxContent.subHeader}</Text>
            </Flex>
            <Flex flexDir='column' w='100%' alignItems='center' gap={2}>
              <Text color={Color.DARK_BLUE}>{boxContent.featureDescription}</Text>
              <AltirProFeatureBox color={Color.DARK_BLUE}>
                <AltirProFeatureRow color={Color.DARK_BLUE} text='High-yield business checking'/>
                <AltirProFeatureRow color={Color.DARK_BLUE} text='Simplified cash consolidation'/>
                <AltirProFeatureRow color={Color.DARK_BLUE} text='Capital to scale your business'/>
              </AltirProFeatureBox>
            </Flex>
            <Flex flexDir='column' alignItems='center' gap={4} width='360px'>
              <Button
                onClick={loginWithPopUp}
                text='Log In'
                onClickEventType={Event.LOGIN_CLICK}
                customTheme={theme}
              />
              <Button
                onClick={loginWithPopUp}
                text='Sign Up'
                variant={ButtonVariant.WHITE_OUTLINE}
                onClickEventType={Event.SIGN_UP_CLICK}
              />
            </Flex>
            <Flex flexDir='column'>
              <Text textAlign='center' fontSize='sm'>By continuing, you agree to our<br/>
                <LinkText text='Terms of Service' href={TERMS_OF_SERVICE_URL} fontSize='sm'/>
                {' '}and{' '}
                <LinkText text='Privacy Policy' href={PRIVACY_POLICY_URL} fontSize='sm'/>.
              </Text>
            </Flex>
            <NotABankDisclosure/>
          </Center>
        </Center>
        <HelpCenterPopoverComponent/>
      </Center>
    </AltirSkeleton>
  )
}

function getContent (themeType: CustomThemeType): LoginBoxContent {
  if (isDistributionPartner(themeType)) {
    return {
      header: 'Welcome',
      subHeader: 'All your transactions in one place. No more downloading multiple bank statements.',
      featureDescription: 'We provide you access to:'
    }
  }

  return {
    header: 'Welcome to Altir',
    subHeader: 'Earn, monitor, and grow.',
    featureDescription: 'Altir provides you access to:'
  }
}
