import React, { type ReactElement } from 'react'
import { Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import BalanceRuleConfirmationCard from './BalanceRuleConfirmationCard'
import TimeRuleConfirmationCard from './TimeRuleConfirmationCard'
import { Color } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import { DELETE_TRANSFER_RULE } from '@/graphql/mutations/DeleteTransferRule'
import { type DeleteTransferRule, type DeleteTransferRuleVariables } from '@/graphql/__generated__/DeleteTransferRule'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { getFormattedBalanceRule, getFormattedTimeRule, isBalanceRule } from '@/utils/transferRuleUtils'
import { type ReactivateTransferRule, type ReactivateTransferRuleVariables }
  from '@/graphql/__generated__/ReactivateTransferRule'
import { REACTIVATE_TRANSFER_RULE } from '@/graphql/mutations/ReactivateTransferRule'
import { TransferRuleType } from '@/graphql/__generated__/globalTypes'
import { type GetTransferRule, type GetTransferRuleVariables } from '@/graphql/__generated__/GetTransferRule'
import { GET_TRANSFER_RULE } from '@/graphql/queries/transfer_rules/GetTransferRule'
import AltirSkeleton from '@/library/loading/AltirSkeleton'

export enum ActivationDirection {
  ReActivate = 'Re-Activate',
  Deactivate = 'Deactivate'
}

export interface TransferRuleActivationModalProps {
  transferRuleId: string
  isModalOpen: boolean
  onModalClose: () => void
  onUpdate: () => void
  activationDirection: ActivationDirection
}

export default function TransferRuleActivationModal (
  { transferRuleId, isModalOpen, onModalClose, onUpdate, activationDirection }: TransferRuleActivationModalProps
): ReactElement {
  const toast = useToast()
  const [
    deleteTransferMutation,
    { loading: deactivateLoading }
  ] = useMutation<DeleteTransferRule, DeleteTransferRuleVariables>(DELETE_TRANSFER_RULE, {
    onCompleted: (_data) => {
      onUpdate()
      toast(getSuccessToast('Successfully deactivated transfer rule'))
      onModalClose()
    },
    onError: (_error) => {
      toast(getErrorToast('Something went wrong. Please try again.'))
      onModalClose()
    }
  })

  const [
    reactivateTransferMutation,
    { loading: reactivateLoading }
  ] = useMutation<ReactivateTransferRule, ReactivateTransferRuleVariables>(REACTIVATE_TRANSFER_RULE, {
    onCompleted: (_data) => {
      onUpdate()
      toast(getSuccessToast('Successfully reactivated transfer rule'))
      onModalClose()
    },
    onError: (_error) => {
      toast(getErrorToast('Something went wrong. Please try again.'))
      onModalClose()
    }
  })

  const { data, loading, error } = useQuery<GetTransferRule, GetTransferRuleVariables>(GET_TRANSFER_RULE, {
    variables: {
      id: transferRuleId
    }
  })

  function onClick (): void {
    if (activationDirection === ActivationDirection.Deactivate) {
      void deleteTransferMutation({ variables: { transferRuleId } })
    } else {
      void reactivateTransferMutation({ variables: { transferRuleId } })
    }
  }

  const transferRule = data?.transferRule

  const formattedBalanceRule = transferRule != null && isBalanceRule(transferRule.transferRuleType)
    ? getFormattedBalanceRule(transferRule)
    : undefined

  const formattedTimeRule = transferRule != null && !isBalanceRule(transferRule.transferRuleType)
    ? getFormattedTimeRule(transferRule)
    : undefined

  return (
    <ModalComponent
      isOpen={isModalOpen}
      onClose={onModalClose}
    >
      <AltirSkeleton isLoading={loading} error={error}>
        <Flex flexDir='column' w='100%' gap={6}>
          <Heading textAlign='center'
            size='lg'
            color={Color.DARK_BLUE}
            whiteSpace='pre-line'
          >
            {`Are you sure you want to\n${activationDirection} this rule?`}
          </Heading>
          <Text textAlign='center'>
            Note that all in-flight transfers will still be executed.
          </Text>
          {
            formattedBalanceRule != null && transferRule != null &&
            (
              transferRule.transferRuleType === TransferRuleType.MIN_TARGET_BALANCE ||
              transferRule.transferRuleType === TransferRuleType.TARGET_BALANCE
            ) &&
              <BalanceRuleConfirmationCard
                to={formattedBalanceRule.to}
                from={formattedBalanceRule.from}
                balanceThreshold={formattedBalanceRule.balance}
                type={transferRule.transferRuleType}
              />
          }
          {
            formattedTimeRule != null && transferRule != null &&
              <TimeRuleConfirmationCard
                to={formattedTimeRule.to}
                from={formattedTimeRule.from}
                frequency={transferRule.transferRuleType}
                amount={formattedTimeRule.amount}
                dayOfWeek={formattedTimeRule.dayOfWeek}
                dateOfMonth={formattedTimeRule.dateOfMonth}
                startDate={formattedTimeRule.ruleStartDate}
              />
          }
          <Flex gap={3} direction='column'>
            <Button
              text={`${activationDirection} Rule`}
              variant={
              activationDirection === ActivationDirection.Deactivate
                ? ButtonVariant.DESTRUCTIVE
                : ButtonVariant.PRIMARY
            }
              onClick={onClick}
              isLoading={deactivateLoading || reactivateLoading}
            />
            <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={() => { onModalClose() }}/>
          </Flex>
        </Flex>
      </AltirSkeleton>
    </ModalComponent>
  )
}
