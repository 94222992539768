import React, { type ReactElement } from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import { Graphic } from '../../../library/utility/Graphic'
import DefaultCard from '@/library/card/DefaultCard'
import Button from '@/library/button/Button'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import useAltirNavigate from '@/hooks/useAltirNavigate'

interface NextStepsSectionProps {
  isLoading: boolean
  icon: string
  text: string
  buttonText: string
  buttonLink: string
}

export default function NextStepsCard (
  {
    isLoading,
    icon,
    text,
    buttonText,
    buttonLink
  }: NextStepsSectionProps): ReactElement {
  const navigate = useAltirNavigate()
  return (
    <AltirSkeleton isLoading={isLoading}>
      <Flex direction='column' align='center' position='relative' mt='40px'>
        {/* This box serves as a shadow card behind the `DefaultCard`
            to give a stack effect, hence the use of absolute position and z-index
          */}
        <Box
          w='100%'
          h='100%'
          bg={Color.GREY}
          borderRadius={BorderRadius.CARD}
          position='absolute'
          top='-20px'
          right='-20px'
          zIndex={0}
        />
        <Box w='full' position='relative' zIndex={1} borderRadius={BorderRadius.CARD} overflow='hidden'>
          <DefaultCard >
            <Flex flexDir='column' gap={5} w='100%'>
              <Flex flexDir='row' gap={2} alignItems='center' textAlign='start' w='100%'>
                <Graphic src={icon} h='50px' />
                <Text color={Color.DARK_BLUE}>{text}</Text>
              </Flex>
              <Button
                text={buttonText}
                afterIcon={<RightArrowIcon size={IconSize.SMALL} />}
                onClick={(e) => { navigate(buttonLink, e) }}
              />
            </Flex>
          </DefaultCard>
        </Box>
      </Flex>
    </AltirSkeleton>
  )
}
