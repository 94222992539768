import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import {
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
}
  from '@/graphql/__generated__/GetTransferRules'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import {
  DateTimeTemplate,
  getFormattedDateString,
  getFormattedTimeString,
  isFutureDate,
  parseDate,
  TimeZone
} from '@/utils/dateUtils'
import { Color } from '@/theme/theme'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'

interface TransferRuleRecentTransferComponentProps {
  rule: TransferRule
}

export default function TransferRuleRecentTransferComponent ({
  rule
}: TransferRuleRecentTransferComponentProps): ReactElement {
  const mostRecentTransfer = rule.mostRecentTransfer
  if (mostRecentTransfer == null) {
    return <></>
  }

  const amount = getCurrencyFormatted(mostRecentTransfer.amount.amount)
  const createdAt = getFormattedDateString(mostRecentTransfer.createdAt, DateTimeTemplate.MM_DD_YY_SLASH)
  const isPending = isFutureDate(
    parseDate(mostRecentTransfer.estimatedSettlementDate, TimeZone.DEFAULT_USER_TIME_ZONE)
  )
  const settlementDateFormatted = getFormattedDateString(
    mostRecentTransfer.estimatedSettlementDate, DateTimeTemplate.MM_DD_YY_SLASH
  )
  const settlementTime = getFormattedTimeString(mostRecentTransfer.estimatedSettlementDate)
  return (
    <Flex flexDir='column' gap={3} w='auto'>
      <Text fontSize='sm'>Most Recent Transfer</Text>
      <Flex borderLeft={`2px solid ${Color.GREY}`} pl={4} w='auto' gap={6}>
        <Flex>
          <StackedInfoAttribute
            header='Amount'
            subtext={amount}
          />
        </Flex>
        <Flex>
          <StackedInfoAttribute
            header='Date'
            subtext={createdAt ?? ''}
          />
        </Flex>
        {
            isPending && settlementDateFormatted != null &&
            <Flex>
              <StackedInfoAttribute
                header='Expected Settlement'
                subtext={`${settlementDateFormatted} ${settlementTime ?? ''}`}
              />
            </Flex>
        }
      </Flex>
    </Flex>
  )
}
