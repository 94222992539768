import { gql } from '@apollo/client'

export const GET_BRAND_FRANCHISEES = gql`
    query GetBrandFranchisees($franchiseGroupId: Int!) {
        franchiseGroup(franchiseGroupId: $franchiseGroupId) {
            brand {
                id
                franchisees(selectedFranchiseGroupId: $franchiseGroupId) {
                    id
                    name
                    debitAuthorizationAgreements(originatorId: $franchiseGroupId) {
                        id
                        status
                    }
                }
            }
        }
    }
`
