import React, { useEffect, type ReactElement } from 'react'
import { Outlet } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { GET_TRANSFER_CONTEXT } from '@/graphql/queries/transfer/GetTransferContext'
import { type GetTransferContext, type GetTransferContextVariables } from '@/graphql/__generated__/GetTransferContext'
import { filterBusinesses } from '@/library/state/utils'
import { ROUTES } from '@/api/routes'
import { getErrorToast } from '@/utils/toastUtils'
import Page from '@/library/page/Page'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function TransferContextLayout (): ReactElement {
  const navigate = useAltirNavigate()
  const toast = useToast()
  const {
    selectedFranchiseGroupId,
    setSelectedFranchiseGroupId,
    selectedOrganizationId
  } = useAltirStore((state) => {
    return {
      selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId,
      setSelectedFranchiseGroupId: state.setSelectedFranchiseGroupId,
      selectedFranchiseGroupId: state.selectedFranchiseGroupId
    }
  })

  const { data, loading, error } = useQuery<GetTransferContext, GetTransferContextVariables>(
    GET_TRANSFER_CONTEXT,
    {
      variables: { organizationId: selectedOrganizationId },
      fetchPolicy: 'network-only'
    }
  )

  useEffect(() => {
    if (data == null) {
      return
    }

    // If no available franchise groups have an Amplify Account, the Transfer Page cannot be viewed
    const franchiseGroupsWithAmplify = filterBusinesses(
      data?.currentUser?.selectedOrganization?.franchiseGroups ?? [], { hasAmplify: true }
    )
    if (franchiseGroupsWithAmplify.length === 0) {
      toast(getErrorToast('Something went wrong. Unable to navigate to Transfer Page.'))
      navigate(ROUTES.HOME, null)
      return
    }

    // If selected franchise group does not have an Amplify Account, switch to one that does
    const selectedFranchiseGroupIdDoesNotHaveAmplifyAccount = franchiseGroupsWithAmplify.every(franchiseGroup => {
      return Number(franchiseGroup.id) !== selectedFranchiseGroupId
    })
    if (selectedFranchiseGroupIdDoesNotHaveAmplifyAccount) {
      setSelectedFranchiseGroupId(Number(franchiseGroupsWithAmplify[0]?.id))
    }
  }, [data, selectedFranchiseGroupId, setSelectedFranchiseGroupId, toast, navigate])

  return (
    <Page>
      <AltirSkeleton isLoading={loading} error={error}>
        <Outlet/>
      </AltirSkeleton>
    </Page>
  )
}
