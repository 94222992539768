import { gql } from '@apollo/client'
import { ADDRESS_FRAGMENT } from '../AddressFragment'

export const COUNTERPARTY_FRAGMENT = gql`
    ${ADDRESS_FRAGMENT}
    fragment CounterpartyFragment on Counterparty {
        treasuryPrimeId
        name
        isOwnershipVerified
        plaidProcessorToken
        counterpartyType
        nickname
        nameOnAccount
        achAccountNumber
        achRoutingNumber
        wireAccountNumber
        wireRoutingNumber
        checkRecipientName
        checkRecipientAddress {
            ...AddressFragment
        }
    }
`
