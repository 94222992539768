import React, { useState, type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { Navigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import OnboardingPage from '@/library/page/OnboardingPage'
import Button from '@/library/button/Button'
import { Graphic } from '@/library/utility/Graphic'
import { HANDHOLDING_PEOPLE_HEART } from '@/theme/graphics'
import { ROUTES } from '@/api/routes'
import { UPDATE_USER_ONBOARDING_STATE } from '@/graphql/mutations/onboarding/UpdateUserOnboardingState'
import {
  type UpdateUserOnboardingState,
  type UpdateUserOnboardingStateVariables
} from '@/graphql/__generated__/UpdateUserOnboardingState'
import ErrorInline from '@/library/errors/ErrorInline'
import {
  GET_ONBOARDING_ORGANIZATION
} from '@/graphql/queries/onboarding/GetOnboardingOrganization'
import { type GetOnboardingOrganization } from '@/graphql/__generated__/GetOnboardingOrganization'
import { ContainerWidth } from '@/theme/theme'
import { type ErrorWithContent } from '@/types/types'
import { ErrorCopy } from '@/utils/errorUtils'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export default function OnboardingOrganizationAcceptInvitePage (): ReactElement {
  const navigate = useAltirNavigate()
  const [
    completeOrganizationAssociationError,
    setCompleteOrganizationAssociationError
  ] = useState<ErrorWithContent | undefined>()
  const {
    data,
    loading,
    error
  } = useQuery<GetOnboardingOrganization>(
    GET_ONBOARDING_ORGANIZATION,
    {
      fetchPolicy: 'network-only'
    }
  )
  const [
    completeOrganizationAssociateStep,
    { loading: completeOrganizationAssociationLoading }
  ] = useMutation<UpdateUserOnboardingState, UpdateUserOnboardingStateVariables>(
    UPDATE_USER_ONBOARDING_STATE,
    {
      variables: { isOrganizationAssociationAcknowledgmentCompleted: true },
      // On Success, redirect to onboarding router which will intelligently route user based on their current state
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP, null) },
      onError: (error) => {
        setCompleteOrganizationAssociationError({
          customContent: {
            title: ErrorCopy.SOMETHING_WENT_WRONG,
            subtitle: 'Unable to accept invite. Please try again or contact support.'
          },
          error
        })
      }
    }
  )

  const organizationName = data?.currentUser?.selectedOrganization?.name
  if (!loading && organizationName == null) {
    return <Navigate to={ROUTES.V2_SIGN_UP_ORGANIZATION_CREATE}/>
  }

  return (
    <OnboardingPage maxWidth={ContainerWidth.MEDIUM} isLoading={loading} error={error}>
      <Graphic src={HANDHOLDING_PEOPLE_HEART} h='122px'/>
      <Center flexDir='column' gap={1} w='100%'>
        <Text>Continue with</Text>
        <Heading>{organizationName}</Heading>
        <Text>You&apos;ve been invited to join {organizationName}</Text>
      </Center>
      <Button
        text='Continue'
        onClick={() => { void completeOrganizationAssociateStep() }}
        isLoading={completeOrganizationAssociationLoading}
      />
      <ErrorInline error={completeOrganizationAssociationError}/>
    </OnboardingPage>
  )
}
