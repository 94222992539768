import React, { type ReactNode, type ReactElement } from 'react'
import {
  Flex,
  Text
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import DefaultMenuItem from './DefaultMenuItem'
import DropDownMenuItem from './DropDownMenuItem'
import { Color, IconSize } from '../../theme/theme'
import CardsIcon from '../../icons/CardsIcon'
import AccountsIcon from '../../icons/AccountsIcon'
import TreasuryIcon from '../../icons/TreasuryIcon'
import FilesIcon from '../../icons/FileIcon'
import GraphIcon from '../../icons/GraphIcon'
import HandDollarIcon from '../../icons/HandDollarIcon'
import SettingsCogComponent from '@/components/pages/settings/components/SettingsCogComponent'
import BulletListIcon from '@/library/icons/BulletListIcon'
import HomeIcon from '@/library/icons/HomeIcon'
import TransferNavIcon from '@/library/icons/TransferNavIcon'
import RecurringIcon from '@/library/icons/RecurringIcon'
import useAltirNavigate from '@/hooks/useAltirNavigate'

export interface MenuLinkObject {
  text: string
  link: string
}

interface NavItemProps {
  text: string
  icon: NavIcon
  options?: MenuLinkObject[]
  route: string
  hasNotification?: boolean
  isCompact?: boolean
  fontSize?: string
}

export enum NavIcon {
  AUTOMATED_TRANSFERS,
  INSIGHTS,
  TRANSFER,
  TREASURY,
  CARDS,
  ACCOUNTS,
  FILES,
  LOANS,
  SETTINGS,
  TRANSACTIONS,
  HOME
}

function getIcon (icon: NavIcon, color: string, hasNotification: boolean): ReactNode {
  switch (icon) {
    case NavIcon.INSIGHTS:
      return <GraphIcon size={IconSize.SMALL} color={color}/>
    case NavIcon.TREASURY:
      return <TreasuryIcon size={IconSize.SMALL} color={color}/>
    case NavIcon.TRANSFER:
      return <TransferNavIcon size={IconSize.SMALL} color={color}/>
    case NavIcon.CARDS:
      return <CardsIcon size={IconSize.SMALL} color={color}/>
    case NavIcon.ACCOUNTS:
      return <AccountsIcon size={IconSize.SMALL} color={color}/>
    case NavIcon.FILES:
      return <FilesIcon size={IconSize.SMALL} color={color}/>
    case NavIcon.LOANS:
      return <HandDollarIcon size={IconSize.SMALL} color={color}/>
    case NavIcon.TRANSACTIONS:
      return <BulletListIcon size={IconSize.SMALL} color={color}/>
    case NavIcon.SETTINGS:
      return <SettingsCogComponent hasNotification={hasNotification} size={IconSize.SMALL} color={color}/>
    case NavIcon.HOME:
      return <HomeIcon size={IconSize.SMALL} color={color}/>
    case NavIcon.AUTOMATED_TRANSFERS:
      return <RecurringIcon size={IconSize.SMALL} color={color}/>
  }
}

export default function VerticalNavMenuItem (
  {
    icon,
    text,
    options = [],
    route,
    isCompact = false,
    hasNotification = false,
    fontSize = 'md'
  }: NavItemProps
): ReactElement {
  const navigate = useAltirNavigate()
  const location = useLocation()

  const handleClick = (e: React.MouseEvent): void => {
    navigate(route, e)
  }
  const isFocused = location.pathname === route
  const navColor = isFocused ? Color.DARK_BLUE : Color.DARK_GREY

  const baseItem = (
    <Flex
      justifyContent={isCompact ? 'center' : 'start'}
      alignItems='center'
      w='100%'
      onClick={handleClick}
    >
      <Flex
        alignItems='center'
        justifyContent='center'
        py={1}
      >
        {getIcon(icon, navColor, hasNotification)}
      </Flex>
      {!isCompact && <Text color={navColor} px={4} fontSize={fontSize}>{text}</Text>}
    </Flex>
  )

  // Don't render sub-items when navbar is collapsed
  if (options.length > 0 && !isCompact) {
    return (
      <DropDownMenuItem
        color={navColor}
        options={options}
        isFocused={isFocused}
        isCompact={isCompact}
      >
        {baseItem}
      </DropDownMenuItem>
    )
  }

  const isSubPageFocused = options.some(option => location.pathname === option.link)
  return (
    <DefaultMenuItem
      color={navColor}
      onClick={handleClick}
      isFocused={isFocused || (isCompact && isSubPageFocused)}
      isCompact={isCompact}
    >
      {baseItem}
    </DefaultMenuItem>
  )
}
