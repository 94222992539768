import React, { type ReactElement, useState } from 'react'
import { Center, Heading, Text, useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import { type FinancialAccountFragment } from '@/graphql/__generated__/FinancialAccountFragment'
import { ModalComponent } from '@/library/modal/ModalComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import PaperPlaneIcon from '@/library/icons/PaperPlaneIcon'
import { IconSize } from '@/theme/theme'
import FormTextArea from '@/library/form/text/FormTextArea'
import { type LogPlatformEventVariables, type LogPlatformEvent } from '@/graphql/__generated__/LogPlatformEvent'
import { LOG_PLATFORM_EVENT } from '@/graphql/mutations/LogPlatformEvent'
import { PlatformEventType } from '@/graphql/__generated__/globalTypes'
import ErrorInline from '@/library/errors/ErrorInline'
import { getSuccessToast } from '@/utils/toastUtils'
import { isEmpty } from '@/utils/stringUtils'

interface ReportIncorrectDataModalProps {
  isOpen: boolean
  onClose: () => void
  account: FinancialAccountFragment
}

export default function ReportIncorrectDataModal ({
  account,
  isOpen,
  onClose
}: ReportIncorrectDataModalProps): ReactElement {
  const [description, setDescription] = useState('')
  const toast = useToast()
  const [
    logPlatformEvent,
    { loading, error }
  ] = useMutation<LogPlatformEvent, LogPlatformEventVariables>(
    LOG_PLATFORM_EVENT,
    {
      variables: {
        type: PlatformEventType.CONNECTED_ACCOUNT_INCORRECT_DATA_REPORT,
        metadata: {
          accountId: account.id,
          institutionName: account.institution?.name,
          description
        }
      },
      onCompleted: () => {
        toast(getSuccessToast('Report sent'))
        onClose()
      }
    }
  )

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <Center flexDir='column' gap={6}>
        <Center flexDir='column' gap={2}>
          <Heading size='lg'>Account Information Incorrect?</Heading>
          <Text textAlign='center'>
            Connections with Plaid and Teller can occassionally become stale.
            If you notice any discrepancies, please let us know and we will do our best
            to resolve the issue.
          </Text>
        </Center>
        <FormTextArea
          fieldName='Please describe your issue'
          onChange={(event) => { setDescription(event.target.value) }}
          placeholder='Please describe your issue'
          label='Issue Description'
          value={description}
        />
        <Button
          text='Send Report'
          variant={ButtonVariant.WHITE_OUTLINE}
          onClick={async () => await logPlatformEvent()}
          isLoading={loading}
          afterIcon={<PaperPlaneIcon size={IconSize.SMALL}/>}
          isDisabled={isEmpty(description)}
        />
        <ErrorInline error={error}/>
      </Center>
    </ModalComponent>
  )
}
