import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { type Recipient } from './RecipientDropDown'
import RecipientPaymentHistory from './RecipientPaymentHistory'
import UnverifiedRecipientWarning from './UnverifiedRecipientWarning'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { RouteParam, ROUTES } from '@/api/routes'
import TransferIcon from '@/library/icons/TransferIcon'
import { IconSize } from '@/theme/theme'
import useAltirNavigate from '@/hooks/useAltirNavigate'
import { routeWithParams } from '@/utils/routerUtils'

interface RecipientDropDownContentProps {
  recipient: Recipient
}

export default function RecipientDropDownContent ({
  recipient
}: RecipientDropDownContentProps): ReactElement {
  const navigate = useAltirNavigate()

  const viewAllLink = routeWithParams(
    ROUTES.RECIPIENT_DETAIL,
    [{ param: RouteParam.COUNTERPARTY_ID, value: recipient.treasuryPrimeId }]
  )

  return (
    <Flex flexDir='column' gap={6}>
      {!recipient.isVerified && <UnverifiedRecipientWarning/>}
      <RecipientPaymentHistory transfers={recipient.transfers} />
      <Flex gap={4}>
        <Button
          text='Make Transfer'
          beforeIcon={<TransferIcon size={IconSize.SMALL}/>}
          onClick={(e) => {
            navigate(ROUTES.TRANSFER, e, { state: { recipientCounterpartyId: recipient.treasuryPrimeId } })
          }}
          size={ButtonSize.SMALL}
        />
        <Button
          text='View Details'
          variant={ButtonVariant.WHITE_OUTLINE}
          afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
          onClick={(e) => { navigate(viewAllLink, e) }}
          size={ButtonSize.SMALL}
        />
      </Flex>
    </Flex>
  )
}
