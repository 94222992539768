import React, { type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import TransferRuleTable from './table/TransferRuleTable'
import RecurringRuleBalanceIcon from '@/library/icons/RecurringRuleBalanceIcon'
import { Color } from '@/theme/theme'
import {
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
}
  from '@/graphql/__generated__/GetTransferRules'
import { GenericTransferRuleType } from '@/utils/transferRuleUtils'
import RecurringRuleTimeIcon from '@/library/icons/RecurringRuleTimeIcon'

interface TransferRuleGroupProps {
  type: GenericTransferRuleType
  transferRules: TransferRule[]
  canEditTransferRules: boolean
  refetch: () => void
  isDeactivated?: boolean
}

export default function TransferRuleGroup ({
  type,
  transferRules,
  canEditTransferRules,
  refetch,
  isDeactivated = false
}: TransferRuleGroupProps): ReactElement {
  const { icon, title, color } = getHeadingForRule(type, isDeactivated)
  if (isDeactivated && transferRules.length < 1) {
    return <></>
  }

  return (
    <Flex direction='column' gap={3}>
      <Flex alignItems='center' gap={2}>
        {icon}
        <Heading size='sm' color={color}>{title}</Heading>
      </Flex>
      <TransferRuleTable
        transferRules={transferRules}
        ruleType={type}
        onUpdate={refetch}
        canEditTransferRules={canEditTransferRules}
        isDeactivated={isDeactivated}
      />
    </Flex>
  )
}

function getHeadingForRule (
  ruleType: GenericTransferRuleType,
  isDeactivated: boolean
): { icon: ReactElement | null, title: string, color: Color } {
  const genericProps = { color: isDeactivated ? Color.DARK_GREY : Color.DARK_BLUE }
  switch (ruleType) {
    case GenericTransferRuleType.BALANCE:
      return {
        ...genericProps,
        icon: !isDeactivated ? <RecurringRuleBalanceIcon color={Color.DARK_BLUE}/> : null,
        title: `${isDeactivated ? 'Inactive ' : ''}Balance-Based Rules`
      }
    case GenericTransferRuleType.TIME:
      return {
        ...genericProps,
        icon: !isDeactivated ? <RecurringRuleTimeIcon color={Color.DARK_BLUE}/> : null,
        title: `${isDeactivated ? 'Inactive ' : ''}Time-Based Rules`
      }
  }
}
