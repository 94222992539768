import React, { useState, type ReactElement } from 'react'
import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react'
import TransferRuleActivationModal, { ActivationDirection } from './TransferRuleActivationModal'
import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { Color, IconSize } from '@/theme/theme'
import MenuIcon from '@/library/icons/MenuIcon'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'

export interface TransferRuleEditMenuProps {
  transferRule: TransferRule
  onUpdate: () => void
  canEditTransferRules: boolean
}

export default function TransferRuleEditMenu (
  {
    transferRule,
    onUpdate,
    canEditTransferRules
  }: TransferRuleEditMenuProps
): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Flex justifyContent='end'>
      <TransferRuleActivationModal
        transferRuleId={transferRule.id}
        isModalOpen={isModalOpen}
        onModalClose={() => { setIsModalOpen(false) }}
        onUpdate={onUpdate}
        activationDirection={transferRule.isActive ? ActivationDirection.Deactivate : ActivationDirection.ReActivate}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          variant='ghost'
          icon={<MenuIcon color={Color.DARK_GREY} size={IconSize.SMALL} />}
        />
        <MenuList p={2}>
          <Tooltip label={!canEditTransferRules ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}>
            <MenuItem onClick={() => { setIsModalOpen(true) }} isDisabled={!canEditTransferRules}>
              <Text>
                {transferRule.isActive
                  ? 'Deactivate'
                  : 'Re-Activate'}
              </Text>
            </MenuItem>
          </Tooltip>
        </MenuList>
      </Menu>
    </Flex>
  )
}
