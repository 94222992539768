import { Center, Flex, Heading, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import TransferTableComponent from '../transfer/components/transfer_history/table/TransferTableComponent'
import Page from '@/library/page/Page'
import { Color } from '@/theme/theme'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'
import Button from '@/library/button/Button'
import TransferIcon from '@/library/icons/TransferIcon'
import useAltirNavigate from '@/hooks/useAltirNavigate'
import { ROUTES } from '@/api/routes'

// TODO (PJ): We have to limit this to prevent exorbinant page load time
// After working with Allison, let's implement pagination
const MAX_PAGE_SIZE = 50

export default function TransferHistoryPage (): ReactElement {
  const navigate = useAltirNavigate()
  return (
    <Page>
      <Center flexDirection='column' width='100%' gap={8} w='100%'>
        <Flex alignItems='center' justifyContent='space-between' w='100%'>
          <Flex flexDir='column' gap={2}>
            <Heading size='md' color={Color.DARK_BLUE}>Transfer History</Heading>
            <Text>In or out of your amplify account</Text>
          </Flex>
          <Flex gap={4}>
            <Button
              beforeIcon={<TransferIcon color={Color.WHITE}/>}
              text='Transfer'
              onClick={(e) => { navigate(ROUTES.TRANSFER, e) }}
            />
            <BusinessSelectorComponent businessFilterParams={{ hasAmplify: true }} requireMultipleBusinesses={true}/>
          </Flex>
        </Flex>
        <TransferTableComponent limit={MAX_PAGE_SIZE}/>
      </Center>
    </Page>
  )
}
