import React from 'react'
import { useQuery } from '@apollo/client'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { type ReactElement } from 'react'
import FinancialAccountNameComponent from './FinancialAccountNameComponent'
import MetricCardRow from '../../pages/treasury/components/treasury/MetricCardRow'
import InstitutionLogo from '../logo/InstitutionLogo'
import Badge, { BadgeVariant } from '../badge/Badge'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import SensitiveTextComponent from '@/library/text/SensitiveTextComponent'
import {
  BorderRadius, Color
} from '@/theme/theme'
import { getMask } from '@/utils/financialAccountUtils'
import { GET_FINANCIAL_ACCOUNT } from '@/graphql/queries/accounts/GetFinancialAccount'
import {
  type GetFinancialAccountVariables,
  type GetFinancialAccount
} from '@/graphql/__generated__/GetFinancialAccount'
import { getPercentageFormatted } from '@/utils/stringUtils'

export interface FinancialAccountDetailComponentProps {
  accountId: number
  isAccountClosed?: boolean
}

export default function FinancialAccountDetailComponent (
  {
    accountId,
    isAccountClosed = false
  }: FinancialAccountDetailComponentProps
): ReactElement {
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery<GetFinancialAccount, GetFinancialAccountVariables>(
    GET_FINANCIAL_ACCOUNT,
    {
      variables: {
        accountId
      }
    }
  )

  const account = data?.financialAccount
  const mask = getMask(account?.lastFour ?? null)
  const interestRateFormatted = account?.amplifyAccount?.interestRate != null
    ? getPercentageFormatted(account.amplifyAccount.interestRate)
    : null

  const accountNumberComponent = (
    <SensitiveTextComponent
      text={account?.achAccountNumber ?? ''}
      description='Account Number'
    />
  )
  const routingNumberComponent = (
    <SensitiveTextComponent
      text={account?.achRoutingNumber ?? ''}
      description='Routing Number'
    />
  )

  return (
    <Flex flexDirection='column' w='100%' gap={3}>
      <Text>Account Details</Text>
      <AltirSkeleton isLoading={loading} error={error}>
        <Flex
          width='100%'
          flexDirection='row'
          py={5}
          px={5}
          borderRadius={BorderRadius.CARD}
          bg={Color.WHITE}
        >
          <Flex flexDirection='column' width='100%'>
            <Flex
              justifyContent='space-between'
              paddingBottom={36}
              flexDirection='row'
              gap={8}
            >
              <FinancialAccountNameComponent
                accountId={account?.accountId ?? undefined}
                accountName={account?.name ?? undefined}
                accountNameOfficial={account?.nameOfficial ?? undefined}
                onUpdate={() => { void refetch() }}
              />
              <Flex shrink={0} justifySelf='start' alignSelf='start' >
                {
                !isAccountClosed
                  ? <InstitutionLogo src={account?.institution?.logoAssetUrl ?? undefined} />
                  : <Badge title='Closed' badgeVariant={BadgeVariant.GREY}/>
                }
              </Flex>
            </Flex>
            <MetricCardRow
              label='Type'
              value={account?.accountSubTypeFormatted ?? ''}
            />
            <Divider my={2} color={Color.DARK_GREY}/>
            <MetricCardRow
              label='Institution'
              value={account?.institution?.name ?? ''}
            />
            {
              interestRateFormatted != null &&
                <Flex w='100%' flexDir='column'>
                  <Divider my={2} color={Color.DARK_GREY}/>
                  <MetricCardRow
                    label='Annual Percentage Yield (APY)'
                    value={interestRateFormatted}
                  />
                </Flex>
            }
            <Divider my={2} color={Color.DARK_GREY} />
            {
              account?.achAccountNumber != null
                ? <MetricCardRow label='Account Number' valueElement={accountNumberComponent}/>
                : <MetricCardRow label='Account Number' value={mask}/>
            }
            {
              account?.achRoutingNumber != null &&
                <Flex w='100%' flexDir='column'>
                  <Divider my={2} color={Color.DARK_GREY}/>
                  <MetricCardRow label='Routing Number' valueElement={routingNumberComponent}/>
                </Flex>
            }
          </Flex>
        </Flex>
      </AltirSkeleton>
    </Flex>
  )
}
